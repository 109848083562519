import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { border, Box, Stack } from '@mui/system';
import { PersonI } from './types/PersonTypes';
import {
  FormControl,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PersonTilleggssvarTable from './PersonTilleggssvarTable';
import { FullHeightTextField, NButton } from '../components/Inputs';
import PersonSvarModal from './components/modals/PersonSvarModal';
import useFetchData from '../../utilities/useFetchData';
import { TilleggsvariableSvarI } from './types/TilleggsvariableTypes';
import { RouteComponentProps } from 'react-router-dom';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import { ApiCall } from '../../utilities/ApiCall';

interface RouteParams {
  prosjektId: string;
}

interface PersonTilleggssvarProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setPersonData: Dispatch<SetStateAction<PersonI>>;
}
export default function PersonTilleggssvar(props: PersonTilleggssvarProps) {
  const { personData, setPersonData } = props;
  const { prosjektId } = props.match.params;
  const [beskrivelse, setBeskrivelse] = useState<string>('');
  const [showSvarKategorier, setShowSvarKategorier] = useState<boolean>(false);
  const [variabelId, setVariabelId] = useState<number>();
  const [svarKategorier, setSvarKategorier] = useState<TilleggsvariableSvarI[] | null>(
    null
  );
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!variabelId) return;
    ApiCall(`getSvarKategorier/${variabelId}`, 'GET', keycloak?.token, setSvarKategorier);
  }, [variabelId]);

  // // GET SVARKATEGORIER
  // const getSvarKategorier = useFetchData({
  //   loadOnMount: true,
  //   clearDataOnLoad: false,
  //   projectURL: 'getSvarKategorier',
  //   subProjectURL: `${variabelId}`
  // });

  // useEffect(() => {
  //   if (variabelId) {
  //     getSvarKategorier.loadData('getSvarKategorier', `${variabelId}`);
  //   }
  // }, [variabelId]);

  // useEffect(() => {
  //   if (getSvarKategorier.data) {
  //     const data = getSvarKategorier.data as unknown as TilleggsvariableSvarI[];
  //     setSvarKategorier(data);
  //   }
  // }, [getSvarKategorier.data]);

  // SET BESKRIVELSE
  useEffect(() => {
    setBeskrivelse(
      personData?.tilleggssvar?.find((t) => t.variabelId === variabelId)?.beskrivelse
    );
  }, [variabelId]);

  return (
    <>
      <Stack direction={'column'} spacing={2} sx={{ mt: '2rem' }}>
        <Typography variant="h6" gutterBottom>
          Tilleggssvar:
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <PersonTilleggssvarTable
            personData={personData}
            setPersonData={setPersonData}
            svarKategorier={svarKategorier}
            variabelId={variabelId}
            setVariabelId={setVariabelId}
            {...props}
          />
          <Stack spacing={1} sx={{ alignItems: 'end' }}>
            <Tooltip
              title={
                !owned
                  ? 'Mangler rettigheter'
                  : !personData?.personId
                  ? 'PersonID mangler'
                  : !variabelId
                  ? 'Ingen variabel valgt'
                  : ''
              }
            >
              <span>
                <NButton
                  children="Velg svar"
                  onClick={() => setShowSvarKategorier(true)}
                  variant="outlined"
                  disabled={!owned || !personData || !variabelId}
                />
              </span>
            </Tooltip>
            <FullHeightTextField
              name="beskrivelse"
              value={beskrivelse ? beskrivelse : '[Ingen svar funnet, velg fra tabellen]'}
              disabled
              sx={{
                minWidth: { sm: '16rem', lg: '28rem' }
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* dialoges */}

      {showSvarKategorier && (
        <PersonSvarModal
          personData={personData}
          setPersonData={setPersonData}
          svarKategorier={svarKategorier}
          variabelId={variabelId}
          kortnavn={
            personData?.tilleggssvar?.find((item) => item.variabelId === variabelId)
              ?.kortnavn
          }
          open={showSvarKategorier}
          hide={() => setShowSvarKategorier(false)}
        />
      )}
    </>
  );
}
