import React, { Dispatch, SetStateAction } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Stack, Typography } from '@mui/material';

interface ChangesModalProps {
  showing: boolean;
  hide: () => void;
  handleJa: () => void;
  handleNei: () => void;
}

export default function ChangesModal(props: ChangesModalProps) {
  const { showing, hide, handleJa, handleNei } = props;

  const handleAvbryt = () => {
    hide();
  };

  return (
    <DialogGeneral
      title="Ulagret innhold"
      open={showing}
      titleIcon={<HelpOutlineIcon sx={{ color: 'white' }} />}
      hide={hide}
      fullWidth
      maxWidth="xs"
    >
      <Typography>Det er blitt gjort endringer. Vil du lagre disse?</Typography>
      <Stack direction={'row'} justifyContent="end" spacing={1} paddingTop={4}>
        <Button variant="contained" onClick={handleJa}>
          Ja
        </Button>
        <Button variant="outlined" onClick={handleNei}>
          Nei
        </Button>
        <Button variant="outlined" onClick={handleAvbryt}>
          Avbryt
        </Button>
      </Stack>
    </DialogGeneral>
  );
}
