import React from 'react';
import { Grid, Box, Typography, Stack } from '@mui/material';
import { ChatBubbleButtonSingle } from '../components/ChatBubbleButton';
import StoffgruppIconsMenu from './StoffgruppIconsMenu';
import { StoffI, StoffgruppeI } from '../stoff/types/StoffTypes';
import { useUserContext } from '../../../context/AuthContext';
import { NButton, SlowText } from '../components/Inputs';
import { IsOwnedById } from '../../utilities/Validate';

interface StoffgrupperFeilds {
  classes: any;
  stoffGruppe: StoffgruppeI;
  handleChange: any;
  toggleStoffPopup: any;
  clearFields: () => void;
  stoffgruppeIdFromURL?: number | undefined;
  handleLagre: () => void;
  deleteStoffgruppe: () => void;
  navnError: any;
  toggleCopyStoffgruppe: () => void;
  stoffInStoffgruppe?: StoffI[];
  //handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function StoffgrupperFeilds(props: StoffgrupperFeilds) {
  const {
    classes,
    stoffGruppe,
    handleChange,
    toggleStoffPopup,
    clearFields,
    deleteStoffgruppe,
    navnError,
    stoffgruppeIdFromURL
  } = props;
  const loggedInuser = useUserContext();

  return (
    <Grid container xs={11}>
      <Grid container item xs={6}>
        <Typography variant="h4">Stoffgrupper</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ paddingBottom: 10 }}
        justifyContent={'flex-end'}
      >
        <StoffgruppIconsMenu
          classes={classes}
          deleteStoffgruppe={deleteStoffgruppe}
          stoffGruppe={stoffGruppe}
          stoffgruppeIdFromURL={stoffgruppeIdFromURL}
          toggleCopyStoffgruppe={props.toggleCopyStoffgruppe}
          stoffInStoffgruppe={props.stoffInStoffgruppe}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <SlowText
            classes={classes}
            id={'Navn'}
            name={'navn'}
            value={stoffGruppe?.navn || ''}
            type={'text'}
            onChange={handleChange}
            required
            inputProps={{
              maxLength: 30
            }}
            error={navnError.error}
            header={'Navn*'}
          />
        </Grid>
        <Grid item xs={3}>
          <Box style={{ paddingTop: 25 }}>
            <ChatBubbleButtonSingle
              name={'Stoffgruppenavn'}
              handleOpenPopup={toggleStoffPopup}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <SlowText
            classes={classes}
            header={'Nr.'}
            id={'nr'}
            name={'nr'}
            onChange={handleChange}
            value={stoffGruppe?.stoffgruppeId || stoffgruppeIdFromURL || ''}
            type={'text'}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SlowText
          classes={classes}
          header={'Beskrivelse'}
          id={'Beskrivelse'}
          name={'beskrivelse'}
          value={stoffGruppe?.beskrivelse || ''}
          type={'text'}
          onChange={handleChange}
          multiline={true}
          rows={'2'}
          InputProps={{
            readOnly:
              !IsOwnedById({
                tilgangskode: stoffGruppe?.tilgangskode,
                u: loggedInuser
              }) && stoffGruppe?.stoffgruppeId !== 0
          }}
        />
      </Grid>
      <Grid item xs={12} container style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Stack direction={'row'} spacing={2}>
          <NButton
            children={stoffgruppeIdFromURL ? 'Lagre' : 'Opprett'}
            onClick={props.handleLagre}
            disabled={
              (!IsOwnedById({
                tilgangskode: stoffGruppe?.tilgangskode,
                u: loggedInuser
              }) &&
                stoffGruppe?.stoffgruppeId !== 0) ||
              stoffGruppe.navn === ''
            }
          />
          <NButton children="Nullstill" variant="outlined" onClick={clearFields} />
        </Stack>
      </Grid>
    </Grid>
  );
}
