import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { OppringingI, PersonI } from './types/PersonTypes';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { CodeRegisterI, HeadCell } from '../components/commons/CommonTypes';
import TableEmptyRows from '../../utilities/TableEmptyRows';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { RouteComponentProps } from 'react-router-dom';
import { useCodeContext } from '../../../context/CodeContext';

const headCells: HeadCell<OppringingI>[] = [
  {
    id: 'nr',
    align: 'left',
    padding: 'normal',
    label: 'Nr.'
  },
  {
    id: 'lopedagId',
    align: 'right',
    padding: 'normal',
    label: 'Løpedag'
  },
  {
    id: 'dato',
    align: 'right',
    padding: 'normal',
    label: 'Dato'
  },
  {
    id: 'varighet',
    align: 'right',
    padding: 'normal',
    label: 'Varighet'
  },
  {
    id: 'status',
    align: 'left',
    padding: 'normal',
    label: 'Status'
  },
  {
    id: 'kommentar',
    align: 'left',
    padding: 'normal',
    label: 'Kommentar'
  }
];

interface RouteParams {
  prosjektId: string;
}

interface PersonLopedagerProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setShowOppringing: Dispatch<SetStateAction<boolean>>;
  setRingOpp: Dispatch<SetStateAction<boolean>>;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  setCurrentOppringing: Dispatch<SetStateAction<number>>;
}

export default function PersonLopedager(props: PersonLopedagerProps) {
  const {
    personData,
    setShowOppringing,
    setRingOpp,
    setCurrentModalLopedag,
    setCurrentOppringing
  } = props;
  const { prosjektId } = props.match.params;
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const allCodes = useCodeContext();
  const oppringingTypes: CodeRegisterI[] = allCodes.codes?.filter((f) => f.value === 13);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <EnhancedTableHead<OppringingI> headCells={headCells} />
        <TableBody>
          {personData?.oppringinger &&
            personData?.oppringinger.map((row) => (
              <TableRow key={row.nr} hover>
                {headCells.map((cell, index) =>
                  index < 1 ? (
                    owned ? (
                      <TableCell
                        key={cell.id}
                        onClick={() => {
                          setCurrentModalLopedag(row.lopedagId);
                          setCurrentOppringing(row.nr);
                          setRingOpp(false);
                          setShowOppringing(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Link>{row[cell.id]}</Link>
                      </TableCell>
                    ) : (
                      <TableCell key={cell.id}>{row[cell.id]}</TableCell>
                    )
                  ) : cell.id === 'kommentar' && row[cell.id]?.toString().length > 30 ? (
                    <Tooltip key={cell.id} title={row[cell.id]} placement="left">
                      <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                        {`${row[cell.id].toString().slice(0, 30)}...`}
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                      {cell.id === 'status'
                        ? oppringingTypes?.find((m) => m.nr === row['status'])?.label
                        : row[cell.id]}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          <TableEmptyRows<OppringingI>
            headCells={headCells}
            dataLength={personData?.oppringinger?.length}
            minRows={4}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
