import React, { ChangeEvent, forwardRef, MouseEvent, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import {
  ItemOwner,
  MatvareIUndersokelseI,
  MatvareKodenNavnI
} from './types/UndersokelseTypes';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';
import { headCellsMIU } from '../matvare/components/TableHeadersData';
import { FilterIconDialog, NButton } from '../components/Inputs';

interface MatvareTableProps {
  owned: boolean;
  matvareData: MatvareIUndersokelseI[];
  undersokelseOwners: ItemOwner[];
  selectedUOwner: ItemOwner;
  setSelectedUOwner: (i: ItemOwner) => void;
  kommentarChecked: boolean;
  setKommentarChecked: (i: boolean) => void;
  loading: boolean;
  hasMore: boolean;
  loader: React.RefObject<HTMLDivElement>;
  setBytt: (value: boolean) => void;
  setMatvareToChange: (value: MatvareKodenNavnI) => void;
  projectId: number;
  undersokelseId: number;
  undersokelseKortnavn: string;
}

export default function MatvarerTable(props: MatvareTableProps) {
  const {
    owned,
    matvareData,
    undersokelseOwners,
    selectedUOwner,
    kommentarChecked,
    setSelectedUOwner,
    setKommentarChecked,
    loading,
    hasMore,
    loader,
    setBytt,
    setMatvareToChange,
    projectId,
    undersokelseId,
    undersokelseKortnavn
  } = props;
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof MatvareIUndersokelseI>('matvarekode');
  const handleRequestSort = (property: keyof MatvareIUndersokelseI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedRows = [...matvareData]?.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) return order === 'asc' ? -1 : 1;
    if (b[orderBy] > a[orderBy]) return order === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
      <Table stickyHeader size="small">
        <EnhancedTableHead<MatvareIUndersokelseI>
          headCells={headCellsMIU}
          rightColumns={owned ? [{ name: 'Detalje' }, { name: 'Bytt' }] : []}
          sorting={{
            order: order,
            orderBy: orderBy,
            onRequestSort: handleRequestSort
          }}
        />
        <TableBody>
          {sortedRows?.map((row) => (
            <TableRow key={row.matvarekode} hover>
              {headCellsMIU.map((cell, i) => (
                <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                  {cell.id === 'matvarekode' ? (
                    <Link
                      href={'/home/matvaredata/matvare/' + row?.matvarekode}
                      target="_blank"
                    >
                      {row[cell.id]}
                    </Link>
                  ) : (
                    row[cell.id]
                  )}
                </TableCell>
              ))}
              <TableCell>
                <Link
                  href={`/home/kostholdsdata/prosjekt/matvarer/${projectId}/${undersokelseId}/${undersokelseKortnavn}/detalje/${
                    row.matvarekode
                  }/${row.navn
                    ?.replace(/[/]/g, '-slash-')
                    ?.replace(/[%]/g, '-percent-')}`}
                  target="_blank"
                  sx={{ width: '100%', color: 'inherit' }}
                >
                  <NButton
                    rightIcon={<OpenInNewOutlinedIcon />}
                    variant="text"
                    color="inherit"
                    fullWidth
                  />
                </Link>
              </TableCell>

              {owned && (
                <TableCell>
                  <IconButton
                    aria-label="detail"
                    onClick={() => {
                      setMatvareToChange({
                        kode: row.matvarekode,
                        navn: row.navn
                      });
                      setBytt(true);
                    }}
                  >
                    <FindReplaceIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={9}>
              {loading && <LinearProgress />}
              {!loading && hasMore && (
                <div ref={loader} style={{ height: '50px', background: 'lightgrey' }} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
