import React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Box,
  InputLabel,
  CircularProgress,
  Typography,
  Grid
} from '@mui/material';
import Paper from '@mui/material/Paper';
import useFetchData from '../../utilities/useFetchData';
import useStyles from '../../../css/materialtheme';
import PageContainer from '../home/PageContainer';
import { DatabaseContext } from '../../../context/DatabaseContext';
import IconsMenuOppskrift from './Oppskrifts/IconsMenuOppskrift';
import {
  MatvareData,
  IngredientData,
  OppskriftData,
  EnhetData
} from './types/MatvareTypes';
import DeleteOppskrift from './Oppskrifts/DeleteOppskrift';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import { useKeycloak } from '@react-keycloak/web';
import { Matvare } from './types/matvaregruppeTypes';
import { useErrorHandler } from 'react-error-boundary';
import EnhetList from './Oppskrifts/EnhetList';
import { ProgressBar } from '../../utilities/ProgressBar';
import { NButton } from '../components/Inputs';
import MatvareSearch from './MatvareSearch';
import IsOwnedByUser from '../../utilities/Validate';
import { tilberedningKodes } from '../../../assets/OppskriftOptions';
import useDidMountEffect from '../../utilities/useDidMountEffect';
import { OptionTypeS } from '../components/commons/CommonTypes';
import { useUserContext } from '../../../context/AuthContext';
import OppskriftTable from './Oppskrifts/OppskriftTable';

export default function Oppskrift(props: any) {
  const initialRow = {
    INGREDIENSID: 0,
    NAVN: '',
    GRAM: 0,
    SORT: 0,
    ORDNRID: 0,
    ENHETER: [],
    ANTALL: '',
    gPerEnhet: 0
  };
  const kodeFromURL = props.match.params.kode;
  const navnFromURL = props.match.params.navn;
  const navn = navnFromURL?.replace(/-slash-/g, '/')?.replace(/-percent-/g, '%');
  const vektEndringFromURL = props.match.params.vektE;
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const backendURI = process.env.REACT_APP_BACKEND;
  const loggedInuser = useUserContext();
  const { currentDatabase } = useContext(DatabaseContext);
  const handleError = useErrorHandler();
  const [title, setTitle] = useState('Oppskrift - Nutrifoodcalc');
  const [isNew, setIsNew] = React.useState(false);
  const [newRow, setNewRow] = React.useState<IngredientData>(initialRow);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [sumIngredienser, setSumIngredienser] = useState<string>('0');
  const [opendeleteIngredient, setOpendeleteIngredient] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<IngredientData>();
  const [deleted, setDeleted] = useState(false);
  const [rowWithNewEnhet, setRowWithNewEnhet] = useState<IngredientData>();
  const [selectedRow, setSelectedRow] = useState<IngredientData>();
  const [owned, setOwned] = useState(false);
  const [lastOrderNr, setLastOrderNr] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [matvareList, setMatvareList] = useState<MatvareData[]>([]);
  const [newMatvare, setNewMatvare] = useState<Matvare>();
  const [showEnhetList, setShowEnhetList] = useState(false);
  const [isDone, setIsDone] = useState(true);
  const [showProgress, setShowProgress] = React.useState(false);
  const [showMatvareKode, setShowMatvareKode] = useState<boolean>(false);
  const [disabledLagre, setDisabledLagre] = useState<boolean>(true);
  const [tilberedning, setTilberedning] = useState<OptionTypeS>(tilberedningKodes[0]);
  const [selectedEnhet, setSelectedEnhet] = React.useState<EnhetData>();

  document.title = title;

  const getAllMatvares = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getMatvareList',
    subProjectURL: `${currentDatabase?.value}/${1}/${999999}` //gets all matvarekodes in the db
  });
  const recepies = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getOppskriftList',
    subProjectURL: `${currentDatabase?.value}/${kodeFromURL}`
  });
  document.title = title;
  const [rows, setRows] = useState<any[]>([]);
  const [originalCopy, setOriginalCopy] = useState<any[]>([]);

  useEffect(() => {
    loadRecipe();
  }, [kodeFromURL]);

  useEffect(() => {
    if (getAllMatvares.data) {
      const data: MatvareData[] = getAllMatvares.data as unknown as MatvareData[];
      setMatvareList(data);
    }
  }, [getAllMatvares.data]);
  useEffect(() => {
    if (recepies.isLoaded) {
      let sort = 1;
      const recipeData: OppskriftData = recepies.data as unknown as OppskriftData;
      setTilberedning(
        tilberedningKodes?.find((t) => t.value === recipeData?.tilberedningskode)
      );
      const modifiedRows = recipeData?.ingredients?.map((row: IngredientData) => {
        return {
          ...row,
          SORT: sort++,
          GRAM: parseFloat(row.GRAM.toString()?.replace(/[,]/g, '.'))
        };
      });
      if (recipeData?.ingredients?.length === 0 && owned) {
        setIsNew(true);
      }
      setOriginalCopy(modifiedRows);
      setRows(modifiedRows);
      getSumIngredienser(recipeData?.ingredients);
    }
  }, [recepies.isLoaded]);

  useEffect(() => {
    if (kodeFromURL > 0) {
      setTitle(`Oppskrifter - ${kodeFromURL} - Nutrifoodcalc`);
      recepies.loadData('getOppskriftList', `${currentDatabase?.value}/${kodeFromURL}`);
      setOwned(IsOwnedByUser({ id: +kodeFromURL, u: loggedInuser }));
    }
  }, [kodeFromURL]);
  useDidMountEffect(() => {
    if (deleted) {
      removeRow(rowToDelete);
      setDeleted(false);
      //prepareData() //Todo: Make delete independent of save
    }
  }, [deleted]);

  useDidMountEffect(() => {
    const valid = checkValidity(+newMatvare?.MATVAREKODEID);

    if (valid) {
      setNewRow({
        ...initialRow,
        INGREDIENSID: +newMatvare?.MATVAREKODEID,
        NAVN: newMatvare?.NAVN,
        ANTALL: '',
        gPerEnhet: 0,
        SORT: rows?.length + 1
      });
    }
  }, [newMatvare]);

  useDidMountEffect(() => {
    if (dataChanged) {
      getSumIngredienser(rows);
    }
  }, [dataChanged]);
  useDidMountEffect(() => {
    if (owned && dataChanged && isDone) {
      setDisabledLagre(false);
    } else {
      setDisabledLagre(true);
    }
  }, [dataChanged, isDone]);

  useEffect(() => {
    console.log('matvareList', matvareList);
  }, [matvareList]);

  const loadRecipe = () => {
    if (kodeFromURL > 0) {
      recepies.clearAll();
      setTitle(`Oppskrift - ${kodeFromURL} - Nutrifoodcalc`);
      recepies.loadData('getOppskriftList', `${currentDatabase?.value}/${kodeFromURL}`);
    }
    let ownedNumber = IsOwnedByUser({ id: kodeFromURL, u: loggedInuser });
    setOwned(ownedNumber);
    if (!ownedNumber) {
      setDisabledLagre(true);
    }
  };
  function addNewItem(): void {
    if (!newRow?.INGREDIENSID || !newRow?.NAVN || !newRow?.GRAM) {
      showAlertComponent('Gram må fylles ut.', 'error');
    } else {
      const valid = checkValidity(+newRow?.INGREDIENSID);
      if (valid) {
        const updatedRows = [...rows, newRow];
        setRows(updatedRows);
        setOriginalCopy(updatedRows);

        setNewRow(initialRow);
      }
    }
  }
  //rearranges the order number after removing
  const rearrangeOrder = (newRows: IngredientData[], order: number) => {
    const rearangedRows = newRows?.map((row: IngredientData) => {
      if (row.SORT > order) {
        return {
          ...row,
          SORT: row.SORT - 1
        };
      }
      return row;
    });
    setLastOrderNr(rearangedRows.length - 1);
    setRows(rearangedRows);
  };
  const removeRow = (rowTD: IngredientData) => {
    const newRows = rows?.filter((row) => row.INGREDIENSID !== rowTD.INGREDIENSID);
    setRows(newRows);
    if (rowTD.INGREDIENSID !== 0) {
      rearrangeOrder(newRows, rowTD.SORT);
      setDataChanged(true);
    }
  };

  const validateMatvareCode = (value: number[]) => {
    const allCodes = matvareList?.map((ele: MatvareData) => ele.MATVAREKODEID);
    let result = true;
    for (let val of value) {
      if (!allCodes?.includes(parseInt(val.toString()))) {
        showAlertComponent(
          `Ingrediensid ${val} eksisterer ikke. Bruk Matvarekode knappen til å søke ingridienser.`,
          'error'
        );
        result = false;
        return result;
      }
    }
    return result;
  };
  const handleDelete = (id: number) => {
    setOpendeleteIngredient(true);
    setRowToDelete(rows?.find((row) => row.INGREDIENSID === id));
  };
  const getSumIngredienser = (data: IngredientData[]) => {
    let sum: number = 0.0;
    data?.forEach((value) => {
      const gram = parseFloat(value.GRAM?.toString()?.replace(/[,]/g, '.'));
      return (sum += gram);
    });
    setSumIngredienser(sum?.toFixed(2));
  };
  const handleCloseDeleteOppskrift = () => {
    setOpendeleteIngredient(false);
  };
  const resetVariables = () => {
    setDataChanged(false);
    setLastOrderNr(0);
    setNewMatvare({
      MATVAREKODEID: 0,
      NAVN: ''
    });
    setNewRow({
      ...newRow,
      INGREDIENSID: 0,
      NAVN: '',
      GRAM: 0,
      SORT: 0,
      ORDNRID: 0,
      ENHETER: []
    });
  };
  const showAlertComponent = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  async function postData(data: any, url: string, methods: string) {
    let response = await fetch(encodeURI(url), {
      method: methods,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    return response;
  }
  const prepareData = () => {
    let valid = true;
    const data = rows?.filter((row: IngredientData) => row.INGREDIENSID !== 0);
    if (
      !(
        newRow?.INGREDIENSID === undefined ||
        newRow?.INGREDIENSID === 0 ||
        Number.isNaN(newRow?.INGREDIENSID)
      )
    ) {
      data.push(newRow);
    }
    const ids = data?.map((row: IngredientData) => row.INGREDIENSID);
    const found = ids?.filter(
      (item: number, index: number) => ids?.indexOf(parseInt(item.toString())) !== index
    );
    if (found.length > 0) {
      showAlertComponent(
        `Ingrediensid ${found} eksisterer inni ingridienseslista allrede.`,
        'error'
      );
      valid = false;
      return;
    }
    const validCode = validateMatvareCode(ids);
    if (!validCode) {
      //
      valid = false;
      return;
    }
    const finalData = data?.map((item: IngredientData) => {
      if (!item.GRAM || isNaN(item.GRAM)) {
        showAlertComponent('Gram kan ikke være tomt eller 0', 'error');
        valid = false;
        return;
      }
      return {
        ...item,
        GRAM: parseFloat(item.GRAM?.toString()),
        SORT: item.SORT || lastOrderNr + 1,
        ORDNRID: item.ORDNRID || item.SORT
      };
    });
    if (valid) saveOppskrift(finalData);
  };
  const saveOppskrift = (data: IngredientData[]) => {
    setShowProgress(true);

    // if (data?.length > 0) {
    let url = `${backendURI}/saveOppskrift/${currentDatabase?.value}/${kodeFromURL}/${
      tilberedning?.value || 0
    }`;
    let method = `POST`;
    if (!isNew) {
      url = `${backendURI}/updateOppskrift/${currentDatabase?.value}/${kodeFromURL}/${
        tilberedning?.value || 0
      }`;
      method = `PUT`;
    }
    postData(data, url, method)
      .then((resp) => {
        if (resp.status === 200) {
          resetVariables();
          setIsNew(false);
          setShowProgress(false);
          setDisabledLagre(true);
          console.log('Lagringen er fullført', 'success');
          showAlertComponent('Lagringen er fullført', 'success');
          loadRecipe();
        } else if (resp.status === 500) {
          resetVariables();
          cleanDatabase();
          setShowProgress(false);
          showAlertComponent(
            'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
            'error'
          );
        }
      })
      .catch((err) => {
        resetVariables();
        cleanDatabase();
        setShowProgress(false);
        console.log('We are in the catch', err);
        showAlertComponent(
          'Lagringen mislyktes. Det har ikke blitt gjort endringer mot databasen.',
          'error'
        );
      });
    /*   } else {
        setShowProgress(false);
        showAlertComponent(
          'Det er ikke noe ingresiens å lagre.',
          'error'
        );
      } */
  };
  const cleanDatabase = () => {
    let url = `${backendURI}/endEditing/${currentDatabase?.value}/${kodeFromURL}`;
    postData({}, url, 'DELETE')
      .then((resp) => {
        if (resp.status === 500) {
          showAlertComponent(
            'Systemet fikk ikke avsluttet revideringen. Noe gikk galt.',
            'error'
          );
        }
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        handleError(err);
        showAlertComponent(
          'Systemet fikk ikke avsluttet revideringen. Noe gikk galt.',
          'error'
        );
      });
  };
  const checkValidity = (id: number) => {
    let valid = false;
    const ids = rows?.map((r: IngredientData) => r.INGREDIENSID);
    if (!id) {
      showAlertComponent(`Ingrediensid kan ikke være tomt.`, 'error');
    } else if (ids?.includes(id) && id !== selectedRow?.INGREDIENSID) {
      showAlertComponent(
        `Ingrediensid ${id} eksisterer inni ingridienseslista allrede.`,
        'error'
      );
    } else {
      const found = matvareList?.find((mat: MatvareData) => mat.MATVAREKODEID === id);
      if (!found) {
        showAlertComponent(
          `Ingrediensid ${id} eksisterer ikke. Bruk Matvare knappen til å søke ingridienser.`,
          'error'
        );
      } else if (id === +kodeFromURL) {
        showAlertComponent(
          'En oppskrift kan ikke inneholde seg selv (ingrediens med samme kode som matvaren)!',
          'error'
        );
      } else {
        valid = true;
      }
    }
    return valid;
  };
  const handleNewRow = (name: string, value: any) => {
    if (name === 'INGREDIENSID') {
      value = parseInt(value?.toString()) || 0;
    }
    if (name === 'ANTALL') {
      let mengde = 0;
      if (+value) {
        const gramValue = parseFloat(
          newRow?.gPerEnhet?.toString()?.trim()?.replace(/,/g, '.')
        );
        //ANTALL is updated only if MENGDEENHET has value, then we also update GRAM by multiplying gPerEnhet with ANTALL
        if (newRow.MENGDEENHET !== '') {
          mengde = Number((+value * gramValue)?.toFixed(2));
          setNewRow({
            ...newRow,
            ANTALL: value,
            GRAM: mengde || +newRow.GRAM
          });
        } else
          setNewRow({
            ...newRow,
            [name]: +value
          });
      } else {
        if (newRow.MENGDEENHET !== '') {
          setNewRow({
            ...newRow,
            ANTALL: '',
            GRAM: parseFloat(newRow?.gPerEnhet?.toString()?.trim())
          });
        } else {
          setNewRow({
            ...newRow,
            [name]: +value
          });
        }
      }
    } else if (name === 'GRAM') {
      setNewRow({
        ...newRow,
        ANTALL: '',
        MENGDEENHET: '',
        GRAM: parseFloat(value?.toString())
      });
    } else
      setNewRow({
        ...newRow,
        [name]: value,
        ORDNRID: rows?.length + 1,
        SORT: rows?.length + 1
      });
    setDataChanged(true);
  };
  const showEnhetOptions = (row: IngredientData) => {
    if (row.INGREDIENSID) {
      setSelectedRow(row);
    } else {
      setSelectedRow(newRow);
    }
    setShowEnhetList(!showEnhetList);
  };
  const handleOnEnterKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (newRow?.INGREDIENSID && event.key === 'Enter') {
      handleOnEnterEffect();
    }
  };
  const handleBlur = () => {
    handleOnEnterEffect();
  };
  const handleOnEnterEffect = () => {
    const valid = checkValidity(+newRow?.INGREDIENSID);
    if (!valid) {
      setNewRow(initialRow);
    } else {
      const navn = matvareList?.find(
        (mat: MatvareData) => mat.MATVAREKODEID === +newRow.INGREDIENSID
      )?.NAVN;
      setNewRow({ ...newRow, NAVN: navn });
    }
  };
  const updateTilberedning = (kode: OptionTypeS) => {
    setTilberedning(kode);
    setDisabledLagre(false);
  };
  const showAllFoodItems = () => {
    setShowMatvareKode(!showMatvareKode);
  };
  return (
    <PageContainer>
      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      {showProgress && (
        <ProgressBar
          isShowing={showProgress}
          text={'Lagrer...'}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      {showMatvareKode && (
        <MatvareSearch
          setMatvare={(id, navn) => {
            setNewMatvare({
              MATVAREKODEID: id as number,
              NAVN: navn
            });
          }}
          showing={showMatvareKode}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          hide={() => setShowMatvareKode(false)}
        />
      )}
      <Container
        id="ingrediens"
        style={{
          minWidth: 800,
          minHeight: '80vh',
          paddingTop: 55
        }}
      >
        <Typography variant="h4">Oppskrift</Typography>
        <IconsMenuOppskrift
          classes={classes}
          currentDatabase={currentDatabase}
          kodeFromURL={kodeFromURL}
          navnFromURL={navn}
          vektEndring={vektEndringFromURL}
          owned={owned}
          ingredientsList={rows}
          showAlertComponent={showAlertComponent}
          sumIngredienser={sumIngredienser}
          matvareList={matvareList}
          tilberedning={tilberedning}
          updateTilberedning={updateTilberedning}
        />
        {opendeleteIngredient && (
          <DeleteOppskrift
            matvarekode={kodeFromURL}
            ingredienseList={rows}
            row={rowToDelete}
            open={opendeleteIngredient}
            close={handleCloseDeleteOppskrift}
            setDeleted={setDeleted}
            showAlertMessage={showAlertComponent}
          />
        )}
        <Paper elevation={1}>
          <Grid item xs={12}>
            {recepies?.isLoaded ? (
              <OppskriftTable
                data={rows}
                owned={owned}
                newRow={newRow}
                handleNewRow={handleNewRow}
                handleOnEnterKeyPress={handleOnEnterKeyPress}
                handleBlur={handleBlur}
                handleDelete={handleDelete}
                setRows={setRows}
                setNewRow={setNewRow}
                addNewItem={addNewItem}
                showEnhetOptions={showEnhetOptions}
                setSelectedRow={setSelectedRow}
                setDataChanged={setDataChanged}
                showAllFoodItems={showAllFoodItems}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Paper>
        <Box display="flex" justifyContent="flex-end">
          <InputLabel style={{ textAlign: 'left' }} sx={classes.InputLabel}>
            Sum ingredienser: {sumIngredienser}
          </InputLabel>
        </Box>
        <NButton
          children={'Lagre'}
          type="submit"
          disabled={disabledLagre}
          onClick={prepareData}
        />
        {showEnhetList && (
          <EnhetList
            showing={showEnhetList}
            hide={() => setShowEnhetList(false)}
            databaseId={currentDatabase.value}
            selectedIngeredient={selectedRow}
            newRow={newRow}
            ingredientsList={rows}
            setRowWithNewEnhet={setRowWithNewEnhet}
            setRows={setRows}
            setNewRow={setNewRow}
            setSelectedEnhet={setSelectedEnhet}
          />
        )}
      </Container>
    </PageContainer>
  );
}
