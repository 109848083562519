import React from 'react';
import { enhetOption } from '../../../../../assets/beregningEnhetOption';
import { BeregningObject, ReportResultTableI } from '../../types/BeregningTypes';
import ExcludedMatvare from '../ExcludedItems/ExcludedMatvare';
import { ExportToExcel } from '../ExportToExcel';
import { Report10Result } from '../Reports/Report10Result';
import { Report11Result } from '../Reports/Report11Result';
import { Report12Result } from '../Reports/Report12Result';
import { Report1Result } from '../Reports/Report1Result';
import { Report2Result } from '../Reports/Report2Result';
import { Report3Result } from '../Reports/Report3Result';
import { Report4Result } from '../Reports/Report4Result ';
import { Report5Result } from '../Reports/Report5Result';
import { Report6Result } from '../Reports/Report6Result';
import { Report7Result } from '../Reports/Report7Result';
import { Report8Result } from '../Reports/Report8Result';
import { Report9Result } from '../Reports/Report9Result';
import useDidMountEffect from '../../../../utilities/useDidMountEffect';
import useFetchData from '../../../../utilities/useFetchData';
import { DatabaseContext } from '../../../../../context/DatabaseContext';
import { rapportOption } from '../../../../../assets/beregningRapportOption';
import { OptionType } from '../../../components/commons/CommonTypes';
import { useUserContext } from '../../../../../context/AuthContext';
import { Tooltip } from '@mui/material';
import { NButton } from '../../../components/Inputs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useKeycloak } from '@react-keycloak/web';
import PollingComponent from '../../../components/commons/PollingComponent';

interface BeregningReportProps {
  beregningObject: BeregningObject;
  createReport: boolean;
  setShowProgress: (value: boolean) => void;
  setCreateReport: (value: boolean) => void;
  showAlertMessage: (message: string, severity: any) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  setProgressText: (value: string) => void;
  setStartPolling: (value: boolean) => void;
}
const BeregningReport = (props: BeregningReportProps) => {
  const {
    createReport,
    beregningObject,
    setShowProgress,
    setCreateReport,
    showAlertMessage,
    updateBeregningObject,
    setProgressText,
    setStartPolling
  } = props;
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler token';
  const { currentDatabase } = React.useContext(DatabaseContext);
  const [projectId] = React.useState<number>(
    beregningObject?.kostholdData?.projectId?.value
  );
  const [surveyId] = React.useState<number>(
    beregningObject?.kostholdData?.surveyId?.value
  );
  const [reportId] = React.useState<number>(beregningObject?.matvareData?.rapport?.value);
  let unitId = beregningObject?.matvareData?.enhet?.value;
  const [unitName] = React.useState<string>(
    unitId ? enhetOption[unitId]['label'] : 'none'
  );
  const [unitType, setUnitType] = React.useState<string>('g');
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>(
    'Beregningen er ikke fullført!'
  );
  const [errorReportId, setErrorReportId] = React.useState<number>(0);
  const [success, setSuccess] = React.useState<boolean>(false);
  const endReport = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'endReport',
    subProjectURL: `${errorReportId}/${errorMessage}`
  });
  React.useEffect(() => {
    if (createReport && beregningObject?.beregn) {
      sendBeregningRequest(reportId);
    }
  }, [createReport]);

  const getReportName = () => {
    const reportName =
      rapportOption?.find((opt: OptionType) => opt.value === reportId)?.label || '';
    return reportName?.replace(/[/]/g, '-') || 'rapportnavn';
  };

  const sendBeregningRequest = (reportId: number) => {
    if (unitId === 2) {
      setUnitType('g/10MJ');
    }
    if (!(reportId === 5 || reportId === 6)) {
      unitId = 1;
    }
    doRequest();
    setCreateReport(false);
    updateBeregningObject('', 'beregn', false);
  };
  const doRequest = () => {
    fetch(
      encodeURI(
        `${process.env.REACT_APP_BACKEND}/doBeregningReport/${projectId}/${surveyId}/${
          beregningObject?.databaseId
        }/${encodeURI(beregningObject?.utvalgId)}/${
          beregningObject?.matvareData?.gruppetypeId
        }/${reportId}/${encodeURI(unitName)}/${
          beregningObject?.kostholdData?.persondivisor
        }/${beregningObject?.kostholdData?.dagdivisor}/${
          currentDatabase?.label
        }/${encodeURI(getReportName())}/${unitId}`
      ),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then((resp) => {
        if (resp.status === 200) {
          console.log('Request has been sent successfully.');
          setStartPolling(true);
        } else {
          console.log('Request sending was not successful.');
          showAlertMessage('Beregningen er ikke fullført!', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return <></>;
};
export default BeregningReport;
