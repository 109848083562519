import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { NButton } from '../../../components/Inputs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface ValiderProps {
  showing: boolean;
  hide: () => void;
}

export default function LopedagValiderModal(props: ValiderProps) {
  const { showing, hide } = props;
  const [success, setSuccess] = useState<boolean>(false);

  return success ? (
    <DialogGeneral
      title="Data validert"
      titleIcon={<CheckCircleOutlineIcon sx={{ color: 'white' }} />}
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="sm"
    >
      <Stack direction={'row'} spacing={1} justifyContent="end" sx={{ pt: '1rem' }}>
        <NButton onClick={() => hide()} children="Ok" />
        <NButton
          onClick={() => setSuccess(false)}
          children={`Se "data validert" versjon`}
          variant="outlined"
        />
      </Stack>
    </DialogGeneral>
  ) : (
    <DialogGeneral
      title="Data mangler"
      titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="sm"
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          Ingen mengde angitt for måltid nr. [nr], matvarekode [kode]
        </Typography>
        <br />
        <Typography variant="body1">
          Ingen måltidskomponenter funnet for måltid nr. [nr]
        </Typography>
        <br />
        <Typography variant="body1">
          Status kan ikke settes til "Ferdig" uten at alt valideres.
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent="end" sx={{ pt: '1rem' }}>
          <NButton onClick={() => hide()} children="Ok" />
          <NButton
            onClick={() => setSuccess(true)}
            children={`Se "data validert" versjon`}
            variant="outlined"
          />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
