import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import {
  Dropdown,
  FormInput,
  FullHeightTextField,
  NButton
} from '../../../components/Inputs';
import { ProsjektI } from '../../types/ProjectTypes';
import { OppringingI, PersonI } from '../../types/PersonTypes';
import { useCodeContext } from '../../../../../context/CodeContext';
import { CodeRegisterI } from '../../../components/commons/CommonTypes';
import selectedDropdown from '../SelectedDropdown';
import ErrorMessage from '../../../../utilities/ErrorMessage';

interface RouteParams {
  prosjektId: string;
  undersokelseKortnavn: string;
}

interface PersonOppringingModalProps extends RouteComponentProps<RouteParams> {
  showing: boolean;
  hide: () => void;
  personData: PersonI;
  currentModalLopedag: number;
  currentOppringing: number;
  projectObject: ProsjektI;
  ringOpp: boolean;
  setActiveTab: Dispatch<SetStateAction<number>>;
  startTime: Date | null;
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
}

export default function PersonOppringingModal(props: PersonOppringingModalProps) {
  const {
    showing,
    hide,
    personData,
    currentModalLopedag,
    currentOppringing,
    projectObject,
    ringOpp,
    setActiveTab,
    startTime,
    setStartTime,
    handleSaveOppringing
  } = props;
  const { prosjektId, undersokelseKortnavn } = props.match.params;
  const [oppringingData, setOppringingData] = useState<OppringingI>({
    nr: currentOppringing,
    lopedagId: currentModalLopedag
  });
  // const [notatError, setNotatError] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const allCodes = useCodeContext();
  const currentModalLopedagData = personData?.lopedager?.find(
    (l) => l.lopedagId === currentModalLopedag
  );

  useEffect(() => {
    // if (!ringOpp) {
    setOppringingData(personData?.oppringinger?.find((o) => o.nr === currentOppringing));
    // } else {
    //   setOppringingData({
    //     nr: currentOppringing,
    //     lopedagId: currentModalLopedag
    //   });
    // }
  }, [personData]);

  // function handleDelete() {
  //   if (ringOpp) {
  //     hide();
  //   } else {
  //     const newOppringinger = personData?.oppringinger?.filter(
  //       (o) => o.nr !== currentOppringing
  //     );
  //     setPersonData({ ...personData, oppringinger: newOppringinger });
  //     hide();
  //   }
  // }

  const getDateFormat = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  function handleLagre() {
    if (oppringingData?.status === 9 && !oppringingData?.kommentar) {
      setShowError(true);
      setErrorMessage('Kommentar må fylles ut');
      return;
    }

    const newOppringing: OppringingI = {
      ...oppringingData,
      dato: getDateFormat(new Date()),
      status: oppringingData?.status,
      kommentar: oppringingData?.kommentar,
      varighet: oppringingData?.status === 1 && ringOpp ? 0 : oppringingData?.varighet
    };
    handleSaveOppringing(newOppringing, currentModalLopedag);

    hide();
  }

  function handleStartTid() {
    const newOppringing: OppringingI = {
      ...oppringingData,
      dato: getDateFormat(new Date()),
      status: 1,
      kommentar: oppringingData?.kommentar
    };
    handleSaveOppringing(newOppringing, currentModalLopedag);

    setStartTime(new Date());
    hide();
    setActiveTab(currentModalLopedag);
    window.scrollTo(0, 0);
  }

  return (
    <DialogGeneral title="Oppringing" open={showing} hide={hide} fullWidth maxWidth="md">
      <Stack spacing={2}>
        {/* <Stack direction={'row'} justifyContent={'space-between'} alignItems="baseline"> */}
        <Stack spacing={1}>
          <Typography variant="body1">
            <b>Prosjekt: </b> {projectObject?.prosjektnavn} - {prosjektId}
          </Typography>
          <Typography variant="body1">
            <b>Undersøkelse: </b> {undersokelseKortnavn}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Typography variant="body1">
              <b>Person: </b>
              {personData?.personId} - {personData?.identitet}
            </Typography>
            <Typography variant="body1">
              <b>Løpedag: </b>
              {oppringingData?.lopedagId && oppringingData?.lopedagId}
              {currentModalLopedagData?.ukedag && ' - ' + currentModalLopedagData?.ukedag}
            </Typography>
          </Stack>
        </Stack>
        {/* <NButton
            children="Slett"
            rightIcon={<DeleteOutlineIcon />}
            variant="text"
            onClick={handleDelete}
            // disabled={!personData?.personId}
          /> */}
        {/* </Stack> */}
        <Grid container>
          <Grid item xs={5} rowSpacing={1} paddingRight={2}>
            <Stack spacing={1}>
              <Typography variant="body1">
                <b>Oppringing: </b>
                {oppringingData?.nr && oppringingData?.nr}
              </Typography>
              <Typography variant="body1">
                <b>Dato: </b>
                {getDateFormat(new Date())}
              </Typography>
            </Stack>
            <Stack spacing={2} marginTop={2}>
              <Dropdown
                header="Status"
                value={selectedDropdown(oppringingData?.status, 13)}
                options={allCodes.codes?.filter((f) => f.value === 13)}
                optionLabel={(option: CodeRegisterI) => option?.label}
                onChange={(value: any) =>
                  setOppringingData({ ...oppringingData, status: value?.nr })
                }
              />
              <Box sx={{ width: '50%' }}>
                <FormInput
                  header="Varighet (min.)"
                  type="number"
                  value={oppringingData?.varighet ?? ''}
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) =>
                    setOppringingData({
                      ...oppringingData,
                      varighet: +event.target.value
                    })
                  }
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item container xs={7}>
            <FullHeightTextField
              header="Kommentar"
              name="kommentar"
              value={oppringingData?.kommentar}
              onChange={(event) => {
                setOppringingData({ ...oppringingData, kommentar: event.target.value });
                setShowError(false);
              }}
            />
            <ErrorMessage showing={showError} message={errorMessage} />
          </Grid>
        </Grid>

        <NButton
          onClick={handleLagre}
          disabled={
            oppringingData?.status === 1 || !oppringingData?.status
            // (oppringingData?.status === 9 && !oppringingData?.kommentar)
          }
          sx={{ maxWidth: 'max-content' }}
        >
          Lagre
        </NButton>
        <Stack direction="row" alignItems="end" spacing={2}>
          <NButton
            variant="outlined"
            onClick={handleStartTid}
            disabled={
              !ringOpp ||
              startTime !== null ||
              (oppringingData?.status !== 1 && oppringingData?.status !== undefined)
            }
            sx={{ maxWidth: 'max-content' }}
          >
            Intervjudetaljer (start tidsregistrering)
          </NButton>
          {startTime && (
            <Typography variant="caption" color={'text.secondary'}>
              {`Allerede startet: ${getDateFormat(startTime)}`}
            </Typography>
          )}
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
