import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useFetchData from '../../../utilities/useFetchData';
import { ExportToExcel } from '../../beregning/components/ExportToExcel';
import { UtvalgtMatvareData } from '../types/MatvareTypes';
import useDidMountEffect from '../../../utilities/useDidMountEffect';

interface UtvalgteMatvarerProps {
  currentUser: string;
  database: { value: number; label: string };
  calculationId: string;
  resultatGruppe: string;
  optionalParameters: string[];
  setVisResultat: (value: boolean) => void;
  setShowProgress: (value: boolean) => void;
  showAlertMessage: (message: string, severity: any) => void;
}

export const UtvalgteMatvarerResult = (props: UtvalgteMatvarerProps) => {
  const {
    currentUser,
    database,
    calculationId,
    resultatGruppe,
    optionalParameters,
    setVisResultat,
    setShowProgress,
    showAlertMessage
  } = props;

  const [dataToDisplay] = useState([]);
  const [infoToDisplay] = useState([]);
  const [resultReady, setResultReady] = useState(false);
  const [retrievedData, setRetrievedData] = useState<UtvalgtMatvareData>({
    MATVARER: [],
    STOFFER: [],
    STOFFKOMPONENTER: []
  });
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const reportInfo = [
    `Utvalgte matvarer, innhold pr. 100 g`,
    `System: NFC, matvaredatabase:  ${database['label']}`,
    `Resultatgruppe: ${resultatGruppe}`,
    `Dato:  ${moment().format('YYYY-MM-DD hh:mm:ss')}`
  ];
  const optionalParams = optionalParameters?.map((param: string) => {
    if (param === 'engelsk navn') {
      return 'navn_engelsk';
    } else if (param === 'engelsk beskrivelse') {
      return 'beskrivelse_engelsk';
    } else if (param === 'MVT-nr') {
      return 'senr';
    } else {
      return param;
    }
  });

  const utvalgtMatvares = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getUtvalgtMatvarer',
    subProjectURL: `${database['value']}/${encodeURI(calculationId)}`
  });

  React.useEffect(() => {
    if (retrievedData === undefined || retrievedData?.MATVARER?.length === 0) {
      utvalgtMatvares.loadData(
        'getUtvalgtMatvarer',
        `${database['value']}/${encodeURI(calculationId)}`
      );
    }
  }, []);
  React.useEffect(() => {
    if (success) {
      setVisResultat(false);
    }
  }, [success]);
  useEffect(() => {
    if (utvalgtMatvares.data) {
      setRetrievedData(utvalgtMatvares.data as unknown as UtvalgtMatvareData);
    }
  }, [utvalgtMatvares.data]);
  useEffect(() => {
    if (retrievedData?.MATVARER?.length > 0) {
      prepareResult(retrievedData);
    }
  }, [retrievedData]);
  useDidMountEffect(() => {
    if (error) {
      setShowProgress(false);
      setVisResultat(false);
      showAlertMessage('Kunne ikke lage resultatet!', 'error');
    }
  }, [error]);
  const prepareResult = (data: UtvalgtMatvareData) => {
    if (
      data.MATVARER?.length < 0 ||
      data.STOFFER?.length < 0 ||
      data.STOFFKOMPONENTER?.length < 0
    ) {
      console.log('empty input data.');
      return;
    }
    let description: string[] = [];
    for (let r of reportInfo) {
      description = [];
      description.push(r);
      infoToDisplay.push(description);
    }
    let result: any[] = [];
    const shortNames: any[] = [];
    const enhet: any[] = [];
    shortNames.push('Kode');
    shortNames.push('Navn');
    enhet.push('');
    enhet.push('');
    if (optionalParams?.includes('navn_engelsk')) {
      shortNames.push('Engelsk navn');
      enhet.push('');
    }
    if (optionalParams?.includes('senr')) {
      shortNames.push('MVT-nr');
      enhet.push('');
    }
    for (let stoff of data?.STOFFER) {
      shortNames.push(stoff.KORTNAVN);
      enhet.push(stoff.ENHET);
      if (optionalParams?.includes('kilde')) {
        shortNames.push('');
        enhet.push('Kilde');
      }
    }
    if (optionalParams?.includes('status')) {
      shortNames.push('Status');
      enhet.push('');
    }
    if (optionalParams?.includes('oppskrift')) {
      shortNames.push('Oppskrift');
      enhet.push('');
    }
    if (optionalParams?.includes('vektendring')) {
      shortNames.push('Vektendring');
      enhet.push('');
    }
    if (optionalParams?.includes('kommentar')) {
      shortNames.push('Kommentar');
      enhet.push('');
    }
    if (optionalParams?.includes('sokeord')) {
      shortNames.push('Sokeord');
      enhet.push('');
    }
    if (optionalParams?.includes('beskrivelse')) {
      shortNames.push('Beskrivelse');
      enhet.push('');
    }
    if (optionalParams?.includes('beskrivelse_engelsk')) {
      shortNames.push('Engelsk beskrivelse');
      enhet.push('');
    }
    if (optionalParams?.includes('referanse')) {
      shortNames.push('Referanse');
      enhet.push('');
    }
    dataToDisplay.push(shortNames);
    dataToDisplay.push(enhet);
    let n = 0;
    for (let mat of data?.MATVARER) {
      result = [];
      result.push(mat.MATVAREKODEID);
      result.push(mat.NAVN);
      if (optionalParams?.includes('navn_engelsk')) {
        result.push(mat.NAVN_ENGELSK);
      }
      if (optionalParams?.includes('senr')) {
        result.push(mat.SENR);
      }
      for (let s = 0; s < data?.STOFFER?.length; s++) {
        result.push(
          (data?.STOFFKOMPONENTER[n]?.MENGDE || 0).toFixed(data?.STOFFER[s]?.DESIMALER)
        );
        if (optionalParams?.includes('kilde')) {
          result.push(data?.STOFFKOMPONENTER[s]?.KILDE);
        }
        n++;
      }
      if (optionalParams?.includes('status')) {
        result.push(mat.STATUS);
      }
      if (optionalParams?.includes('oppskrift')) {
        result.push(mat.OPPSKRIFT);
      }
      if (optionalParams?.includes('vektendring')) {
        result.push(mat.VEKTENDRING);
      }
      if (optionalParams?.includes('kommentar')) {
        result.push(mat.KOMMENTAR);
      }
      if (optionalParams?.includes('sokeord')) {
        result.push(mat.SOKEORD);
      }
      if (optionalParams?.includes('beskrivelse')) {
        result.push(mat.BESKRIVELSE);
      }
      if (optionalParams?.includes('beskrivelse_engelsk')) {
        result.push(mat.BESKRIVELSE_ENGELSK);
      }
      if (optionalParams?.includes('referanse')) {
        result.push(mat.REFERANSE);
      }
      dataToDisplay.push(result);
    }
    setResultReady(true);
    setShowProgress(false);
    //setVisResultat(false);
  };
  return (
    <>
      <div>
        {resultReady && (
          <ExportToExcel
            user={currentUser}
            reportInfo={infoToDisplay}
            reportData={dataToDisplay}
            setError={setError}
            setSuccess={setSuccess}
            setErrorMessage={setErrorMessage}
          />
        )}
      </div>
    </>
  );
};
