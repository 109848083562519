import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import TabComponent from '../../utilities/TabComponent';
import Person from './Person';
import PageContainer from '../home/PageContainer';
import { Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Lopedag from './Lopedag';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { LopedagI, MaaltidskomponentI, OppringingI, PersonI } from './types/PersonTypes';
import { useProjectContext } from '../../../context/ProjectContext';
import { TabI } from '../components/commons/CommonTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { ApiCall } from '../../utilities/ApiCall';
import useFetchData from '../../utilities/useFetchData';
import ChangesModal from './components/modals/ChangesModal';
import PostData from '../../utilities/postData';
import { GyldighetsOmrade } from './types/ProjectTypes';
import isDeepEqual from '../../utilities/isDeepEqual';

interface ChangesContextType {
  changes: boolean;
  setChanges: Dispatch<SetStateAction<boolean>>;
}

export const ChangesContext = createContext<ChangesContextType | undefined>(undefined);

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  personId: string;
  lopedagId?: string;
  maaltidId: string;
}

const defaultTabArray: TabI[] = [{ tabType: 'person', label: 'Person', idx: 0 }];

interface PersonLayoutProps extends RouteComponentProps<RouteParams> {}
//TODO: automatically open selected lopedag and måltid(from url) if the values are not undefined
export default function PersonLayout(props: PersonLayoutProps) {
  const {
    prosjektId,
    undersokelseId,
    undersokelseKortnavn,
    personId,
    lopedagId,
    maaltidId
  } = props.match.params;
  const defaultPerson: PersonI = {
    personId: +personId,
    personStatus: 1,
    alder: 0,
    hoyde: 0,
    vekt: 0,
    bmi: 0,
    kjonn: 1,
    bostedType: 1,
    landsdel: 1,
    notat: '',
    skjemaStatus: 1,
    tilbakemeldingStatus: 1,
    opprettet: new Date(),
    endret: new Date(),
    lopedager: [],
    oppringinger: [],
    tilleggssvar: []
  };
  const [personData, setPersonData] = useState<PersonI | null>(defaultPerson);
  const [personDataCopy, setPersonDataCopy] = useState<PersonI | null>(defaultPerson);
  const [lopedagData, setLopedagData] = useState<LopedagI | null>(null);
  const [lopedagDataCopy, setLopedagDataCopy] = useState<LopedagI | null>(null);
  const [maaltidskomponentData, setMaaltidskomponentData] = useState<
    MaaltidskomponentI[] | null
  >(null);
  const [lopedagIdArray, setLopedagIdArray] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tempActiveTab, setTempActiveTab] = useState<number>(0);
  const [tabArray, setTabArray] = useState<TabI[]>(defaultTabArray);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [currentOppringing, setCurrentOppringing] = useState<number>();
  const [currentMaaltidId, setCurrentMaaltidId] = useState<number>(+maaltidId);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const [refetchPerson, setRefetchPerson] = useState<boolean>(false);
  const [refetchLopedag, setRefetchLopedag] = useState<boolean>(false);
  const [currentLopedag, setCurrentLopedag] = useState<number>(+lopedagId);
  const [newLopedag, setNewLopedag] = useState<boolean>(false);
  const [currentModalLopedag, setCurrentModalLopedag] = useState<number>();
  const [changes, setChanges] = useState<boolean>(false);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [gyldighetsOmraade, setGyldighetsOmraade] = useState<GyldighetsOmrade>();
  // const [maaltidskomponentDeleteArray, setMaaltidskomponentDeleteArray] =
  //   useState<number[]>();
  const { currentDatabase } = useContext(DatabaseContext);
  const projectContext = useProjectContext();
  const projectObject = projectContext.projects?.find(
    (p) => p.prosjektId === +prosjektId
  );
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const getPerson = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getPerson',
    subProjectURL: `${prosjektId}/${undersokelseId}/${personId}`
  });

  const isAlderValid: boolean =
    (personData?.alder >= gyldighetsOmraade?.minAlder &&
      personData?.alder <= gyldighetsOmraade?.maxAlder) ||
    personData?.alder === 0 ||
    personData?.alder === undefined;

  const isHoydeValid: boolean =
    (personData?.hoyde >= gyldighetsOmraade?.minHoyde &&
      personData?.hoyde <= gyldighetsOmraade?.maxHoyde) ||
    personData?.hoyde === 0 ||
    personData?.hoyde === undefined;

  const isVektValid: boolean =
    (personData?.vekt >= gyldighetsOmraade?.minVekt &&
      personData?.vekt <= gyldighetsOmraade?.maxVekt) ||
    personData?.vekt === 0 ||
    personData?.vekt === undefined;

  const redirectTo = (
    prosjektId: number,
    undersokelseId: number,
    undersokelseKortnavn: string,
    personId: number
  ) =>
    history.push(
      `/home/kostholdsdata/prosjekt/person/${prosjektId}/${undersokelseId}/${undersokelseKortnavn}/${personId}`
    );

  const setUrlParams = () => {
    if (prosjektId && +undersokelseId && personId) {
      redirectTo(+prosjektId, +undersokelseId, undersokelseKortnavn, +personId);
      setPersonData({ ...personData, personId: +personId });
      setPersonDataCopy({ ...personData, personId: +personId });
    }
  };

  useEffect(() => {
    setUrlParams();
  }, []);

  useEffect(() => {
    if (getPerson.data) {
      const data: PersonI = getPerson.data as unknown as PersonI;
      setPersonData(data);
      setPersonDataCopy(data);
    }
  }, [getPerson.data]);

  const callAlert = (message: string, severity: AlertI['alertColor']) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  function findFirstEmptyLopedag() {
    for (let i = 1; i < 50; i++) {
      if (!lopedagIdArray.includes(i)) {
        return i;
      }
    }
  }

  useEffect(() => {
    if (personData?.personId) {
      /*  ApiCall(
        `getPerson/${prosjektId}/${undersokelseId}/${personData?.personId}`,
        'GET',
        keycloak.token,
        setPersonData
      ).catch(console.error); */
      setRefetchPerson(false);
      redirectTo(
        +prosjektId,
        +undersokelseId,
        undersokelseKortnavn,
        personData?.personId
      );
    }
  }, [personData?.personId, refetchPerson]);

  useEffect(() => {
    if (
      !currentDatabase.value ||
      !prosjektId ||
      !undersokelseId ||
      !personId ||
      !currentLopedag
    )
      return;
    const fetchData = async () => {
      const response = await ApiCall<LopedagI>(
        `getLopedag/${currentDatabase.value}/${prosjektId}/${undersokelseId}/${personId}/${currentLopedag}`,
        'GET',
        keycloak.token
      );
      if (response?.ok) {
        const json = await response?.json();
        setLopedagData(json);
        setLopedagDataCopy(json);
      }
    };
    fetchData();
    setRefetchLopedag(false);
  }, [personData?.lopedager, refetchLopedag, currentLopedag]);

  // LOPEDAG ID ARRAY
  useEffect(() => {
    setLopedagIdArray(personData?.lopedager?.map((l) => l.lopedagId) || []);
  }, [personData?.lopedager]);

  // SET INITIAL TAB ARRAY
  useEffect(() => {
    if (personData?.lopedager !== undefined) {
      setTabArray([
        ...defaultTabArray,
        ...lopedagIdArray?.map((l) => {
          return {
            tabType: 'lopedag',
            label: `Dag ${l}`,
            idx: l
          };
        })
      ]);
    }
  }, [lopedagIdArray]);

  // SET CURRENT LØPEDAG ON TAB CHANGE
  useEffect(() => {
    if (activeTab > 0) {
      if (newLopedag) {
        setCurrentLopedag(findFirstEmptyLopedag());
      } else {
        setCurrentLopedag(lopedagIdArray[activeTab - 1]);
      }
    }
    setNewLopedag(false);
  }, [activeTab]);

  // NEW LOPEDAG
  const handleNewLopedag = () => {
    const newLopedag: LopedagI = {
      personId: +personData?.personId,
      lopedagId: findFirstEmptyLopedag()
    };
    handleSaveLopedag(newLopedag, true);
  };

  // LAGRE OG OPPDATERE LØPEDAG
  const handleSaveLopedag = async (inputData: LopedagI, newLopedag?: boolean) => {
    if (
      !owned ||
      !prosjektId ||
      !undersokelseId ||
      !lopedagData?.personId ||
      !lopedagData?.lopedagId
    )
      return;
    const promisesToAwait = [];
    promisesToAwait.push(saveAndUpdateMaaltidskomponenter(maaltidskomponentData));
    promisesToAwait.push(saveAndUpdateLopedag(inputData, newLopedag));
    await Promise.all(promisesToAwait);
    // for (let i of maaltidskomponentDeleteArray) {
    //   promisesToAwait.push(deleteMaaltidskomponent(i));
    // }
    // await Promise.all(promisesToAwait);
  };

  const saveAndUpdateMaaltidskomponenter = async (inputData: MaaltidskomponentI[]) => {
    if (!maaltidskomponentData?.length) return;
    const response = await ApiCall(
      `saveAndUpdateMaaltidsKomponenter/${prosjektId}/${undersokelseId}/${lopedagData?.personId}/${lopedagData?.lopedagId}`,
      'POST',
      keycloak?.token,
      undefined,
      { data: inputData }
    );
    if (response?.ok) {
      callAlert('Måltidskomponent lagret', 'success');
      setRefetchLopedag(true);
    } else {
      callAlert('Lagring av måltidskomponent feilet', 'error');
    }
  };

  // LAGRE OG OPPDATERE PERSON
  const handleSavePerson = () => {
    if (!personData?.personId || !personData?.personStatus) return;
    if (!isAlderValid || !isHoydeValid || !isVektValid) {
      callAlert(
        'Et eller flere felter er utenfor undersøkelsens gyldighetsområde',
        'error'
      );
      return;
    }
    PostData(
      { data: personData },
      'saveAndUpdatePerson',
      `${prosjektId}/${undersokelseId}`,
      'POST',
      keycloak.token
    ).then((resp: Response) => {
      if (resp.ok) {
        setShowAlert(true);
        setAlertMessage('Person lagret');
        setAlertSeverity('success');
        setChanges(false);
        getPerson.loadData('getPerson', `${prosjektId}/${undersokelseId}/${personId}`);
        /* redirectTo(
          +prosjektId,
          +undersokelseId,
          undersokelseKortnavn,
          personData?.personId
        ); */
      } else {
        console.log('Error: ', resp.status);
        setShowAlert(true);
        setAlertMessage('Lagring av person feilet');
        setAlertSeverity('error');
      }
    });
  };

  const saveAndUpdateLopedag = async (inputData: LopedagI, newLopedag?: boolean) => {
    if (!lopedagData?.personId || !lopedagData?.lopedagId) return;
    const response = await ApiCall(
      `saveAndUpdateLopedag/${prosjektId}/${undersokelseId}/${lopedagData?.personId}`,
      'POST',
      keycloak.token,
      undefined,
      { data: inputData }
    );
    if (response.ok) {
      if (newLopedag) {
        const newTabArray = [
          ...personData?.lopedager?.map((l) => l.lopedagId),
          findFirstEmptyLopedag()
        ].sort();
        setTabArray([
          ...defaultTabArray,
          ...newTabArray?.map((l) => {
            return {
              tabType: 'lopedag',
              label: `Dag ${l}`,
              idx: l
            };
          })
        ]);
        setNewLopedag(true);
        setActiveTab(findFirstEmptyLopedag());
      }
      callAlert('Løpedag lagret', 'success');
      setChanges(false);
      setRefetchPerson(true);
    } else {
      console.log('Error: ', response.status);
      callAlert('Lagring av løpedag feilet', 'error');
    }
  };

  // LAGRE OG OPPDATERE PERSON
  const handleSaveOppringing = (inputData: OppringingI, lopedagId: number) => {
    const fetchData = async () => {
      await ApiCall(
        `saveAndUpdateOppringing/${prosjektId}/${undersokelseId}/${personData?.personId}/${lopedagId}`,
        'POST',
        keycloak.token,
        undefined,
        { data: inputData }
      );
      setRefetchPerson(true);
    };
    fetchData().catch(console.error);
  };

  // AVSLUTT INTERVJU
  function handleAvslutt() {
    if (startTime) {
      const endTime = new Date();
      const spentTime = endTime.getTime() - startTime.getTime();
      const minutes = Math.round(spentTime / (1000 * 60));
      handleSaveOppringing(
        {
          ...personData?.oppringinger?.find((o) => o.nr === currentOppringing),
          varighet: minutes
        },
        currentModalLopedag
      );
    }
    setStartTime(null);
  }

  useEffect(() => {
    if (!personData || !personDataCopy) return;
    if (isDeepEqual(personData, personDataCopy)) {
      setChanges(false);
    } else {
      setChanges(true);
    }
  }, [personData, personDataCopy]);

  useEffect(() => {
    if (!lopedagData || !lopedagDataCopy) return;
    if (isDeepEqual(lopedagData, lopedagDataCopy)) {
      setChanges(false);
    } else {
      setChanges(true);
    }
  }, [lopedagData, lopedagDataCopy]);

  const handleJa = () => {
    setShowChangesModal(false);
    if (activeTab === 0) {
      handleSavePerson();
    } else {
      handleSaveLopedag(lopedagData);
    }
    setActiveTab(tempActiveTab);
  };

  const handleNei = () => {
    setShowChangesModal(false);
    if (activeTab === 0) {
      setPersonData(personDataCopy);
    } else {
      setLopedagData(lopedagDataCopy);
    }
    setActiveTab(tempActiveTab);
  };

  return (
    <ChangesContext.Provider value={{ changes, setChanges }}>
      <PageContainer>
        <Paper style={{ padding: '5rem 15px 1.5em 15px', boxShadow: 'none' }}>
          <TabComponent
            tabArray={tabArray}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setTempActiveTab={setTempActiveTab}
            setShowChangesModal={setShowChangesModal}
            tabIcon={(tabType) =>
              tabType === 'person' ? <PersonIcon /> : <EditCalendarIcon />
            }
            // position="fixed"
            appBar
            panel={(tabType: string) =>
              tabType === 'person' ? (
                <Person
                  personData={personData}
                  setPersonData={setPersonData}
                  projectObject={projectObject}
                  handleNewLopedag={handleNewLopedag}
                  setActiveTab={setActiveTab}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  currentOppringing={currentOppringing}
                  setCurrentOppringing={setCurrentOppringing}
                  handleAvslutt={handleAvslutt}
                  handleSaveOppringing={handleSaveOppringing}
                  currentModalLopedag={currentModalLopedag}
                  setCurrentModalLopedag={setCurrentModalLopedag}
                  redirectTo={redirectTo}
                  callAlert={callAlert}
                  handleSavePerson={handleSavePerson}
                  setGyldighetsOmraade={setGyldighetsOmraade}
                  isAlderValid={isAlderValid}
                  isHoydeValid={isHoydeValid}
                  isVektValid={isVektValid}
                  {...props}
                />
              ) : tabType === 'lopedag' ? (
                <Lopedag
                  lopedagData={lopedagData}
                  setLopedagData={setLopedagData}
                  personData={personData}
                  maaltidskomponentData={maaltidskomponentData}
                  setMaaltidskomponentData={setMaaltidskomponentData}
                  projectObject={projectObject}
                  startTime={startTime}
                  handleAvslutt={handleAvslutt}
                  setActiveTab={setActiveTab}
                  setShowAlert={setShowAlert}
                  setAlertMessage={setAlertMessage}
                  setAlertSeverity={setAlertSeverity}
                  handleSaveLopedag={handleSaveLopedag}
                  setRefetchPerson={setRefetchPerson}
                  setRefetchLopedag={setRefetchLopedag}
                  currentLopedag={currentLopedag}
                  currentModalLopedag={currentModalLopedag}
                  currentMaaltidId={currentMaaltidId}
                  setCurrentMaaltidId={setCurrentMaaltidId}
                  // setMaaltidskomponentDeleteArray={setMaaltidskomponentDeleteArray}
                  callAlert={callAlert}
                  {...props}
                />
              ) : null
            }
          />

          {showChangesModal && (
            <ChangesModal
              showing={showChangesModal}
              hide={() => setShowChangesModal(false)}
              handleJa={handleJa}
              handleNei={handleNei}
            />
          )}

          {showAlert && (
            <AlertComp
              alertMessage={alertMessage}
              alertColor={alertSeverity}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          )}
        </Paper>
      </PageContainer>
    </ChangesContext.Provider>
  );
}
