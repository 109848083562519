import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { MouseEvent, ChangeEvent, useState, useMemo, useEffect } from 'react';
import { CustomTableInput, FormInput, NButton } from '../components/Inputs';
import PageContainer from '../home/PageContainer';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { RouteComponentProps } from 'react-router-dom';
import { useProjectContext } from '../../../context/ProjectContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import { TilleggsvariableI, TilleggsvariableTableI } from './types/TilleggsvariableTypes';
import { HeadCell } from '../components/commons/CommonTypes';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import TilleggsvariablerSearchModal from './components/modals/TilleggsvariablerSearchModal';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ApiCall } from '../../utilities/ApiCall';
import { useKeycloak } from '@react-keycloak/web';
import DeleteModal from './components/modals/DeleteModal';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { CountResult } from './types/ProjectTypes';
import EnhancedTilleggsvariabelRow from './EnhancedTilleggsvariabelRow';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import UndersokelseCopyVariablerModal from './components/modals/UndersokelseCopyVariablerModal';

const headCells: HeadCell<TilleggsvariableI>[] = [
  {
    id: 'sortering',
    align: 'right',
    padding: 'normal',
    label: 'Sort.'
  },
  {
    id: 'variabelId',
    align: 'right',
    padding: 'normal',
    label: 'ID'
  },
  {
    id: 'kortnavn',
    align: 'left',
    padding: 'none',
    label: 'Kortnavn'
  },
  {
    id: 'sporsmal',
    align: 'left',
    padding: 'normal',
    label: 'Spørsmål'
  },
  {
    id: 'svartype',
    align: 'left',
    padding: 'normal',
    label: 'Svartype'
  }
];

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface UTilleggsvariablerProps extends RouteComponentProps<RouteParams> {}

export default function UndersokelseTilleggsvariabler(props: UTilleggsvariablerProps) {
  const { prosjektId, undersokelseId } = props.match.params;
  const [variablerData, setVariablerData] = useState<TilleggsvariableTableI[] | null>(
    null
  );
  const [allVariabler, setAllVariabler] = useState<TilleggsvariableI[]>();
  const [allVariablerOriginal, setAllVariablerOriginal] = useState<TilleggsvariableI[]>();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TilleggsvariableI>('sortering');
  const [showVariablerSearch, setShowVariablerSearch] = useState<boolean>(false);
  const [showDeleteVariabel, setShowDeleteVariabel] = useState<boolean>(false);
  const [showCopyVariabler, setShowCopyVariabler] = useState<boolean>();
  const [addMultiple, setAddMultiple] = useState<boolean>(false);
  const [newRowKortnavn, setNewRowKortnavn] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [variableHasInfo, setVariableHasInfo] = useState<CountResult>();
  const [undersokelseHasInfo, setUndersokelseHasInfo] = useState<CountResult>();
  const [refetchVariabler, setRefetchVariabler] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const projectContext = useProjectContext();
  const projectObject = projectContext.projects?.find(
    (p) => p.prosjektId === +prosjektId
  );
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  const callAlert = (message: string, severity: AlertI['alertColor']) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  useEffect(() => {
    if (!prosjektId || !undersokelseId) return;
    ApiCall(
      `getTilleggsvariabler/${prosjektId}/${undersokelseId}`,
      'GET',
      keycloak.token,
      setVariablerData
    ).catch(console.error);
    setRefetchVariabler(false);
    setDataChanged(false);
  }, [prosjektId, undersokelseId, refetchVariabler]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiCall('getTilleggsvariablerList', 'GET', keycloak.token);
      if (!response.ok) {
        console.error(response.status);
        return;
      }
      const json = await response.json();
      setAllVariabler(json);
      setAllVariablerOriginal(json);
    };
    fetchData();
  }, []);

  const saveAndUpdateTilleggsvariabler = async (inputData: TilleggsvariableI[]) => {
    const response = await ApiCall(
      `saveAndUpdateTilleggsvariabler/${prosjektId}/${undersokelseId}`,
      'POST',
      keycloak?.token,
      undefined,
      { data: inputData }
    );
    if (response.ok) {
      setDataChanged(false);
      callAlert('Tilleggsvariabler lagret', 'success');
    } else {
      console.log('Error', response.status);
      callAlert('Lagring av tilleggsvariabler feilet', 'error');
    }
  };

  const deleteTilleggsvariable = () => {
    if (!owned || !deleteId) return;
    const fetchDelete = async () => {
      const response = await ApiCall(
        `deleteTilleggsvariable/${prosjektId}/${undersokelseId}/${deleteId}`,
        'DELETE',
        keycloak?.token,
        undefined,
        { data: [] }
      );
      if (response.ok) {
        setRefetchVariabler(true);
        callAlert('Tilleggsvariabel slettet', 'success');
      } else {
        console.error(response.status);
        callAlert('Sletting av tilleggsvariabel feilet', 'error');
      }
    };
    fetchDelete();
    setDeleteId(null);
  };

  const checkIfVariableHasInfo = (variabelId: number) => {
    ApiCall<CountResult>(
      `checkIfVariableHasSvar/${prosjektId}/${undersokelseId}/${variabelId}`,
      'GET',
      keycloak?.token,
      setVariableHasInfo
    );
  };

  // HANDLING

  const handleCopy = async () => {
    const response = await ApiCall<CountResult>(
      `checkIfUndersokelseHasVariables/${prosjektId}/${undersokelseId}`,
      'GET',
      keycloak?.token
    );
    if (response?.ok) {
      const json: CountResult = await response?.json();
      if (json?.COUNT > 0) {
        callAlert('Valgt undersøkelse inneholder allerede tilleggsvariabler', 'error');
      } else {
        setShowCopyVariabler(true);
      }
    } else {
      console.log('error: could not check if undersokelse has variables');
    }
  };

  const handleRequestSort = (property: keyof TilleggsvariableI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMove = (index: number, direction: string) => {
    variablerData?.sort((a, b) => a.sortering - b.sortering);
    const clickedRow = variablerData?.find((n) => n.sortering === index);
    const adjacentRow =
      variablerData[
        variablerData?.indexOf(clickedRow) +
          (order === 'desc'
            ? -(direction === 'up' ? -1 : 1)
            : direction === 'up'
            ? -1
            : 1)
      ];
    const newSortering = variablerData?.map((n) =>
      n.sortering === clickedRow?.sortering
        ? { ...n, sortering: adjacentRow?.sortering }
        : n.sortering === adjacentRow?.sortering
        ? { ...n, sortering: clickedRow?.sortering }
        : n
    );
    setVariablerData(newSortering);
    setDataChanged(true);
  };

  const rows = useMemo(
    () => variablerData?.slice().sort(getComparator(order, orderBy)),
    [variablerData, order, orderBy]
  );

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        <Stack spacing={2}>
          <Stack direction={'row'} useFlexGap>
            <Typography variant="h4">Tilleggsvariabler for undersøkelse</Typography>
            <NButton
              children="Kopi / duplikat"
              rightIcon={<ContentCopyOutlinedIcon />}
              variant="text"
              sx={{ ml: 'auto' }}
              onClick={handleCopy}
            />
            <NButton
              children="Skriv ut"
              rightIcon={<PrintOutlinedIcon />}
              variant="text"
              onClick={() => console.log('todo: skriv ut')}
            />
          </Stack>
          <Typography variant="h6" paddingBottom={2}>
            Prosjekt: {prosjektId && prosjektId} - {projectObject?.prosjektnavn}
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead<TilleggsvariableI>
                headCells={headCells}
                rightColumns={[
                  { name: 'Flytt', align: 'center' },
                  { name: 'Slett', align: 'center' }
                ]}
                sorting={{
                  order: order,
                  orderBy: orderBy,
                  onRequestSort: handleRequestSort
                }}
              />
              <TableBody>
                {rows?.map((row, index) => (
                  <EnhancedTilleggsvariabelRow
                    key={row.variabelId}
                    row={row}
                    index={index}
                    headCells={headCells}
                    variablerData={variablerData}
                    orderBy={orderBy}
                    handleMove={handleMove}
                    checkIfVariableHasInfo={checkIfVariableHasInfo}
                    setDeleteId={setDeleteId}
                    setShowDeleteVariabel={setShowDeleteVariabel}
                  />
                ))}

                <TableRow>
                  {headCells.map((cell, index) =>
                    cell.id === 'kortnavn' ? (
                      <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                        <Stack direction={'row'} alignItems="center">
                          <IconButton
                            onClick={() => {
                              setAddMultiple(false);
                              setShowVariablerSearch(true);
                            }}
                          >
                            <ChatBubbleOutlineIcon />
                          </IconButton>
                          <CustomTableInput
                            value={newRowKortnavn}
                            type={cell.align === 'right' ? 'number' : 'text'}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              setNewRowKortnavn(event.target.value);
                              setAddMultiple(false);
                              setShowVariablerSearch(true);
                            }}
                          />
                        </Stack>
                      </TableCell>
                    ) : (
                      <TableCell key={cell.id} />
                    )
                  )}
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction={'row'} spacing={1}>
            <NButton
              children="Lagre"
              onClick={() => saveAndUpdateTilleggsvariabler(variablerData)}
              disabled={!dataChanged}
            />
            <NButton
              children="Søke og legge til flere tilleggsvariabler"
              leftIcon={<LibraryAddOutlinedIcon />}
              onClick={() => {
                setAddMultiple(true);
                setShowVariablerSearch(true);
              }}
            />
            <NButton
              children="Nullstill"
              onClick={() => setRefetchVariabler(true)}
              disabled={!dataChanged}
            />
          </Stack>
        </Stack>

        {/* dialogs  */}

        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}

        {showVariablerSearch && (
          <TilleggsvariablerSearchModal
            showing={showVariablerSearch}
            hide={() => {
              setShowVariablerSearch(false);
              setNewRowKortnavn('');
            }}
            variablerData={variablerData}
            setVariablerData={setVariablerData}
            allVariabler={allVariabler}
            setAllVariabler={setAllVariabler}
            allVariablerOriginal={allVariablerOriginal}
            addMultiple={addMultiple}
            setDataChanged={setDataChanged}
            newRowKortnavn={newRowKortnavn}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
          />
        )}

        {showCopyVariabler && (
          <UndersokelseCopyVariablerModal
            showing={showCopyVariabler}
            hide={() => {
              setUndersokelseHasInfo(null);
              setShowCopyVariabler(false);
            }}
            callAlert={callAlert}
            setRefetchVariabler={setRefetchVariabler}
            {...props}
          />
        )}

        {showDeleteVariabel && (
          <DeleteModal
            showing={showDeleteVariabel}
            hide={() => {
              setVariableHasInfo(null);
              setShowDeleteVariabel(false);
            }}
            handleDelete={deleteTilleggsvariable}
            title="Slette tilleggsvariabel?"
            body={
              variableHasInfo?.COUNT > 0
                ? `Tilleggsvariabel ${deleteId} har ${variableHasInfo?.COUNT} registrerte svar. Ønsker du å slette den?`
                : `Ønsker du å slette tilleggsvariabel ${deleteId}?`
            }
          />
        )}
      </Paper>
    </PageContainer>
  );
}
