import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { LopedagI, OppringingI, PersonI } from './types/PersonTypes';
import CallIcon from '@mui/icons-material/Call';
import { EnhancedTableHead } from '../../utilities/EnhancedTableHead';
import { HeadCell } from '../components/commons/CommonTypes';
import TableEmptyRows from '../../utilities/TableEmptyRows';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';
import { useKeycloak } from '@react-keycloak/web';
import { RouteComponentProps } from 'react-router-dom';
import { useCodeContext } from '../../../context/CodeContext';

const headCells: HeadCell<LopedagI>[] = [
  {
    id: 'intervjuer',
    align: 'left',
    padding: 'normal',
    label: 'Intervjuer'
  },
  {
    id: 'ukedag',
    align: 'left',
    padding: 'normal',
    label: 'Ukedag'
  },
  {
    id: 'vanligdag',
    align: 'left',
    padding: 'normal',
    label: 'Vanlig?'
  },
  {
    id: 'statuskode',
    align: 'left',
    padding: 'normal',
    label: 'Status'
  },
  {
    id: 'notat',
    align: 'left',
    padding: 'normal',
    label: 'Notat'
  }
];

interface RouteParams {
  prosjektId: string;
}

interface PersonLopedagerTableProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  setShowOppringing: Dispatch<SetStateAction<boolean>>;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  setCurrentOppringing: Dispatch<SetStateAction<number>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setRingOpp: Dispatch<SetStateAction<boolean>>;
  oppringingIds: Set<number>;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
}

export default function PersonLopedager(props: PersonLopedagerTableProps) {
  const {
    personData,
    setShowOppringing,
    setCurrentModalLopedag,
    setCurrentOppringing,
    setActiveTab,
    setRingOpp,
    oppringingIds,
    handleSaveOppringing
  } = props;
  const { prosjektId } = props.match.params;
  const allCodes = useCodeContext();
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );

  const vanligDagLabel = (nr: number) => (nr === 1 ? 'Ja' : nr === 2 ? 'Nei' : '');
  const statusLabel = (nr: number) =>
    allCodes?.codes?.find((c) => c.value === 11 && c.nr === nr)?.label;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <EnhancedTableHead<LopedagI>
          headCells={headCells}
          leftColumns={[{ name: 'Nr', padding: 'checkbox' }, { name: 'Ring opp' }]}
        />
        <TableBody>
          {personData?.lopedager?.map((row) => (
            <TableRow key={row.lopedagId} hover>
              <TableCell
                onClick={() => {
                  setActiveTab(row.lopedagId);
                  window.scrollTo(0, 0);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Link>{row['lopedagId']}</Link>
              </TableCell>
              <TableCell padding="checkbox">
                <Tooltip title={!owned ? 'Mangler rettigheter' : ''}>
                  <span>
                    <Button
                      onClick={() => {
                        const newCurrentOppringing =
                          oppringingIds.size > 0
                            ? Math.max(...[...oppringingIds?.values()]) + 1
                            : 1;
                        setCurrentOppringing(newCurrentOppringing);
                        setCurrentModalLopedag(row.lopedagId);
                        setRingOpp(true);
                        handleSaveOppringing(
                          {
                            nr: newCurrentOppringing,
                            lopedagId: row.lopedagId
                          },
                          row.lopedagId
                        );
                        setShowOppringing(true);
                      }}
                      disabled={!owned}
                      aria-label="call"
                    >
                      <CallIcon />
                    </Button>
                  </span>
                </Tooltip>
              </TableCell>
              {headCells.map((cell) => (
                <TableCell key={cell.id} padding={cell.padding} align={cell.align}>
                  {cell.id === 'vanligdag'
                    ? vanligDagLabel(row[cell.id])
                    : cell.id === 'statuskode'
                    ? statusLabel(+row[cell.id])
                    : row[cell.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableEmptyRows<LopedagI>
            headCells={headCells}
            dataLength={personData?.lopedager?.length}
            minRows={3}
            extraCells={2}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
