import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { NButton } from '../../../components/Inputs';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogGeneral from '../../../../utilities/DialogGeneral';

interface LopedagRemindersModalProps {
  showing: boolean;
  hide: () => void;
}

export default function LopedagRemindersModal(props: LopedagRemindersModalProps) {
  const { showing, hide } = props;

  return (
    <DialogGeneral
      title="Har du husket å lese opp hurtiglista?"
      titleIcon={<ErrorOutlineIcon sx={{ color: 'white' }} />}
      open={showing}
      hide={hide}
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          'Til slutt leser jeg opp en liste over matvarer det er lett å glemme. <br /> Er
          det noe av dette du spiste eller drakk i går, som vi ikke har fått med?'
        </Typography>
        <List disablePadding>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Tyggegummi eller pastiller?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Godteri eller sjokolade?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Kaffe eller te?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Alkoholholdig drikke, brus eller vann?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Kjeks, kaker eller boller?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Frukt, tørket frukt eller nøtter?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Potetgull eller annen snacks?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Rester (fra middag eller lignende)?</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText>Tran eller andre kosttilskudd?</ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1">
          <b>Norkost 4:</b> Husk å stille spørsmål om takeaway/matkasser/Foodora o.l. før
          de øvrige bakgrunnspørsmålene.
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent="end">
          <NButton onClick={() => hide()} children="Ja" />
          <NButton onClick={() => hide()} children="Nei" variant="outlined" />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
