export enum StoffType {
  single = 'single stoff',
  group = 'stoff group',
  both = 'single and group'
}
export enum SingleStoffTypes {
  stoffDef = 'stoff definition',
  stoffMat = 'stoff in matvare'
}
export interface SingleStoff {
  STOFFNRID: number | null;
  KORTNAVN: string | null;
  NAVN: string | null;
  NAVN_ENGELSK: string | null;
  DESIMALER: number | null;
  ENHET: string | null;
  SORTERING: string | null;
  ENERGIFAKTOR: number | null;
  REGNBART: string | null;
  INNGAAR_ENERGI: string | null;
  BESKRIVELSE: string | null;
}
export interface CopyData {
  navn: string;
  oldStoffgruppeid: number;
}

export interface StoffI {
  stoffnrId: number;
  kortnavn: string;
  navn: string;
  enhet: string;
  sortering: number;
  regnbart: string;
  engelskNavn?: string;
  beskrivelse?: string;
  desimaler?: number;
  ingaari?: string;
  energifaktor?: number;
  selected?: boolean;
}
export interface StoffgruppeI {
  navn: string;
  stoffgruppeId: number;
  beskrivelse?: string;
  tilgangskode?: number;
}
