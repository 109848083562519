import React from 'react';
import DialogComponent from '../../../../utilities/DialogComponent';
import {
  BeregningObject,
  DefaultBeregningCounts,
  ReportResultTableI,
  UtvalgTableData
} from '../../types/BeregningTypes';
import { SaveInputs } from '../SaveInputs';
import BeregningReport from './BeregningReport';

interface BeregningReportContainerProps {
  surveyDatabase: number;
  beregningObject: BeregningObject;
  //beregnReport: boolean;
  //setBeregnReport: (value: boolean) => void;
  showAlertMessage: (message: string, severity: any) => void;
  setBeregningObject: (obj: BeregningObject) => void;
  setShowProgress: (value: boolean) => void;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  setUtvalgData: (value: UtvalgTableData) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  setProgressText: (value: string) => void;
  cleared: boolean;
  setStartPolling: (value: boolean) => void;
}
const BeregningReportContainer = (props: BeregningReportContainerProps) => {
  const {
    surveyDatabase,
    beregningObject,
    showAlertMessage,
    setBeregningObject,
    setShowProgress,
    setUtvalgData,
    setTotalCounts,
    updateBeregningObject,
    setProgressText,
    cleared,
    setStartPolling
    //beregnReport,
    //setBeregnReport
  } = props;
  const [showBeregnWithDifferentDb, setShowBeregnWithDifferentDb] = React.useState(false);
  const [showHugeDataConfirmation, setShowHugeDataConfirmation] = React.useState(false);
  const [createReport, setCreateReport] = React.useState(false);

  const [saveBeregningInput, setSaveBeregningInput] = React.useState(false);
  React.useEffect(() => {
    if (beregningObject?.beregn) {
      setCreateReport(false);
      if (
        (beregningObject?.matvareData?.rapport?.value === 10 ||
          beregningObject?.matvareData?.rapport?.value === 12) &&
        (beregningObject?.stoffData?.stoffGroup?.length > 0 ||
          beregningObject?.stoffData?.individualStoffs?.length != 1)
      ) {
        showAlertMessage('Mer enn ett stoff valgt!', 'error');
      } else {
        if (beregningObject?.inputsSaved) {
          setBeregningObject({ ...beregningObject, cleanPreviousData: true });
          startBeregning();
        } else {
          setSaveBeregningInput(true);
        }
      }
    }
  }, [beregningObject.beregn, beregningObject?.inputsSaved]);
  React.useEffect(() => {
    if (cleared) {
      setSaveBeregningInput(false);
    }
    if (!beregningObject?.inputsSaved && beregningObject?.matvareData?.rapport?.value) {
      setSaveBeregningInput(true);
    }
  }, [beregningObject?.matvareData?.rapport?.value, cleared]);

  React.useEffect(() => {
    if (cleared) {
      setSaveBeregningInput(false);
    }
    if (!beregningObject?.inputsSaved && beregningObject?.matvareData?.rapport?.value) {
      setSaveBeregningInput(true);
    }
  }, [beregningObject?.matvareData?.rapport?.value, cleared]);

  const startBeregning = () => {
    if (beregningObject?.databaseId !== surveyDatabase) {
      setShowBeregnWithDifferentDb(true);
    } else {
      continueBeregning();
    }
  };
  const checkDatasize = () => {
    if (
      beregningObject?.matvareData?.matvaregroups?.length +
        beregningObject?.matvareData?.individualMatvares?.length >
      225
    ) {
      setShowHugeDataConfirmation(true);
    } else {
      setStartVariables();
    }
  };
  const continueBeregning = () => {
    setShowBeregnWithDifferentDb(false);
    checkDatasize();
  };
  const setStartVariables = () => {
    setCreateReport(true);
  };
  const handleClickNei = () => {
    closeDialog();
  };

  const closeDialogHugeData = () => {
    setShowHugeDataConfirmation(false);
    setBeregningObject({ ...beregningObject, beregn: false });
  };
  const handleClickJaHugeData = () => {
    setStartVariables();
    setShowHugeDataConfirmation(false);
  };
  const handleClickNeiHugeData = () => {
    closeDialogHugeData();
  };
  const closeDialog = () => {
    setShowBeregnWithDifferentDb(false);
    setBeregningObject({ ...beregningObject, beregn: false });
  };
  return (
    <>
      {saveBeregningInput && (
        <SaveInputs
          beregningObject={beregningObject}
          showAlertMessage={showAlertMessage}
          setBeregningObject={setBeregningObject}
          setUtvalgData={setUtvalgData}
          setTotalCounts={setTotalCounts}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
        />
      )}
      {createReport && (
        <BeregningReport
          beregningObject={beregningObject}
          updateBeregningObject={updateBeregningObject}
          createReport={createReport}
          setCreateReport={setCreateReport}
          setShowProgress={setShowProgress}
          //setBeregnReport={setBeregnReport}
          showAlertMessage={showAlertMessage}
          setProgressText={setProgressText}
          setStartPolling={setStartPolling}
        />
      )}
      {showBeregnWithDifferentDb && (
        <DialogComponent
          title={'Matvaredatabase varsel'}
          message={
            'Den valgte undersøkelsen er opprettet for en annen matvaredatabase enn den valgte.\nVil du allikevel utføre beregningen?'
          }
          open={showBeregnWithDifferentDb}
          close={closeDialog}
          handleClickJa={continueBeregning}
          handleClickNei={handleClickNei}
        />
      )}
      {showHugeDataConfirmation && (
        <DialogComponent
          title={'Stor data varsel'}
          message={
            'Totalt antall matvaregrupper + enkeltmatvarer kan være for stort for Excel og beregningen kan ta lenge tid.\nVil du allikevel utføre beregningen?'
          }
          open={showHugeDataConfirmation}
          close={closeDialogHugeData}
          handleClickJa={handleClickJaHugeData}
          handleClickNei={handleClickNeiHugeData}
        />
      )}
    </>
  );
};
export default BeregningReportContainer;
