import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../../utilities/DialogBox';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@mui/material';
import useFetchData from '../../../utilities/useFetchData';
import useStylesTable from '../../../../css/TableCss';
import { Matvare } from '../../matvare/types/matvaregruppeTypes';
import {
  BeregningObject,
  DefaultBeregningCounts,
  MatvareGroupData,
  // StoffData,
  UtvalgTableData
} from '../types/BeregningTypes';
import { SaveInputs } from './SaveInputs';
import { Checkbox } from '@mui/material';
import Fjern from './Fjern';
import useStylesButton from '../../../../css/ButtonCss';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
//import { Stoff } from '../../matvare/types/StoffTypes';
import { NButton } from '../../components/Inputs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { StoffI, StoffgruppeI } from '../../stoff/types/StoffTypes';
interface UtvalgTableDataProps {
  beregningObject: BeregningObject;
  isBeregning: boolean;
  showAlertMessage: (message: string, severity: any) => void;
  setBeregningObject: (obj: BeregningObject) => void;
  totalCounts: DefaultBeregningCounts;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  utvalgData: UtvalgTableData;
  setUtvalgData: (value: UtvalgTableData) => void;
  hide: () => void;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
}
export const UtvalgTableComponent = (props: UtvalgTableDataProps) => {
  const {
    beregningObject,
    isBeregning,
    totalCounts,
    setBeregningObject,
    showAlertMessage,
    setTotalCounts,
    utvalgData,
    setUtvalgData,
    hide,
    setShowProgress,
    setProgressText
  } = props;
  const classesButton = useStylesButton();
  const [showDialogbox, setShowDialogbox] = useState(false);
  const title = 'Vis utvalg';
  const classesTable = useStylesTable();
  const [showSaveBeregningInput, setShowSaveBeregningInput] = useState(false);
  const [pageMG, setPageMG] = useState(0);
  const [rowsPerPageMG, setRowsPerPageMG] = useState(20);
  const [pageM, setPageM] = useState(0);
  const [rowsPerPageM, setRowsPerPageM] = useState(20);
  const [pageFM, setPageFM] = useState(0);
  const [rowsPerPageFM, setRowsPerPageFM] = useState(20);
  const [pageS, setPageS] = useState(0);
  const [rowsPerPageS, setRowsPerPageS] = useState(20);
  const [loadCount, setLoadCount] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMatvaregroups, setSelectedMatvaregroups] = useState<MatvareGroupData[]>(
    []
  );
  const [loadUtvalgData, setLoadUtvalgData] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MatvareGroupData>(null);

  const retrieveDataFromUtvalgTable = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getUtvalgTableData',
    subProjectURL: `${beregningObject?.databaseId}/${encodeURI(
      beregningObject?.utvalgId
    )}/${beregningObject?.matvareData?.gruppetypeId}`
  });
  useEffect(() => {
    if (!beregningObject?.beregn) {
      if (!beregningObject?.inputsSaved) {
        setShowSaveBeregningInput(true);
      }
    }
  }, [beregningObject?.inputsSaved, beregningObject?.beregn]);
  useDidMountEffect(() => {
    if (loadUtvalgData) {
      retrieveDataFromUtvalgTable.loadData(
        'getUtvalgTableData',
        `${beregningObject?.databaseId}/${encodeURI(beregningObject?.utvalgId)}/${
          beregningObject?.matvareData?.gruppetypeId
        }`
      );
    }
  }, [loadUtvalgData]);
  useEffect(() => {
    if (retrieveDataFromUtvalgTable.data) {
      const data = retrieveDataFromUtvalgTable?.data as unknown as UtvalgTableData;
      setDataTodisplay(data);
    }
  }, [retrieveDataFromUtvalgTable.data]);
  useEffect(() => {
    if (utvalgData) {
      setDataTodisplay(utvalgData);
    }
  }, [utvalgData]);
  const setDataTodisplay = (data: UtvalgTableData) => {
    setTotalCounts({
      TOTALMATVAREGRUPPE: data?.matvaregroupData?.length,
      TOTALMATVARE: data?.matvaredata?.length,
      TOTALFJERNDEMATVARE: data?.fjerndeMatvareData?.length,
      TOTALSTOFFCOUNT: data?.stoffData?.length
    });
    setUtvalgData(data);
    setShowDialogbox(true);
    setLoadUtvalgData(false);
  };
  const handleCloseMatavreGruppe = () => {
    setShowDialogbox(false);
    hide();
  };
  const handleChangePageMG = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageMG(newPage);
  };
  const handleChangeRowsPerPageMG = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageMG(+event.target.value);
    setPageMG(0);
  };
  const handleChangePageM = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageM(newPage);
  };
  const handleChangeRowsPerPageM = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageM(+event.target.value);
    setPageM(0);
  };
  const handleChangePageFM = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageFM(newPage);
  };
  const handleChangeRowsPerPageFM = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageFM(+event.target.value);
    setPageFM(0);
  };
  const handleChangePageS = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPageS(newPage);
  };
  const handleChangeRowsPerPageS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageS(+event.target.value);
    setPageS(0);
  };
  const handleClickFjern = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFjernMenu = () => {
    setAnchorEl(null);
  };
  const isSelected = (current: MatvareGroupData) => {
    const item = selectedMatvaregroups?.findIndex(
      (n: MatvareGroupData) => n.NODENRID === current.NODENRID
    );
    return item >= 0;
  };
  const handleSelectMatvaregruppe = (
    event: React.MouseEvent<unknown>,
    matvaregruppe: MatvareGroupData
  ) => {
    const selectedIds = selectedMatvaregroups?.map((n: MatvareGroupData) => n.NODENRID);
    const selectedIndex = selectedIds?.indexOf(matvaregruppe?.NODENRID);
    let newSelected: MatvareGroupData[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMatvaregroups, {
        NODENRID: matvaregruppe?.NODENRID,
        NAVN: matvaregruppe?.NAVN,
        KORTNAVN: matvaregruppe?.KORTNAVN
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMatvaregroups?.slice(1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMatvaregroups?.slice(0, selectedIndex),
        selectedMatvaregroups?.slice(selectedIndex + 1)
      );
    }
    setSelectedMatvaregroups(newSelected);
  };
  const handleSelectAllMatvaregruppe = (
    list: MatvareGroupData[],
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = list?.map((n: MatvareGroupData) => n);
      setSelectedMatvaregroups(newSelecteds);
      return;
    }
    setSelectedMatvaregroups([]);
  };
  return (
    <>
      {showSaveBeregningInput && (
        <SaveInputs
          beregningObject={beregningObject}
          showAlertMessage={showAlertMessage}
          setBeregningObject={setBeregningObject}
          setTotalCounts={setTotalCounts}
          setUtvalgData={setUtvalgData}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
        />
      )}
      {showDialogbox ? (
        <DialogBox
          title={title}
          isShowing={showDialogbox}
          hide={handleCloseMatavreGruppe}
        >
          <Grid container spacing={3} padding={2}>
            <Grid container>
              <Grid item xs={5}>
                <Typography>
                  Beregningsutvalg: <b>{beregningObject?.utvalgId}</b>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  ResultatGruppe:{' '}
                  <b>{beregningObject?.matvareData?.resultatGroup || ''}</b>
                </Typography>
              </Grid>
              {isBeregning ? (
                <Grid item xs={2}>
                  <NButton
                    children="Fjern..."
                    leftIcon={<DeleteOutlineIcon />}
                    variant="outlined"
                    onClick={handleClickFjern}
                    disabled={
                      totalCounts?.TOTALMATVARE === 0 &&
                      totalCounts?.TOTALMATVAREGRUPPE === 0 &&
                      totalCounts?.TOTALSTOFFCOUNT === 0
                    }
                  />
                  <Fjern
                    beregningObject={beregningObject}
                    anchorEl={anchorEl}
                    handleCloseFjernMenu={handleCloseFjernMenu}
                    totalCounts={totalCounts}
                    setLoadCount={setLoadCount}
                    selectedMatvaregroups={selectedMatvaregroups}
                    setLoadUtvalgData={setLoadUtvalgData}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid container spacing={1}>
              {isBeregning && (
                <Grid item xs={3}>
                  MatvareGruppe: <b>{utvalgData?.matvaregroupData?.length || 0}</b>
                  <Table sx={classesTable.body}>
                    <TableHead sx={classesTable.head}>
                      <TableRow>
                        {utvalgData?.matvaregroupData?.length > 0 ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              indeterminate={
                                selectedMatvaregroups?.length > 0 &&
                                selectedMatvaregroups?.length <
                                  utvalgData?.matvaregroupData?.length
                              }
                              checked={
                                utvalgData?.matvaregroupData?.length > 0 &&
                                selectedMatvaregroups?.length ===
                                  utvalgData?.matvaregroupData?.length
                              }
                              onChange={(e) => {
                                handleSelectAllMatvaregruppe(
                                  utvalgData?.matvaregroupData,
                                  e
                                );
                              }}
                            />
                          </TableCell>
                        ) : undefined}
                        <TableCell>Kortnavn</TableCell>
                        <TableCell>Navn</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={classesTable.root}>
                      {}
                      {utvalgData?.matvaregroupData
                        ?.slice(
                          pageMG * rowsPerPageMG,
                          pageMG * rowsPerPageMG + rowsPerPageMG
                        )
                        .map((row: MatvareGroupData) => {
                          const highlighted =
                            selectedRow && selectedRow?.NODENRID === row?.NODENRID;
                          const isItemSelected = isSelected(row);
                          return (
                            <TableRow
                              key={row.KORTNAVN}
                              role="checkbox"
                              onClick={() => {
                                setSelectedRow(highlighted ? null : row);
                              }}
                              style={{
                                backgroundColor: highlighted ? '#d2ecfd' : 'white'
                              }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) =>
                                    handleSelectMatvaregruppe(event, row)
                                  }
                                />
                              </TableCell>
                              <TableCell>{row.KORTNAVN}</TableCell>
                              <TableCell>{row.NAVN}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={utvalgData?.matvaregroupData?.length || 0}
                    rowsPerPage={rowsPerPageMG}
                    page={pageMG}
                    onPageChange={handleChangePageMG}
                    onRowsPerPageChange={handleChangeRowsPerPageMG}
                  />
                </Grid>
              )}
              {/* {showTableMatvarer && ( */}
              <Grid item xs={isBeregning ? 3 : 6}>
                Matvarer: <b>{utvalgData?.matvaredata?.length || 0}</b>
                <Table sx={classesTable.body}>
                  <TableHead sx={classesTable.head}>
                    <TableRow>
                      <TableCell>Kode</TableCell>
                      <TableCell>Navn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {}
                    {utvalgData?.matvaredata
                      ?.slice(pageM * rowsPerPageM, pageM * rowsPerPageM + rowsPerPageM)
                      .map((row: Matvare) => (
                        <TableRow key={row.MATVAREKODEID}>
                          <TableCell>{row.MATVAREKODEID}</TableCell>
                          <TableCell>{row.NAVN}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={utvalgData?.matvaredata?.length || 0}
                  rowsPerPage={rowsPerPageM}
                  page={pageM}
                  onPageChange={handleChangePageM}
                  onRowsPerPageChange={handleChangeRowsPerPageM}
                />
              </Grid>
              {/* )} */}

              {isBeregning && (
                <Grid item xs={2}>
                  Fjernede matvarer:<b>{utvalgData?.fjerndeMatvareData?.length || 0}</b>
                  <Table sx={classesTable.body}>
                    <TableHead sx={classesTable.head}>
                      <TableRow>
                        <TableCell>Kode</TableCell>
                        <TableCell>Navn</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {}
                      {utvalgData?.fjerndeMatvareData
                        ?.slice(
                          pageFM * rowsPerPageFM,
                          pageFM * rowsPerPageFM + rowsPerPageFM
                        )
                        .map((row: Matvare) => (
                          <TableRow key={row.MATVAREKODEID}>
                            <TableCell>{row.MATVAREKODEID}</TableCell>
                            <TableCell>{row.NAVN}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={utvalgData?.fjerndeMatvareData?.length || 0}
                    rowsPerPage={rowsPerPageFM}
                    page={pageFM}
                    onPageChange={handleChangePageFM}
                    onRowsPerPageChange={handleChangeRowsPerPageFM}
                  />
                </Grid>
              )}
              {isBeregning && (
                <Grid item xs={2}>
                  Stoffgrupper : <b>{utvalgData?.stoffGroupData?.length || 0}</b>
                  <Table sx={classesTable.body}>
                    <TableHead sx={classesTable.head}>
                      <TableRow>
                        <TableCell>Navn</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {}
                      {utvalgData?.stoffGroupData?.map((row: StoffgruppeI) => (
                        <TableRow key={row.stoffgruppeId}>
                          <TableCell>{row.navn}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}

              <Grid item xs={isBeregning ? 2 : 6}>
                Enkeltstoffer : <b>{utvalgData?.stoffData?.length || 0}</b>
                <Table sx={classesTable.body}>
                  <TableHead sx={classesTable.head}>
                    <TableRow>
                      <TableCell>Nr</TableCell>
                      <TableCell>Navn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {}
                    {utvalgData?.stoffData
                      ?.slice(pageS * rowsPerPageS, pageS * rowsPerPageS + rowsPerPageS)
                      .map((row: StoffI) => {
                        return (
                          <TableRow key={row.stoffnrId}>
                            <TableCell>{row.stoffnrId}</TableCell>
                            <TableCell>{row.navn}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={utvalgData?.stoffData?.length || 0}
                  rowsPerPage={rowsPerPageS}
                  page={pageS}
                  onPageChange={handleChangePageS}
                  onRowsPerPageChange={handleChangeRowsPerPageS}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogBox>
      ) : undefined}
    </>
  );
};
