import { FormControl } from '@mui/base';
import {
  Box,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AlertI } from '../../../../../types/alertTypes';
import { ApiCall } from '../../../../utilities/ApiCall';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import useFetchData from '../../../../utilities/useFetchData';
import { Dropdown, NButton } from '../../../components/Inputs';
import { PersonI } from '../../types/PersonTypes';
import { CountResult, ProsjektI, UndersokelseI } from '../../types/ProjectTypes';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface KopiModalProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  showing: boolean;
  hide: () => void;
  projectObject: ProsjektI;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
}

export default function PersonKopiModal(props: KopiModalProps) {
  const {
    showing,
    hide,
    projectObject,
    personData,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity
  } = props;
  const { prosjektId, undersokelseId, undersokelseKortnavn } = props.match.params;
  const [undersokelserData, setUndersokelserData] = useState<UndersokelseI[]>([]);
  const [selectedUndersokelse, setSelectedUndersokelse] = useState<
    UndersokelseI['undersokelseId'] | null
  >(null);
  const [selectedPersonId, setSelectedPersonId] = useState<PersonI['personId'] | null>(
    null
  );
  const [relatedInfo, setRelatedInfo] = useState<number | null>(null);
  const [personExists, setPersonExists] = useState<boolean>(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!prosjektId) return;
    ApiCall<UndersokelseI[]>(
      `hentPUndersokelser/${prosjektId}`,
      'GET',
      keycloak.token,
      setUndersokelserData
    );
  }, [prosjektId]);

  // GET DOES PERSON EXIST
  const checkIfPersonIdExists = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'checkIfPersonIdExists',
    subProjectURL: `${prosjektId}/${selectedUndersokelse}/${selectedPersonId}`
  });

  useEffect(() => {
    if (selectedUndersokelse && selectedPersonId) {
      checkIfPersonIdExists.loadData(
        'checkIfPersonIdExists',
        `${prosjektId}/${selectedUndersokelse}/${selectedPersonId}`
      );
    }
  }, [selectedUndersokelse, selectedPersonId]);

  useEffect(() => {
    if (checkIfPersonIdExists.data) {
      const data = checkIfPersonIdExists.data as unknown as CountResult;
      if (data?.COUNT > 0) {
        setPersonExists(true);
      } else {
        setPersonExists(false);
      }
    }
  }, [checkIfPersonIdExists.data]);

  // KOPIERE PERSON
  const handleCopy = async () => {
    const backendURI = process.env.REACT_APP_BACKEND;
    let url = `${backendURI}/copyPerson/${prosjektId}/${undersokelseId}/${personData?.personId}/${selectedUndersokelse}/${selectedPersonId}/${relatedInfo}`;
    let response: Response = await fetch(encodeURI(url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + keycloak.token
      }
    });
    if (response.ok) {
      setShowAlert(true);
      setAlertMessage('Person kopiert');
      setAlertSeverity('success');
      console.log('success', response.status);
      hide();
    } else {
      console.log('error', response.status);
      setShowAlert(true);
      setAlertMessage('Kopiering av person feilet');
      setAlertSeverity('error');
    }
  };

  return (
    <DialogGeneral
      title="Kopi av person"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="sm"
    >
      <Stack spacing={2}>
        <Stack spacing={1} paddingBottom={2}>
          <Typography variant="body1">
            <b>Prosjekt: </b> {projectObject?.prosjektnavn} - {prosjektId}
          </Typography>
          <Typography variant="body1">
            <b>Undersøkelse: </b> {undersokelseKortnavn}
          </Typography>
          <Typography variant="body1">
            <b>Person: </b>
            {personData?.personId} - {personData?.identitet}
          </Typography>
        </Stack>
        <Box>
          <Typography variant="h6">Kopieringsalternativ</Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="velg-relatedInfo"
              value={relatedInfo}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRelatedInfo(+event.target.value)
              }
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Kopi av personen med tilleggssvar"
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Kopi av personen med all underliggende informasjon"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Stack direction={'row'} spacing={1} justifyContent={'end'} alignItems={'end'}>
          <Dropdown
            header="Undersøkelse*"
            value={selectedUndersokelse}
            onChange={(value: any) => setSelectedUndersokelse(value)}
            options={undersokelserData?.map((u) => u.undersokelseId)}
            optionLabel={(value) =>
              undersokelserData?.find((u) => u.undersokelseId === value)?.navn
            }
          />
          <Box sx={{ width: '100%' }}>
            <FormControl>
              <Typography sx={{ whiteSpace: 'nowrap' }}>PersonID</Typography>
              <OutlinedInput
                value={selectedPersonId || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSelectedPersonId(+event.target.value)
                }
                fullWidth
                size="small"
                type="number"
              />
            </FormControl>
          </Box>
          <Box>
            <NButton
              children="Kopier"
              disabled={
                !selectedUndersokelse ||
                !selectedPersonId ||
                selectedPersonId < 1 ||
                relatedInfo === null ||
                personExists
              }
              onClick={handleCopy}
            />
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'end'}>
          {personExists && selectedPersonId ? (
            <Box height={5}>
              <Typography variant="caption" color="error">
                PersonID finnes allerede
              </Typography>
            </Box>
          ) : (
            <Box height={5} />
          )}
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
