import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableContainer,
  Box,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import { DialogBox } from '../../utilities/DialogBox';
import { CustomTab, TabPanel } from '../../utilities/TabPanel';
import { MonoSearchField, NButton } from '../components/Inputs';
import useStylesTable from '../../../css/TableCss';
import { UtvalgTableData } from '../beregning/types/BeregningTypes';
import { SingleStoffTypes, StoffI, StoffType, StoffgruppeI } from './types/StoffTypes';
import useFetchData from '../../utilities/useFetchData';
interface StoffTableProps {
  databaseId: number;
  showStoffOption: boolean;
  setShowStoffOption: (v: boolean) => void;
  toggleStoffnummer: any;
  stofferList?: StoffI[];
  setStofferList?: (list: StoffI[]) => void;
  selectedStoffs?: StoffI[];
  setSelectedStoffs?: (stoff: StoffI[]) => void;
  stoffgrupperList?: StoffgruppeI[];
  setStoffgrupperList?: (list: StoffgruppeI[]) => void;
  selectedStoffgruppe?: StoffgruppeI;
  setSelectedStoffgruppe?: (stoffgruppe: StoffgruppeI) => void;
  stoffType: StoffType;
  utvalgData?: UtvalgTableData;
  history?: any;
  singleStoffType?: SingleStoffTypes;
}

export const StoffSearch = (props: StoffTableProps) => {
  const {
    databaseId,
    setShowStoffOption,
    showStoffOption,
    toggleStoffnummer,
    stofferList,
    setStofferList,
    selectedStoffs,
    setSelectedStoffs,
    stoffgrupperList,
    setStoffgrupperList,
    selectedStoffgruppe,
    setSelectedStoffgruppe,
    stoffType,
    utvalgData,
    singleStoffType
  } = props;
  const classesTable = useStylesTable();
  const [selectedRow, setSelectedRow] = useState<StoffI | StoffgruppeI>();
  const [stoffer, setStoffer] = useState<StoffI[]>([]);
  const [stoffgrupper, setStoffgrupper] = useState<StoffgruppeI[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [singleStoffs, setSingleStoffs] = useState(false);
  const [radioButtonSelectGrupper, setRadioButtonSelectGrupper] = React.useState(0);
  const [radioButtonSelectStoffer, setRadioButtonSelectStoffer] = React.useState(0);

  const [utvalgDataCopy, setUtvalgDataCopy] = useState<UtvalgTableData>(utvalgData);
  const getStoffs = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffDetail',
    subProjectURL: null
  });
  const getStoffgroups = useFetchData({
    loadOnMount: false,
    clearDataOnLoad: false,
    projectURL: 'getStoffGroups',
    subProjectURL: null
  });
  useEffect(() => {
    if (getStoffs.data && stoffType !== StoffType.group) {
      const data: StoffI[] = (getStoffs.data as unknown as StoffI[]) || [];
      setStofferList(data);
      setStoffer(data);
    }
  }, [getStoffs.data]);
  useEffect(() => {
    if (getStoffgroups.data && stoffType !== StoffType.single) {
      const data: StoffgruppeI[] =
        (getStoffgroups.data as unknown as StoffgruppeI[]) || [];
      setStoffgrupperList(data);
      setStoffgrupper(data);
    }
  }, [getStoffgroups.data]);

  useEffect(() => {
    if (stoffType === StoffType.single) {
      getStoffs.loadData(`getAllStoffs`, `${databaseId}/0`);
    } else if (stoffType === StoffType.group) {
      getStoffgroups.loadData(`getStoffGroups`, `${databaseId}/all`);
    } else {
      getStoffgroups.loadData(`getStoffGroups`, `${databaseId}/all`);
      getStoffs.loadData(`getAllStoffs`, `${databaseId}/0`);
    }
  }, []);

  const searchStoffById = (id?: string): void => {
    const searchResults = id
      ? stofferList?.filter((stoff) => String(stoff.stoffnrId)?.includes(id as string))
      : stofferList;
    searchResults?.length === 0 ? setStoffer([]) : setStoffer(searchResults);
  };
  const searchStoffByName = (name: string): void => {
    const lowerCaseName = name?.toLowerCase();
    const searchResults = lowerCaseName
      ? stofferList?.filter((stoff) =>
          String(stoff.kortnavn)?.toLowerCase()?.includes(lowerCaseName)
        )
      : stofferList;
    searchResults?.length === 0 ? setStoffer([]) : setStoffer(searchResults);
  };
  const searchStoffgroupByName = (name: string): void => {
    const lowerCaseName = name?.toLowerCase();
    const searchResults = lowerCaseName
      ? stoffgrupperList?.filter((stoffgruppe) =>
          String(stoffgruppe.navn)?.toLowerCase()?.includes(lowerCaseName)
        )
      : stoffgrupperList;
    searchResults?.length === 0 ? setStoffgrupper([]) : setStoffgrupper(searchResults);
  };
  const handleSelectStoff = (stoff: StoffI) => {
    if (stoffType === StoffType.both || stoffType === StoffType.group) {
      setSelectedStoffgruppe({ stoffgruppeId: 0, navn: '', beskrivelse: '' });
    }
    const index = selectedStoffs?.findIndex(
      (n: StoffI) => n.stoffnrId === stoff?.stoffnrId
    );
    if (index > -1) {
      stoff.selected = false;
      selectedStoffs?.splice(index, 1);
      const indexUD = utvalgDataCopy?.stoffData?.findIndex(
        (u) => u.stoffnrId === stoff.stoffnrId
      );
      utvalgDataCopy?.stoffData.splice(indexUD, 1);
    } else {
      stoff.selected = true;
      selectedStoffs?.push(stoff);
    }
  };

  useEffect(() => {
    console.log('utvalgDataCopy', utvalgDataCopy);
  }, [utvalgDataCopy]);

  const handleSelectAllStoff = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (stoffType === StoffType.both) {
        setSelectedStoffs(
          Array.from(stoffer, (n) => {
            n.selected = true;
            return n;
          })
        );
        setSelectedStoffgruppe({ stoffgruppeId: 0, navn: '', beskrivelse: '' });
      }
    } else {
      if (stoffType === StoffType.both) {
        stoffer?.map((n: StoffI) => (n.selected = false));
        setSelectedStoffs([]);
      }
    }
  };
  const isSelected = (row: StoffI) => {
    let selected = false;
    const ids: number[] = utvalgDataCopy?.stoffData?.map((u) => u.stoffnrId);
    const found = ids?.includes(row.stoffnrId);
    if (row.selected || found) {
      selected = true;
    }
    return selected;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    newValue === 0 ? setSingleStoffs(false) : setSingleStoffs(true);
  };

  const clearSelectedStoffs = () => {
    stoffer?.map((n: StoffI) => (n.selected = false));
    setSelectedStoffs([]);
    setRadioButtonSelectGrupper(0);
    if (stoffType === StoffType.both) {
      setSelectedStoffgruppe({ stoffgruppeId: 0, navn: '', beskrivelse: '' });
    }
    setUtvalgDataCopy({ ...utvalgDataCopy, stoffData: [], stoffGroupData: [] });
  };
  const handleStoffgruppe = (row: StoffgruppeI) => {
    if (stoffType === StoffType.both) {
      setSelectedStoffs([]);
      setUtvalgDataCopy({ ...utvalgDataCopy, stoffData: [] });
      setRadioButtonSelectGrupper(row.stoffgruppeId);
      setSelectedStoffgruppe(row);
    }
    if (stoffType === StoffType.group) {
      setRadioButtonSelectGrupper(row.stoffgruppeId);
      setSelectedStoffgruppe(row);
      props.history?.push(`/home/matvaredata/stoffgruppe/${row.stoffgruppeId}`);
      setShowStoffOption(!showStoffOption);
    }
  };
  const handleStoffs = (row: StoffI) => {
    if (stoffType === StoffType.both) {
      setSelectedStoffgruppe({
        navn: '',
        stoffgruppeId: 0
      });
      setUtvalgDataCopy({ ...utvalgDataCopy, stoffGroupData: [] });
      setRadioButtonSelectStoffer(row.stoffnrId);
      setSelectedStoffs([row]);
    }
    if (stoffType === StoffType.single) {
      setRadioButtonSelectStoffer(row.stoffnrId);
      setSelectedStoffs([row]);
      if (singleStoffType === SingleStoffTypes.stoffDef) {
        props.history?.push(`/home/matvaredata/stoff/${row.stoffnrId}`);
      } else {
        toggleStoffnummer(row);
      }
      setShowStoffOption(!showStoffOption);
    }
  };
  return (
    <DialogBox
      title={
        stoffType === StoffType.single
          ? 'Liste over enkeltstoff'
          : tabValue === 0
          ? 'Liste over stoffgruppe'
          : 'Liste over enkeltstoff'
      }
      isShowing={showStoffOption}
      hide={toggleStoffnummer}
    >
      <CustomTab
        tabValue={tabValue}
        handleChange={handleChange}
        label1={
          stoffType == StoffType.group || stoffType === StoffType.both
            ? 'Stoffgrupper'
            : 'Enkeltstoffer'
        }
        label2={
          stoffType === StoffType.group || stoffType === StoffType.both
            ? 'Enkeltstoffer'
            : 'Stoffgrupper'
        }
        disabled={stoffType === StoffType.single || stoffType === StoffType.group}
      />
      <TabPanel
        value={tabValue}
        index={stoffType === StoffType.group || stoffType === StoffType.both ? 1 : 0}
      >
        <Box style={{ width: 1000, height: '55vh' }}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={3} container alignItems="flex-end">
              <MonoSearchField
                header="Søk etter stoffnummer"
                searchFn={(n) => searchStoffById(n)}
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <MonoSearchField
                header="Søk etter kortnavn"
                searchFn={(n) => searchStoffByName(n)}
              />
            </Grid>
            <Grid item xs={6} container alignItems="flex-end" justifyContent={'flex-end'}>
              <Typography>
                {'Antall stoffer: '}
                {stoffer?.length}
              </Typography>
            </Grid>
          </Grid>
          <TableContainer>
            <Table sx={classesTable.body}>
              <TableHead sx={classesTable.head}>
                <TableRow>
                  <TableCell padding="checkbox">
                    {stoffType !== StoffType.single ? (
                      <Checkbox
                        indeterminate={
                          selectedStoffs?.length > 0 &&
                          selectedStoffs?.length < stoffer?.length
                        }
                        checked={
                          stoffer?.length > 0 &&
                          selectedStoffs?.length === stoffer?.length
                        }
                        onChange={handleSelectAllStoff}
                        // disabled={utvalgDataCopy?.stoffData?.length !== 0}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>Sortering</TableCell>
                  <TableCell>Stoffnr</TableCell>
                  <TableCell>Kortnavn</TableCell>
                  <TableCell>Navn</TableCell>
                  <TableCell>Regnbart</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stoffer?.map((row: StoffI) => {
                  const highlighted =
                    selectedRow && (selectedRow as StoffI).stoffnrId === row.stoffnrId;
                  //const isItemSelected = isSelected(row);
                  return (
                    <TableRow
                      key={row.stoffnrId}
                      hover
                      onClick={() => {
                        setSelectedRow(highlighted ? undefined : row);
                      }}
                      style={{
                        backgroundColor: highlighted ? '#d2ecfd' : 'white'
                      }}
                      sx={classesTable.tableRow}
                    >
                      <TableCell padding="checkbox">
                        {stoffType !== StoffType.single ? (
                          <Checkbox
                            checked={isSelected(row)}
                            onClick={() => handleSelectStoff(row)}
                            //disabled={utvalgDataCopy?.stoffData?.length !== 0}
                          />
                        ) : (
                          <FormControl component="fieldset">
                            <RadioGroup
                              key={row.stoffnrId}
                              value={radioButtonSelectStoffer}
                              onChange={() => {
                                handleStoffs(row);
                              }}
                            >
                              <FormControlLabel
                                value={row.stoffnrId}
                                control={<Radio size="small" />}
                                label=""
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.sortering}</TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.stoffnrId}</TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.kortnavn}</TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.navn}</TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.regnbart}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {stoffer?.length > 0 && (
            <ButtonBox
              showStoffOption={showStoffOption}
              setShowStoffOption={setShowStoffOption}
              setShowStoffTab={toggleStoffnummer}
              clearStoff={clearSelectedStoffs}
              disabled={
                selectedStoffs?.length === 0 && utvalgDataCopy?.stoffData?.length === 0
              }
            />
          )}
        </Box>{' '}
      </TabPanel>
      <TabPanel
        value={tabValue}
        index={stoffType === StoffType.group || stoffType === StoffType.both ? 0 : 1}
      >
        <Box style={{ width: 900, height: '50vh' }}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6} container alignItems="flex-end">
              <MonoSearchField
                header="Søk etter navn"
                searchFn={(n) => searchStoffgroupByName(n)}
              />
            </Grid>
            <Grid item xs={6} container alignItems="flex-end" justifyContent={'flex-end'}>
              <Typography>
                {'Antall stoffgrupper: '}
                {stoffgrupper?.length}
              </Typography>
            </Grid>
          </Grid>
          <TableContainer>
            <Table sx={classesTable.body}>
              <TableHead sx={classesTable.head}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Navn</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stoffgrupper?.map((row: StoffgruppeI) => {
                  const highlighted =
                    selectedRow &&
                    (selectedRow as StoffgruppeI).stoffgruppeId === row.stoffgruppeId;
                  //const isItemSelected = isSelected(row);
                  return (
                    <TableRow
                      hover
                      key={row.stoffgruppeId}
                      style={{
                        backgroundColor: highlighted ? '#d2ecfd' : 'white'
                      }}
                    >
                      <TableCell sx={classesTable.tableRow}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            key={row.stoffgruppeId}
                            value={
                              radioButtonSelectGrupper ||
                              (utvalgDataCopy?.stoffGroupData[0]?.stoffgruppeId
                                ? utvalgDataCopy?.stoffGroupData[0]?.stoffgruppeId
                                : radioButtonSelectGrupper)
                            }
                            onChange={() => {
                              handleStoffgruppe(row);
                            }}
                          >
                            <FormControlLabel
                              value={row.stoffgruppeId}
                              control={<Radio size="small" />}
                              label=""
                              //disabled={utvalgDataCopy?.stoffGroupData?.length !== 0}
                            />
                          </RadioGroup>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.navn}</TableCell>
                      <TableCell sx={classesTable.tableRow}>{row.beskrivelse}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {stoffgrupper?.length > 0 && stoffType === StoffType.both && (
            <ButtonBox
              showStoffOption={showStoffOption}
              setShowStoffOption={setShowStoffOption}
              setShowStoffTab={toggleStoffnummer}
              clearStoff={clearSelectedStoffs}
              disabled={
                selectedStoffgruppe?.stoffgruppeId === 0 &&
                utvalgDataCopy?.stoffGroupData?.length === 0
              }
            />
          )}
        </Box>
      </TabPanel>
    </DialogBox>
  );
};
interface ButtonBoxprops {
  showStoffOption: boolean;
  setShowStoffTab: (stoff?: StoffI) => void;
  setShowStoffOption: (value: boolean) => void;
  clearStoff: () => void;
  disabled: boolean;
}
function ButtonBox(props: ButtonBoxprops) {
  return (
    <Grid container padding={4} spacing={1}>
      <Grid container item xs={6} justifyContent="flex-start" alignItems="flex-end">
        <NButton
          children="Ok"
          onClick={() => {
            props.setShowStoffTab();
            props.setShowStoffOption(!props.showStoffOption);
          }}
        />
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end" alignItems="flex-end">
        <NButton
          children="Nullstill valgte stoffer og grupper"
          variant="outlined"
          onClick={props.clearStoff}
          //disabled={props.disabled}
        />
      </Grid>
    </Grid>
  );
}
