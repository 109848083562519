import {
  Checkbox,
  Chip,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useKeycloak } from '@react-keycloak/web';
import React, {
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
  ChangeEvent,
  useMemo
} from 'react';
import { AlertI } from '../../../../../types/alertTypes';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { EnhancedTableHead } from '../../../../utilities/EnhancedTableHead';
import { HeadCell } from '../../../components/commons/CommonTypes';
import { MonoSearchField, NButton } from '../../../components/Inputs';
import { TilleggsvariableI } from '../../types/TilleggsvariableTypes';

const headCells: HeadCell<TilleggsvariableI>[] = [
  {
    id: 'variabelId',
    align: 'left',
    padding: 'normal',
    label: 'ID'
  },
  {
    id: 'kortnavn',
    align: 'left',
    padding: 'normal',
    label: 'Kortnavn'
  },
  {
    id: 'sporsmal',
    align: 'left',
    padding: 'normal',
    label: 'Spørsmål'
  }
];

interface TilleggsvariablerSearchProps {
  showing: boolean;
  hide: () => void;
  variablerData: TilleggsvariableI[];
  setVariablerData: Dispatch<SetStateAction<TilleggsvariableI[]>>;
  allVariabler: TilleggsvariableI[];
  setAllVariabler: Dispatch<SetStateAction<TilleggsvariableI[]>>;
  allVariablerOriginal: TilleggsvariableI[];
  addMultiple: boolean;
  setDataChanged: Dispatch<SetStateAction<boolean>>;
  newRowKortnavn: string;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
}

export default function TilleggsvariablerSearchModal(
  props: TilleggsvariablerSearchProps
) {
  const {
    showing,
    hide,
    variablerData,
    setVariablerData,
    allVariabler,
    setAllVariabler,
    allVariablerOriginal,
    addMultiple,
    setDataChanged,
    newRowKortnavn,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity
  } = props;
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { keycloak } = useKeycloak();

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    let newSelected: readonly number[] = [];
    if (!selected.includes(id)) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
  };

  const searchWithName = (name?: string) => {
    if (name) {
      const newData = allVariablerOriginal?.filter((i) =>
        i.kortnavn?.toLowerCase().includes(name?.toLowerCase())
      );
      setAllVariabler(newData);
    } else {
      setAllVariabler(allVariablerOriginal);
    }
  };

  const handleOk = () => {
    const idExists = (id: number) => variablerData?.find((v) => v.variabelId === id);
    const nextSortId =
      variablerData?.length > 0
        ? Math.max(...variablerData?.map((v) => v.sortering)) + 1
        : 1;
    if (addMultiple) {
      const newRows = allVariabler
        ?.filter((i) => selected.includes(i.variabelId))
        .filter((j) => !idExists(j.variabelId))
        .map((l, index) => ({
          sortering: nextSortId + index,
          variabelId: l.variabelId,
          kortnavn: l.kortnavn,
          sporsmal: l.sporsmal,
          svartype: l.svartype
        }));

      const newData = [...variablerData, ...newRows];
      setVariablerData(newData);
    } else {
      const newRow = allVariabler?.find((i) => i.variabelId === selectedRow);
      if (idExists(newRow.variabelId)) {
        setShowAlert(true);
        setAlertMessage('Variabel er allerede lagt til');
        setAlertSeverity('error');
        return;
      }
      setVariablerData([
        ...variablerData,
        {
          ...newRow,
          sortering: nextSortId
        }
      ]);
    }
    setDataChanged(true);
    hide();
  };

  const rows = useMemo(
    () => allVariabler?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [allVariabler, page, rowsPerPage]
  );

  return (
    <DialogGeneral
      title="Liste over tilleggsvariabler"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="md"
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <Box minWidth={'fit-content'}>
            <MonoSearchField
              header="Søk etter kortnavn"
              preValue={newRowKortnavn}
              searchFn={(n) => searchWithName(n)}
            />
          </Box>
          {addMultiple && selected?.length > 0 && (
            <Stack sx={{ maxHeight: '15rem', overflowY: 'auto' }}>
              <Typography>Valgte tilleggsvariabler</Typography>
              <Stack
                direction={'row'}
                flexWrap={'wrap'}
                alignItems="center"
                columnGap={1}
                rowGap={1}
                height={'100%'}
              >
                {selected?.map((item, index) => (
                  <Chip
                    key={item}
                    label={allVariabler?.find((v) => v.variabelId === item)?.kortnavn}
                    onDelete={() => setSelected((prev) => prev.filter((p) => p !== item))}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>

        <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
          <Table size="small">
            <EnhancedTableHead<TilleggsvariableI>
              headCells={headCells}
              leftColumns={[{ name: 'Velg' }]}
            />
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={row.variabelId}>
                  {addMultiple ? (
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClick(event, row.variabelId)}
                    >
                      <Checkbox
                        color="primary"
                        checked={selected?.includes(row.variabelId)}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      padding="checkbox"
                      onClick={() => setSelectedRow(row.variabelId)}
                    >
                      <Radio
                        checked={selectedRow === row.variabelId}
                        inputProps={{
                          'aria-labelledby': 'table-checkbox-' + index
                        }}
                      />
                    </TableCell>
                  )}
                  {headCells.map((cell) => (
                    <TableCell
                      key={cell.id}
                      align={cell.align}
                      padding={cell.padding}
                      onClick={
                        addMultiple
                          ? (event) => handleClick(event, row.variabelId)
                          : () => setSelectedRow(row.variabelId)
                      }
                    >
                      {row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {rows && (
          <TablePagination
            component="div"
            count={allVariabler?.length - 1}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Stack
          spacing={1}
          direction="row"
          alignItems="end"
          justifyContent="space-between"
        >
          <NButton children="OK" onClick={handleOk} />
          <NButton
            children="Avbryt"
            onClick={() => hide()}
            disabled={selected.length === 0 && selectedRow === 0}
          />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
