import React from 'react';
import { Divider, Box, Grid, Typography, Stack } from '@mui/material';
import useStyles from '../../../css/materialtheme';
import KodesKortNavn from '../matvare/components/KodesKortNavn';
import EnglishNameOgBeskrivelse from '../matvare/components/EnglishNameOgBeskrivelse';
import SupplamentaryData from '../matvare/components/SupplamentaryData';
import IconsMenuMatvare from '../matvare/components/IconsMenuMatvare';
import { useHistory } from 'react-router';
import { MatvareData, MatvareResult } from './types/MatvareTypes';
import { InputFields } from '../components/InputFields';
import { SaveBeforeRedirect } from '../../utilities/SaveBeforeRedirect';
import postData from '../../utilities/postData';
import { NButton } from '../components/Inputs';
import { hasDataChanged } from '../../utilities/ArrayFunctions';
interface MatvareProps {
  params: any;
  currentDatabase: any;
  token: string;
  owned: boolean;
  matvareData: MatvareData;
  originalMatvareCopy: MatvareData;
  setOwned: (value: boolean) => void;
  handleMatvareForm: (name: string, value: string | number) => void;
  handleOnEnterKeyPress: (e: React.KeyboardEvent<HTMLDivElement>, name: string) => void;
  loadMatvare: (id: number) => void;
  clearMatvareData: () => void;
  refreshUnmodified: () => void;
  showAlertComponent: (message: string, severity: any) => void;
  showProgress: boolean;
  setShowProgress: (value: boolean) => void;
  matvarekodeError: {
    error: boolean;
    message: string;
  };
  title: string;
}
export default function Matvare(props: MatvareProps) {
  const {
    params,
    currentDatabase,
    token,
    matvareData,
    originalMatvareCopy,
    handleMatvareForm,
    handleOnEnterKeyPress,
    loadMatvare,
    clearMatvareData,
    owned,
    showProgress,
    setShowProgress,
    showAlertComponent,
    matvarekodeError,
    title
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const kodeFromURL = params.kode; // This is MatvareKodeId that is in URL
  const [enterPressed, setEnterPressed] = React.useState(false);
  document.title = title;

  const saveMatvare = (data: MatvareData) => {
    /*
        kodeFromURL controls if we are in a update or create phase.
        We take care of the selection higher up in the component tree.
       */
    let url = `saveAndUpdateMatvare`;
    let dataUrl = `${currentDatabase?.value}`;
    postData({ data: data }, url, dataUrl, 'POST', token)
      .then(() => {
        const message = kodeFromURL
          ? `Oppdateringen er fullført`
          : `Lagringen er fullført`;
        console.log(message, 'success');
        showAlertComponent(message, 'success');
        navigateAway();
      })
      .catch((err) => {
        console.log('We are in the catch', err);
        const message = kodeFromURL ? `Oppdateringen mislyktes` : `Lagringen mislyktes`;
        showAlertComponent(
          `${message}. Det har ikke blitt gjort endringer mot databasen.`,
          'error'
        );
        navigateAway();
      });
  };

  React.useEffect(() => {
    if (enterPressed) {
      if (matvareData.MATVAREKODEID === 0 || matvareData.MATVAREKODEID === '') {
        return;
      }
      loadMatvare(matvareData.MATVAREKODEID as number);
      setEnterPressed(false);
    }
  }, [enterPressed]);
  const navigateAway = () => {
    // set a timer for the history.push because it will trigger a page refresh
    // and set showAlert to false, removing the Alert
    const timer = setTimeout(() => {
      history.push(`/home/matvaredata/matvare/${matvareData.MATVAREKODEID}`);
    }, 1000);
    return () => clearTimeout(timer);
  };
  const navigateToMatvare = (matvadeKode: number) => {
    // set a timer for the history.push because it will trigger a page refresh
    // and set showAlert to false, removing the Alert
    const timer = setTimeout(() => {
      history.push(`/home/matvaredata/matvare/${matvadeKode}`);
    }, 1000);
    return () => clearTimeout(timer);
  };
  /* 
    Here we re-create our object before the final POST/PUT so we can make sure
    we have the correct types: number or string and remove null
   */
  const matvareDataForm: MatvareData = {
    MATVAREKODEID: matvareData.MATVAREKODEID || 0,
    NAVN: matvareData.NAVN || '',
    KORTNAVN: `V${matvareData.MATVAREKODEID}` || '',
    NAVN_ENGELSK: matvareData.NAVN_ENGELSK || '',
    SENR: matvareData.SENR || 0,
    OFFISIELT_NAVN: matvareData.OFFISIELT_NAVN || '',
    SOKEORD: matvareData.SOKEORD || '',
    MAKSIMALMENGDE: matvareData.MAKSIMALMENGDE || 0,
    VEKTENDRING: matvareData.VEKTENDRING || 0,
    OPPSKRIFT: matvareData.OPPSKRIFT || '0',
    SPISELIG: matvareData.SPISELIG || 0,
    BRUTTOBEREGNING: matvareData.BRUTTOBEREGNING || 0,
    UTBYTTING_SPERRET: matvareData.UTBYTTING_SPERRET || 0,
    BESKRIVELSE: matvareData.BESKRIVELSE || '',
    BESKRIVELSE_ENGELSK: matvareData.BESKRIVELSE_ENGELSK || '',
    REFERANSE: matvareData.REFERANSE || '',
    KOMMENTAR: matvareData.KOMMENTAR || '',
    STATUS: 0
  };

  const submitData = () => {
    if (
      !matvareData?.MATVAREKODEID ||
      !matvareData?.NAVN ||
      !matvareData?.NAVN_ENGELSK ||
      !matvareData?.SOKEORD ||
      !matvareData?.BESKRIVELSE ||
      !matvareData?.BESKRIVELSE_ENGELSK ||
      !matvareData?.REFERANSE
    ) {
      showAlertComponent('Obligatoriske felter kan ikke være tomme.', 'error');
    } else {
      if (hasDataChanged(matvareData, originalMatvareCopy)) {
        console.log('Data has changed!');
        saveMatvare(matvareDataForm);
        props.refreshUnmodified();
        const timer = setTimeout(() => {
          loadMatvare(matvareData.MATVAREKODEID as number);
        }, 500);
        return () => clearTimeout(timer);
      } else {
        showAlertComponent(
          `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`,
          'info'
        );
      }
    }
  };
  return (
    <Box id="Matvare">
      <Grid item xs={6}>
        <Typography variant="h4">Matvare</Typography>
      </Grid>
      <Grid container item xs={12} justifyContent={'flex-end'}>
        <IconsMenuMatvare
          classes={classes}
          owned={owned}
          matvareData={matvareData}
          currentDatabase={currentDatabase}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          showAlertComponent={showAlertComponent}
          navigateToMatvare={navigateToMatvare}
          //matvareData={matvareData}
          token={token}
        />
      </Grid>
      <form onSubmit={submitData} id="submitDataForm">
        <KodesKortNavn
          handleOnEnterKeyPress={handleOnEnterKeyPress}
          matvareData={matvareData as MatvareResult}
          classes={classes}
          handleMatvareForm={handleMatvareForm}
          setShowProgress={setShowProgress}
          setEnterPressed={setEnterPressed}
          showProgress={false}
          matvarekodeError={matvarekodeError}
          //owned={owned}
        />
        <Divider style={{ margin: 15 }} />

        <EnglishNameOgBeskrivelse
          matvareData={matvareData}
          classes={classes}
          handleMatvareForm={handleMatvareForm}
          owned={owned}
        />

        <Divider style={{ margin: 15 }} />

        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <InputFields
                label={'Referanse*'}
                classes={classes}
                id={'referanse'}
                name={'REFERANSE'}
                value={matvareData?.REFERANSE || ''}
                type={'text'}
                required={true}
                handleChange={handleMatvareForm}
                owned={owned}
                //disabled={!owned}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                label={'Kommentar'}
                classes={classes}
                id={'kommentar'}
                name={'KOMMENTAR'}
                value={matvareData?.KOMMENTAR || ''}
                type={'text'}
                handleChange={handleMatvareForm}
                maxLength={true}
                multiline={true}
                Min={1}
                MaxLength={40}
                rows={'3'}
                rowsMax={'5'}
                owned={owned}
                //disabled={!owned}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: 15 }} />
        <SupplamentaryData
          matvareData={matvareData}
          classes={classes}
          handleMatvareForm={handleMatvareForm}
          owned={owned}
        />
        <Stack direction={'row'} spacing={2} style={{ paddingTop: 20 }}>
          <NButton
            children={kodeFromURL ? 'Lagre' : 'Opprett'}
            color={owned ? 'primary' : undefined}
            onClick={submitData}
            disabled={!(matvareData.MATVAREKODEID && owned)}
          />
          <NButton children="Nullstill" variant="outlined" onClick={clearMatvareData} />
        </Stack>
      </form>
    </Box>
  );
}
