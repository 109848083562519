import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { OppringingI, PersonI } from './types/PersonTypes';
import AddIcon from '@mui/icons-material/Add';
import { FormInput, NButton } from '../components/Inputs';
import PersonLopeDagerTable from './PersonLopedagerTable';
import { RouteComponentProps } from 'react-router-dom';
import { useUserContext } from '../../../context/AuthContext';
import IsOwnedByUser from '../../utilities/Validate';

interface RouteParams {
  prosjektId: string;
}

interface PersonLopedagerProps extends RouteComponentProps<RouteParams> {
  personData: PersonI;
  handleChange: (
    field: Extract<keyof PersonI, string | number>,
    value: string | number
  ) => void;
  handleNewLopedag: () => void;
  setShowOppringing: Dispatch<SetStateAction<boolean>>;
  setCurrentModalLopedag: Dispatch<SetStateAction<number>>;
  setCurrentOppringing: Dispatch<SetStateAction<number>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
  setRingOpp: Dispatch<SetStateAction<boolean>>;
  oppringingIds: Set<number>;
  handleSaveOppringing: (oppringing: OppringingI, lopedagId: number) => void;
}

export default function PersonLopedager(props: PersonLopedagerProps) {
  const {
    personData,
    handleChange,
    handleNewLopedag,
    setShowOppringing,
    setCurrentModalLopedag,
    setCurrentOppringing,
    setActiveTab,
    setRingOpp,
    oppringingIds,
    handleSaveOppringing
  } = props;
  const { prosjektId } = props.match.params;
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );

  return (
    <Stack sx={{ mt: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        Løpedager:
      </Typography>
      <Box marginBottom={0.5}>
        <PersonLopeDagerTable
          personData={personData}
          setShowOppringing={setShowOppringing}
          setCurrentModalLopedag={setCurrentModalLopedag}
          setCurrentOppringing={setCurrentOppringing}
          setActiveTab={setActiveTab}
          setRingOpp={setRingOpp}
          oppringingIds={oppringingIds}
          handleSaveOppringing={handleSaveOppringing}
          {...props}
        />
      </Box>

      <Stack direction={'row'} alignItems={'end'} spacing={4}>
        <Tooltip
          title={
            !owned
              ? 'Mangler rettigheter'
              : !personData?.personId
              ? 'PersonID mangler'
              : ''
          }
        >
          <span>
            <NButton
              sx={{ minWidth: 'max-content' }}
              children="Ny løpedag"
              leftIcon={<AddIcon />}
              variant="outlined"
              onClick={handleNewLopedag}
              disabled={!owned || !personData?.personId}
            />
          </span>
        </Tooltip>

        <Grid container spacing={1} marginTop={1} alignItems={'end'}>
          <Grid item xs={6} md={3}>
            <FormInput
              header="Skjema sendt"
              onChange={(value: any) => handleChange('skjemaSendt', value?.nr)}
              value={personData?.skjemaSendt || ''}
              placeholder="[DD.MM.YYYY]"
              inputProps={{
                style: {
                  textAlign: 'right'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormInput
              header="Purring sendt"
              onChange={(value: any) => handleChange('purringSendt', value?.nr)}
              value={personData?.purringSendt || ''}
              placeholder="[DD.MM.YYYY]"
              inputProps={{
                style: {
                  textAlign: 'right'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormInput
              header="Skjema mottatt"
              onChange={(value: any) => handleChange('skjemaMottatt', value?.nr)}
              value={personData?.skjemaMottatt || ''}
              placeholder="[DD.MM.YYYY]"
              inputProps={{
                style: {
                  textAlign: 'right'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormInput
              header="Tilbakemelding sendt"
              onChange={(value: any) => handleChange('tilbakemeldingSendt', value?.nr)}
              value={personData?.tilbakemeldingSendt || ''}
              placeholder="[DD.MM.YYYY]"
              inputProps={{
                style: {
                  textAlign: 'right'
                }
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
