import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useUserContext } from '../../../context/AuthContext';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { AlertI } from '../../../types/alertTypes';
import { ApiCall } from '../../utilities/ApiCall';
import {
  EnhancedTableHead,
  getComparator,
  Order
} from '../../utilities/EnhancedTableHead';
import IsOwnedByUser from '../../utilities/Validate';
import { HeadCell } from '../components/commons/CommonTypes';
import { EnhetData, MatvareData } from '../matvare/types/MatvareTypes';
import LopedagEnheterModal from './components/modals/LopedagEnheterModal';
import MatvareListeModal from './components/modals/MatvareListeModal';
import EnchancedMatvareRow from './EnhancedMatvareRow';
import { LopedagI, MaaltidskomponentI, UtbyttetI } from './types/PersonTypes';
import { CountResult } from './types/ProjectTypes';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteModal from './components/modals/DeleteModal';

const activeStyle = {
  ':not(:hover)': {
    backgroundColor: '#F1F1F1',
    '& > :nth-of-type(-n+5), & > :nth-of-type(7), & > :nth-of-type(n+9)': {
      backgroundColor: '#FFFFFF'
    }
  }
};

const inactiveStyle = {
  ':not(:hover)': {
    backgroundColor: '#F1F1F1',
    '& > :nth-of-type(-n+4), & > :nth-of-type(n+9)': {
      backgroundColor: '#FFFFFF'
    }
  }
};

const headCells: HeadCell<MaaltidskomponentI>[] = [
  { id: 'sortering', align: 'right', padding: 'none', label: 'Sort.' },
  { id: 'kode', align: 'right', padding: 'normal', label: 'Kode' },
  { id: 'navn', align: 'left', padding: 'none', label: 'Navn' },
  { id: 'enhet', align: 'left', padding: 'checkbox', label: 'Enhet' },
  { id: 'antall', align: 'right', padding: 'normal', label: 'Antall' },
  { id: 'mengde', align: 'right', padding: 'none', label: 'Gram' },
  { id: 'kommentar', align: 'left', padding: 'normal', label: 'Kommentar' },
  { id: 'utbyttet', align: 'left', padding: 'normal', label: 'Byttet' }
];

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  personId: string;
}

interface MaaltidsKomponenterTableProps extends RouteComponentProps<RouteParams> {
  maaltidskomponentData: MaaltidskomponentI[];
  setMaaltidskomponentData: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  lopedagData: LopedagI;
  currentMaaltidId: number;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
}

export default function LopedagMaaltidsKomponenterTable(
  props: MaaltidsKomponenterTableProps
) {
  const {
    maaltidskomponentData,
    setMaaltidskomponentData,
    lopedagData,
    currentMaaltidId,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity,
    setRefetchLopedag
  } = props;
  const { prosjektId, undersokelseId, personId } = props.match.params;
  const [newRowData, setNewRowData] = useState<MaaltidskomponentI | null>(null);
  const [editRowData, setEditRowData] = useState<MaaltidskomponentI | null>(null);
  const [utbyttetData, setUtbyttetData] = useState<UtbyttetI[]>();
  const [currentIngredient, setCurrentIngredient] = useState<number>();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof MaaltidskomponentI>('maaltidsId');
  const [alleMaaltider, setAlleMaaltider] = useState<MaaltidskomponentI[] | null>(null);
  const [enhetData, setEnhetData] = useState<EnhetData[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [komponentHasInfo, setKomponentHasInfo] = useState<CountResult>();
  const [fromNewRow, setFromNewRow] = useState<boolean>(false);
  const [fromUtbyttet, setFromUtbyttet] = useState<boolean>(false);
  const [showMengdeEnheter, setShowMengdeEnheter] = useState<boolean>(false);
  const [showMatvareListe, setShowMatvareListe] = useState<boolean>(false);
  const [showDeleteKomponent, setShowDeleteKomponent] = useState<boolean>(false);
  const [selectedKode, setSelectedKode] = useState<number | null>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [openRowArray, setOpenRowArray] = useState<number[]>([]);
  const { currentDatabase } = useContext(DatabaseContext);
  const loggedInuser = useUserContext();
  const owned = useMemo(
    () => IsOwnedByUser({ id: +prosjektId, u: loggedInuser }),
    [prosjektId, loggedInuser]
  );
  const { keycloak } = useKeycloak();

  const callAlert = (message: string, severity: AlertI['alertColor']) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const rows = useMemo(
    () => maaltidskomponentData?.slice().sort(getComparator(order, orderBy)),
    [order, orderBy, maaltidskomponentData]
  );

  const allRows = useMemo(
    () => alleMaaltider?.slice().sort(getComparator(order, orderBy)),
    [order, orderBy, alleMaaltider]
  );

  useEffect(() => {
    if (currentMaaltidId === 0) {
      setAlleMaaltider(
        lopedagData?.maaltider?.map((maaltid) => maaltid?.maaltidskomponenter).flat(1)
      );
    }
  }, [lopedagData?.maaltider, currentMaaltidId]);

  useEffect(() => {
    if (!selectedKode || selectedKode < 0) return;
    getEnheter();
  }, [selectedKode]);

  const getEnheter = () => {
    ApiCall(
      `getEnheter/${currentDatabase.value}/${selectedKode}`,
      'GET',
      keycloak.token,
      setEnhetData
    );
  };

  const checkIfKomponentHasInfo = () => {
    if (!lopedagData?.personId || !currentMaaltidId) return;
    ApiCall(
      `getMaaltidskomponentRelatedInfo/${prosjektId}/${undersokelseId}/${
        lopedagData?.personId
      }/${lopedagData?.lopedagId}/${currentMaaltidId}/${selectedKode}/${2}`,
      'GET',
      keycloak.token,
      setKomponentHasInfo
    ).catch(console.error);
  };

  const deleteMaaltidskomponent = async () => {
    const response = await ApiCall(
      `deleteMaaltidskomponent/${prosjektId}/${undersokelseId}/${lopedagData?.personId}/${
        lopedagData?.lopedagId
      }/${currentMaaltidId}/${selectedKode}/${1}`,
      'DELETE',
      keycloak?.token,
      undefined,
      { data: [] }
    );
    if (response?.ok) {
      callAlert('Måltidskomponent slettet', 'success');
    } else {
      callAlert('Sletting av måltidskomponent feilet', 'error');
    }
  };

  useEffect(() => {
    if (!personId || !lopedagData?.lopedagId || !currentMaaltidId || !selectedKode)
      return;
    const fetchData = async () => {
      const ordnrId = maaltidskomponentData?.find(
        (k) => k.kode === selectedKode
      )?.ordnrId;
      console.log('ordnrId', ordnrId);
      const response = await ApiCall<UtbyttetI[]>(
        `getByttIngredienser/${currentDatabase?.value}/${selectedKode}/${prosjektId}/${undersokelseId}/${personId}/${lopedagData?.lopedagId}/${currentMaaltidId}/${ordnrId}`,
        'GET',
        keycloak?.token
      );
      if (response?.ok) {
        const json: UtbyttetI[] = await response?.json();
        setUtbyttetData(json);
      }
    };
    fetchData();
  }, [personId, lopedagData?.lopedagId, currentMaaltidId, selectedKode]);

  interface UtbyttetResponseI {
    notInserted: string[];
    notUpdated: string[];
  }

  const saveAndUpdateUtbyttet = async (inputData: UtbyttetI[]) => {
    const ordnrId = maaltidskomponentData?.find((k) => k.kode === selectedKode)?.ordnrId;
    const dataToSend = inputData?.filter((d) => d.matvareinn);
    console.log('dataToSend', dataToSend);

    const response = await ApiCall(
      `saveAndUpdateUtbyttet/${prosjektId}/${undersokelseId}/${personId}/${lopedagData?.lopedagId}/${currentMaaltidId}/${selectedKode}/${ordnrId}`,
      'POST',
      keycloak?.token,
      undefined,
      // { data: dataToSend }
      { data: dataToSend }
    );
    if (response?.ok) {
      const json = (await response?.json()) as UtbyttetResponseI;
      if (json?.notInserted?.length > 0 || json?.notUpdated?.length > 0) {
        callAlert(`${json?.notUpdated?.join(', ')} bytter ble ikke lagret`, 'info');
        setRefetchLopedag(true);
      } else {
        callAlert('Ingredienser byttet ut', 'success');
        setRefetchLopedag(true);
      }
    } else {
      callAlert('Utbytting feilet', 'error');
      console.error('error', response?.status);
    }
  };

  // useEffect(() => {
  //   if (!newRowActive) return;
  //   const getMatvareListByKode = async () => {
  //     const response = await ApiCall(
  //       `getMatvareList/${currentDatabase.value}/${newRowData?.kode}/${newRowData?.kode}`,
  //       'GET',
  //       keycloak?.token
  //     );
  //     if (response?.ok) {
  //       const json = await response?.json();
  //       console.log('json', json);
  //       // setNewRowData((prev) => ({...prev, kommentar: }))
  //     }
  //   };
  //   getMatvareListByKode();
  // }, [newRowActive]);

  useEffect(() => {
    if (newRowData?.enhet && newRowData?.antall) {
      const gPerEnhet = enhetData?.find(
        (u) => u.MENGDEENHETID === newRowData?.enhet
      ).MENGDE;
      const newMengde =
        +newRowData?.antall * parseFloat(gPerEnhet?.toString().trim().replace(/,/g, '.'));
      setNewRowData((prev) => ({ ...prev, mengde: newMengde?.toString() }));
    }
  }, [newRowData?.enhet, newRowData?.antall]);

  const handleChange = (
    field: Extract<keyof MaaltidskomponentI, string | number>,
    value: string | number,
    set: Dispatch<SetStateAction<MaaltidskomponentI>>
  ) => {
    if ((field === 'kode' || field === 'antall' || field === 'mengde') && +value < 0) {
      callAlert(
        `${field.charAt(0).toUpperCase()}${field.slice(1)} kan ikke være negativ`,
        'error'
      );
      return;
    }
    if (field === 'enhet') {
      set((prev) => ({ ...prev, antall: null, mengde: null, enhet: value as string }));
    }
    if (field === 'mengde') {
      set((prev) => ({ ...prev, enhet: null, antall: null, mengde: value as string }));
      return;
    }
    set((prev) => ({ ...prev, [field]: value }));
  };

  const handleCodeSubmit = async (kode: number) => {
    if (!kode) return;
    if (maaltidskomponentData?.map((m) => m.kode)?.includes(kode)) {
      callAlert(`Kode ${kode} er allerede lagt til`, 'error');
      setNewRowData(null);
      return;
    }
    const response = await ApiCall(
      `getMatvareByKode/${currentDatabase?.value}/${kode}`,
      'GET',
      keycloak.token
    );
    if (response?.ok) {
      const data: MatvareData = await response.json();
      if (!data?.NAVN) {
        setNewRowData(null);
        callAlert(`Kode ${kode} finnes ikke`, 'error');
      } else {
        setSelectedKode(kode);
        setNewRowData((prev) => ({
          ...prev,
          navn: data?.NAVN,
          enhet: null,
          antall: null,
          mengde: null
        }));
      }
    } else {
      console.log('error', response);
    }
  };

  const handleEnhetSubmit = (
    get: MaaltidskomponentI,
    set: Dispatch<SetStateAction<MaaltidskomponentI>>,
    fromNewRow: boolean
  ) => {
    if (!get?.enhet) return;
    const foundUnit = enhetData?.find(
      (u) => u.MENGDEENHETID?.toLowerCase() === get?.enhet?.toLowerCase()
    );
    console.log('foundUnit', foundUnit);
    if (foundUnit) {
      callAlert('Enhet funnet', 'success');
      set((prev) => ({ ...prev, enhet: foundUnit.MENGDEENHETID }));
      setTimeout(() => {
        document?.getElementById(`antall-input-${selectedKode}`)?.focus();
      }, 200);
    } else {
      callAlert('Enhet ikke funnet, velg fra listen', 'error');
      set((prev) => ({ ...prev, enhet: null }));
      setShowMengdeEnheter(true);
      setFromNewRow(fromNewRow);
      setSelectedKode(get?.kode);
    }
  };

  const handleSave = () => {
    const newRow: MaaltidskomponentI = {
      ...newRowData,
      maaltidsId: currentMaaltidId,
      ordnrId: 1,
      sortering:
        maaltidskomponentData?.length > 0
          ? Math.max(...maaltidskomponentData?.map((k) => k.sortering)) + 1
          : 1
    };
    setMaaltidskomponentData((prev) => [...prev, newRow]);
    setNewRowData(null);
  };

  const handleDelete = () => {
    if (fromNewRow) {
      setNewRowData(null);
    } else {
      deleteMaaltidskomponent();
      setRefetchLopedag(true);
    }
  };

  const handleRequestSort = (property: keyof MaaltidskomponentI) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const findHeadCell = (id: Extract<keyof MaaltidskomponentI, string>) =>
    headCells.find((c) => c.id === id);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-labelledby="tableTitle" size={'small'}>
          <EnhancedTableHead
            headCells={headCells}
            leftColumns={
              currentMaaltidId === 0
                ? [{ name: 'Målt.', align: 'right', padding: 'checkbox' }]
                : undefined
            }
            rightColumns={[
              { name: 'Flytt', align: 'center' },
              { name: 'Rediger', padding: 'none' },
              { name: 'Meny', align: 'right' }
            ]}
            sorting={{
              order: order,
              orderBy: orderBy,
              onRequestSort: handleRequestSort,
              sortColumns: ['maaltidsId', 'sortering', 'navn']
            }}
          />
          <TableBody>
            {currentMaaltidId === 0 ? (
              allRows?.map((row, index) => (
                <EnchancedMatvareRow
                  maaltidskomponentData={maaltidskomponentData}
                  setMaaltidskomponentData={setMaaltidskomponentData}
                  key={index}
                  row={row}
                  // handleBytte={handleBytte}
                  headCells={headCells}
                  index={index}
                  order={order}
                  orderBy={orderBy}
                  selectedKode={selectedKode}
                  setSelectedKode={setSelectedKode}
                  setShowMengdeEnheter={setShowMengdeEnheter}
                  handleChange={handleChange}
                  handleEnhetSubmit={handleEnhetSubmit}
                  editRowData={editRowData}
                  setEditRowData={setEditRowData}
                  setShowDeleteKomponent={setShowDeleteKomponent}
                  checkIfKomponentHasInfo={checkIfKomponentHasInfo}
                  currentMaaltidId={currentMaaltidId}
                  enhetData={enhetData}
                  utbyttetData={utbyttetData}
                  setUtbyttetData={setUtbyttetData}
                  setFromUtbyttet={setFromUtbyttet}
                  callAlert={callAlert}
                  setShowMatvareListe={setShowMatvareListe}
                  currentIngredient={currentIngredient}
                  setCurrentIngredient={setCurrentIngredient}
                  saveAndUpdateUtbyttet={saveAndUpdateUtbyttet}
                  openRowArray={openRowArray}
                  setOpenRowArray={setOpenRowArray}
                />
              ))
            ) : (
              <>
                {rows?.map((row, index) => (
                  <EnchancedMatvareRow
                    maaltidskomponentData={maaltidskomponentData}
                    setMaaltidskomponentData={setMaaltidskomponentData}
                    key={row.kode}
                    row={row}
                    // handleBytte={handleBytte}
                    headCells={headCells}
                    index={index}
                    order={order}
                    orderBy={orderBy}
                    selectedKode={selectedKode}
                    setSelectedKode={setSelectedKode}
                    setShowMengdeEnheter={setShowMengdeEnheter}
                    handleChange={handleChange}
                    handleEnhetSubmit={handleEnhetSubmit}
                    editRowData={editRowData}
                    setEditRowData={setEditRowData}
                    setShowDeleteKomponent={setShowDeleteKomponent}
                    checkIfKomponentHasInfo={checkIfKomponentHasInfo}
                    currentMaaltidId={currentMaaltidId}
                    enhetData={enhetData}
                    utbyttetData={utbyttetData}
                    setUtbyttetData={setUtbyttetData}
                    setFromUtbyttet={setFromUtbyttet}
                    callAlert={callAlert}
                    setShowMatvareListe={setShowMatvareListe}
                    currentIngredient={currentIngredient}
                    setCurrentIngredient={setCurrentIngredient}
                    saveAndUpdateUtbyttet={saveAndUpdateUtbyttet}
                    openRowArray={openRowArray}
                    setOpenRowArray={setOpenRowArray}
                  />
                ))}
                {/* <TableRow sx={newRowActive ? activeStyle : inactiveStyle} hover> */}
                <TableRow onClick={() => setSelectedKode(null)} hover>
                  <TableCell
                    padding={findHeadCell('sortering').padding}
                    align={findHeadCell('sortering').align}
                  >
                    {newRowData?.sortering}
                  </TableCell>

                  {/* KODE */}
                  <TableCell
                    padding={findHeadCell('kode').padding}
                    align={findHeadCell('kode').align}
                    sx={{ pb: 0, pt: 0 }}
                  >
                    <Stack direction={'row'} alignItems="center">
                      <IconButton
                        onClick={() => {
                          setNewRowData(null);
                          setShowMatvareListe(true);
                        }}
                        disabled={!currentMaaltidId}
                      >
                        <ChatBubbleOutlineIcon />
                      </IconButton>
                      <TextField
                        variant="standard"
                        type="number"
                        value={newRowData?.kode || ''}
                        sx={{ minWidth: '2.5rem' }}
                        onChange={(event) =>
                          handleChange('kode', +event.target.value, setNewRowData)
                        }
                        onKeyDown={(event) =>
                          event.key === 'Enter' && handleCodeSubmit(newRowData?.kode)
                        }
                        onBlur={() => handleCodeSubmit(newRowData?.kode)}
                        inputProps={{
                          style: {
                            textAlign: 'right'
                          }
                        }}
                        disabled={!currentMaaltidId}
                      />
                    </Stack>
                  </TableCell>

                  {/* NAVN */}
                  <TableCell
                    padding={findHeadCell('navn').padding}
                    align={findHeadCell('navn').align}
                    sx={{ pb: 0, pt: 0 }}
                  >
                    <Stack direction={'row'} alignItems="center">
                      <IconButton
                        onClick={() => setShowMatvareListe(true)}
                        disabled={!currentMaaltidId}
                      >
                        <ChatBubbleOutlineIcon />
                      </IconButton>
                      <TextField
                        variant="standard"
                        type="text"
                        value={newRowData?.navn || ''}
                        sx={{ minWidth: '4rem' }}
                        onChange={(event) =>
                          handleChange('navn', event.target.value, setNewRowData)
                        }
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            setNewRowData({ ...newRowData, kode: null });
                            setShowMatvareListe(true);
                          }
                        }}
                        disabled={!currentMaaltidId}
                      />
                    </Stack>
                  </TableCell>

                  {/* ENHET */}
                  <TableCell
                    padding={findHeadCell('enhet').padding}
                    align={findHeadCell('enhet').align}
                    sx={{ pb: 0, pt: 0 }}
                  >
                    <Stack direction={'row'} alignItems="center">
                      <IconButton
                        onClick={() => {
                          setFromNewRow(true);
                          setShowMengdeEnheter(true);
                        }}
                        disabled={!newRowData?.kode || !newRowData?.navn}
                      >
                        <ChatBubbleOutlineIcon />
                      </IconButton>
                      <TextField
                        variant="standard"
                        type="text"
                        value={newRowData?.enhet || ''}
                        sx={{ minWidth: '3rem' }}
                        onChange={(event) =>
                          handleChange('enhet', event.target.value, setNewRowData)
                        }
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          handleEnhetSubmit(newRowData, setNewRowData, true)
                        }
                        onBlur={() => handleEnhetSubmit(newRowData, setNewRowData, true)}
                        disabled={!newRowData?.kode || !newRowData?.navn}
                      />
                    </Stack>
                  </TableCell>

                  {/* ANTALL */}
                  <TableCell
                    padding={findHeadCell('antall').padding}
                    align={findHeadCell('antall').align}
                    sx={{ pb: 0, pt: 0 }}
                  >
                    <TextField
                      id={`antall-input-${newRowData?.kode}`}
                      variant="standard"
                      type="number"
                      value={newRowData?.antall || ''}
                      sx={{ minWidth: '3rem' }}
                      onChange={(event) =>
                        handleChange('antall', event.target.value, setNewRowData)
                      }
                      inputProps={{
                        style: {
                          textAlign: 'right'
                        }
                      }}
                      disabled={!newRowData?.enhet}
                    />
                  </TableCell>

                  {/* MENGDE */}
                  <TableCell
                    padding={findHeadCell('mengde').padding}
                    align={findHeadCell('mengde').align}
                    sx={{ pb: 0, pt: 0 }}
                  >
                    <TextField
                      variant="standard"
                      type="number"
                      value={newRowData?.mengde || ''}
                      sx={{ minWidth: '3rem' }}
                      onChange={(event) =>
                        handleChange('mengde', event.target.value, setNewRowData)
                      }
                      inputProps={{
                        style: {
                          textAlign: 'right'
                        }
                      }}
                      disabled={!newRowData?.kode || !newRowData?.navn}
                    />
                  </TableCell>

                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>

                  {/* SAVE, CLEAR OR EDIT */}
                  <TableCell padding="none" align="center" sx={{ pb: 0, pt: 0 }}>
                    <IconButton
                      aria-label="done"
                      onClick={handleSave}
                      disabled={
                        !(
                          !!newRowData?.kode &&
                          !!newRowData?.navn &&
                          (!!newRowData?.mengde ||
                            (!!newRowData?.enhet && !!newRowData?.antall))
                        )
                      }
                    >
                      <CheckOutlinedIcon />
                    </IconButton>
                  </TableCell>

                  {/* MENU */}
                  <TableCell align="right" sx={{ pb: 0, pt: 0 }}>
                    <IconButton
                      onClick={(event: MouseEvent<HTMLButtonElement>) =>
                        setAnchorEl(event.currentTarget)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem disabled={!selectedKode}>
                        <ListItemIcon>
                          <ContentCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Kopier</ListItemText>
                      </MenuItem>
                      <MenuItem disabled={!currentMaaltidId}>
                        <ListItemIcon>
                          <ContentPasteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Lime inn</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setNewRowData(null)}>
                        <ListItemIcon>
                          <DeleteOutlineIcon />
                        </ListItemIcon>
                        <ListItemText>Slette</ListItemText>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showMengdeEnheter && (
        <LopedagEnheterModal
          showing={showMengdeEnheter}
          hide={() => {
            setFromNewRow(false);
            setShowMengdeEnheter(false);
          }}
          enhetData={enhetData}
          selectedKode={selectedKode}
          maaltidskomponentData={maaltidskomponentData}
          fromNewRow={fromNewRow}
          setNewRowData={setNewRowData}
          setEditRowData={setEditRowData}
        />
      )}

      {MatvareListeModal && (
        <MatvareListeModal
          showing={showMatvareListe}
          hide={() => {
            setShowMatvareListe(false);
            setFromUtbyttet(false);
          }}
          showProgress={showProgress}
          setShowProgress={setShowProgress}
          newRowData={newRowData}
          setNewRowData={setNewRowData}
          fromUtbyttet={fromUtbyttet}
          utbyttetData={utbyttetData}
          setUtbyttetData={setUtbyttetData}
          currentIngredient={currentIngredient}
        />
      )}

      {showDeleteKomponent && (
        <DeleteModal
          showing={showDeleteKomponent}
          hide={() => {
            setKomponentHasInfo(null);
            setShowDeleteKomponent(false);
          }}
          handleDelete={handleDelete}
          title="Slette måltidskomponent?"
          body={
            komponentHasInfo?.COUNT > 0
              ? `Måltidskomponent med kode ${selectedKode} har underliggende data. Ønsker du å slette den?`
              : `Ønsker du å slette måltidskomponent med kode ${selectedKode}`
          }
        />
      )}
    </>
  );
}
