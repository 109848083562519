import React, { ReactNode } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { ItemOwner } from './CommonTypes';
import { DialogBox } from '../../../utilities/DialogBox';
import { Dropdown, NButton } from '../Inputs';
import { AlertI } from '../../../../types/alertTypes';

interface BrukertilgangProps<T> {
  header: ReactNode;
  getUrl: string;
  deleteUrl: string;
  postUrl: string;
  showAlertComponent: (a: string, b: AlertI['alertColor']) => void;
  owned: boolean;
  setProgressBar: (a: boolean) => void;
  showing: boolean;
  hide: () => void;
}

function Brukertilgang<T>(p: BrukertilgangProps<T>) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler tilgang';
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };

  const [users, setUsers] = React.useState<ItemOwner[]>([]);
  const [current, setCurrent] = React.useState<ItemOwner[]>([]);
  const [state, setState] = React.useState<ItemOwner[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<ItemOwner>(null);

  React.useEffect(() => {
    apiCall('getUsers', 'GET')
      .then((resp) => resp.json())
      .then((resp) => setUsers(resp));
  }, []);
  React.useEffect(() => {
    apiCall(p.getUrl, 'GET')
      .then((resp) => resp.json())
      .then((resp) =>
        setCurrent(resp?.map((r) => ({ brukerkode: r.brukerkode, id: r.id })))
      );
  }, [p.getUrl]);

  React.useEffect(() => setState(current), [current]);

  const addToState = (users: ItemOwner[]) => {
    const newUsers = users?.filter((u) => !state?.find((a) => a.id === u.id));

    setState([...state, ...newUsers]);
  };

  const removeFromState = (users: ItemOwner[]) => {
    const RID = users?.map((u) => u.id);

    setState(state?.filter((s) => !RID.includes(s.id)));
    setSelected([]);
  };

  const saveChanges = async () => {
    p.setProgressBar(true);

    const removed = current?.filter((a) => !state?.find((b) => a.id === b.id));
    const added = state?.filter((a) => !current?.find((b) => a.id === b.id));

    if ((!removed?.length && !added?.length) || !p.owned) {
      p.showAlertComponent('Ingen endringer gjort', 'warning');
      p.setProgressBar(false);
      setCurrent(state);
      return;
    }

    var failed = 0;
    removed?.map(async (u) => {
      const response = await apiCall(`${p.deleteUrl}`, 'DELETE', { data: [u.id] });

      if (!response.ok) {
        failed++;
      }
    });
    //postUrl = `createBrukertilgang/${projectId}/${undersokelseId}`
    if (!failed) {
      const response = await apiCall(p.postUrl, 'POST', {
        data: added?.map((u) => u.id)
      });

      if (!response.ok) {
        failed += added?.length;
      }
    }

    if (!failed) {
      p.showAlertComponent('Lagret brukertilganger', 'success');
      setCurrent(state);
    } else {
      p.showAlertComponent(
        `Klarte ikke å lagre brukertilganger (${failed} feil)`,
        'error'
      );
    }
    p.setProgressBar(false);
  };

  const [selected, setSelected] = React.useState<number[]>([]);
  const allSelected = React.useMemo(
    () => (selected?.length === 0 ? false : Boolean(selected?.length === state?.length)),
    [selected?.length, state?.length]
  );

  const toggleSelection = (i: number) => {
    if (selected?.includes(i)) {
      setSelected(selected?.filter((a) => a !== i));
    } else {
      setSelected([...selected, i]);
    }
  };

  const availableUsers = React.useMemo(
    () => users?.filter((u) => !state?.find((s) => s.id === u.id)),
    [users, state]
  );

  return (
    <DialogBox title="Brukertilgang" isShowing={p.showing} hide={p.hide} size="md">
      <Grid container spacing={1}>
        <Grid item xs={8} container>
          {p.header}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <Typography variant="h6" children="Brukere som er gitt tilgang" />
        </Grid>
        <Grid item xs={12}>
          <Table size="small" sx={{ border: '1px solid black' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={allSelected}
                    indeterminate={Boolean(!allSelected && selected?.length)}
                    onClick={() =>
                      allSelected ? setSelected([]) : setSelected(state?.map((u, i) => i))
                    }
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Bruker</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Fjern</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.map((row, i) => {
                const checked = selected?.includes(i);
                return (
                  <TableRow hover selected={checked}>
                    <TableCell>
                      <Checkbox checked={checked} onChange={() => toggleSelection(i)} />
                    </TableCell>
                    <TableCell>{row.brukerkode}</TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <IconButton
                        children={<PersonRemoveOutlinedIcon />}
                        onClick={() => removeFromState([row])}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="flex-end">
          <Grid item xs={8}>
            <Dropdown
              header="Velg bruker"
              value={selectedUser}
              options={availableUsers}
              optionLabel={(o) => o.brukerkode}
              onChange={(v) => setSelectedUser(v as ItemOwner)}
              disabled={!p.owned}
            />
          </Grid>
          <Grid item xs={3}>
            <NButton
              leftIcon={<PersonAddOutlinedIcon />}
              children="Legg til"
              variant="outlined"
              disabled={!p.owned}
              onClick={() => {
                if (selectedUser) {
                  addToState([selectedUser]);
                  setSelectedUser(null);
                }
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <NButton
              children="Lagre"
              disabled={!p.owned || current === state}
              onClick={saveChanges}
            />
            <NButton
              leftIcon={<GroupRemoveOutlinedIcon />}
              children="Fjern valgte"
              variant="outlined"
              disabled={!p.owned || !selected?.length}
              onClick={() => removeFromState(selected?.map((i) => state[i]))}
            />
          </Stack>
        </Grid>
      </Grid>
    </DialogBox>
  );
}
export default Brukertilgang;
