import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { AlertI } from '../../../types/alertTypes';
import AlertComp from '../../utilities/AlertComp';
import { CodeRegisterI } from '../components/commons/CommonTypes';
import { NButton } from '../components/Inputs';
import LopedagMaaltidsberegningModal from './components/modals/LopedagMaaltidsberegningModal';
import LopedagStoffgrupperModal from './components/modals/LopedagStoffgrupperModal';
import LopedagMaaltidsKomponenterTable from './LopedagMaaltidsKomponenterTable';
import {
  LopedagI,
  MaaltidI,
  MaaltidskomponentI,
  PersonI,
  StoffnMengdeI,
  UtbyttetI
} from './types/PersonTypes';
import { UtvalgsId } from '../beregning/types/BeregningTypes';

interface RouteParams {
  prosjektId: string;
  undersokelseId: string;
  personId: string;
}

interface MaaltidsKomponenterProps extends RouteComponentProps<RouteParams> {
  lopedagData: LopedagI;
  maaltidskomponentData: MaaltidskomponentI[];
  setMaaltidskomponentData: Dispatch<SetStateAction<MaaltidskomponentI[]>>;
  maaltidsData: MaaltidI[];
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
  utvalgId: UtvalgsId;
  stoffnMengdeData: StoffnMengdeI[];
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  setRefetchLopedag: Dispatch<SetStateAction<boolean>>;
  getMaaltidsBeregning: (mId: number) => void;
  utvalgData: StoffnMengdeI[];
  setUtvalgData: Dispatch<React.SetStateAction<StoffnMengdeI[]>>;
  // setMaaltidskomponentDeleteArray: Dispatch<SetStateAction<number[]>>;
}

export default function LopedagMaaltidsKomponenter(props: MaaltidsKomponenterProps) {
  const {
    lopedagData,
    maaltidskomponentData,
    setMaaltidskomponentData,
    maaltidsData,
    maaltidTypes,
    currentMaaltidId,
    setCurrentMaaltidId,
    setRefetchLopedag,
    stoffnMengdeData,
    utvalgId,
    getMaaltidsBeregning,
    utvalgData,
    setUtvalgData
    // setMaaltidskomponentDeleteArray
  } = props;
  const { prosjektId, undersokelseId, personId } = props.match.params;
  // const [komponent, setKomponent] = useState<MaaltidskomponentI | null>();
  const [valgOptions, setValgOptions] = useState<CodeRegisterI[]>([]);
  const [showStoffgrupper, setShowStoffgrupper] = useState<boolean>(false);
  const [showMaaltidsberegning, setShowMaaltidsberegning] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<AlertI['alertColor']>('info');
  const { currentDatabase } = useContext(DatabaseContext);

  useEffect(() => {
    setMaaltidskomponentData(
      lopedagData?.maaltider?.find((maaltid) => maaltid?.maaltidsId === currentMaaltidId)
        ?.maaltidskomponenter
    );
  }, [lopedagData?.maaltider, currentMaaltidId]);

  useEffect(() => {
    //This happens at page load, save & delete måltidakomponenter, save & delete byttIngerediens
    if (currentMaaltidId && utvalgId?.ID) {
      getMaaltidsBeregning(currentMaaltidId);
    }
  }, [currentMaaltidId, utvalgId?.ID, utvalgData]);

  useEffect(() => {
    if (lopedagData?.maaltider) {
      setValgOptions([
        { label: 'Alle', nr: 0 },
        ...lopedagData?.maaltider?.map((maaltid) => {
          return { label: maaltid?.maaltidsId.toString(), nr: maaltid?.maaltidsId };
        })
      ]);
    }
  }, [lopedagData]);

  // const getByttIngredienser = useFetchData({
  //   loadOnMount: false,
  //   clearDataOnLoad: false,
  //   projectURL: 'getByttIngredienser',
  //   subProjectURL: `${currentDatabase?.value}/${komponent?.kode}/${prosjektId}/${undersokelseId}/${personData?.personId}/${lopedagData?.lopedagId}/${currentMaaltidId}/${komponent?.ordnrId}`
  // });

  // useEffect(() => {
  //   if (
  //     personData?.lopedager &&
  //     lopedagData?.lopedagId &&
  //     maaltidskomponentData &&
  //     komponent
  //   ) {
  //     getByttIngredienser.loadData(
  //       'getByttIngredienser',
  //       `${currentDatabase?.value}/${komponent?.kode}/${prosjektId}/${undersokelseId}/${personData?.personId}/${lopedagData?.lopedagId}/${currentMaaltidId}/${komponent?.ordnrId}`
  //     );
  //   }
  // }, [
  //   personData?.personId,
  //   lopedagData?.lopedagId,
  //   maaltidskomponentData,
  //   currentDatabase?.value,
  //   currentMaaltidId,
  //   currentMatvare
  // ]);

  // useEffect(() => {
  //   if (getByttIngredienser.data) {
  //     const data = getByttIngredienser.data as unknown as UtbyttetI[];
  //     setByttetUtData(data);
  //   }
  // }, [getByttIngredienser.data]);

  // // HANDLING
  // function handleBytte(sort: number) {
  //   setCurrentMatvare(sort);
  //   setKomponent(maaltidskomponentData?.find((item) => item.sortering === sort));
  // }

  return (
    <>
      <Stack spacing={2} sx={{ pt: '4rem' }}>
        <Typography variant="h6">Måltidskomponenter:</Typography>
        <Stack direction={'row'} alignItems={'end'} spacing={1} useFlexGap>
          <FormControl sx={{ minWidth: '7.5rem' }}>
            <InputLabel id="demo-simple-select-label">Velg måltid</InputLabel>
            <Select
              value={
                valgOptions?.find((option) => option.nr === currentMaaltidId)?.nr || ''
              }
              // renderValue={(value) => (value === 0 ? 'Alle' : value)}
              label="Velg måltid"
              onChange={(event) => setCurrentMaaltidId(+event.target.value)}
              placeholder={'Velg måltid'}
              size="small"
              // autoWidth
            >
              {valgOptions.map((option) => (
                <MenuItem key={option.nr} value={option.nr}>
                  {/* {option === 0 ? 'Alle' : option.toString()} */}
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <Tooltip title={!utvalgData?.length ? 'Stoffutvalg mangler' : ''}>
              <span>
                <NButton
                  children="Måltidsberegning"
                  variant="outlined"
                  onClick={() => setShowMaaltidsberegning(true)}
                  disabled={!currentMaaltidId || !utvalgData?.length}
                />
              </span>
            </Tooltip>
          </Box>
          <Box>
            <NButton
              children={'Velg stoff / gruppe '}
              variant="outlined"
              onClick={() => setShowStoffgrupper(true)}
              disabled={!currentMaaltidId}
            />
          </Box>
          <Tooltip
            title={
              utvalgData?.length > 1 ? utvalgData?.map((u) => u.kortnavn)?.join(', ') : ''
            }
          >
            <Typography sx={{ textWrap: 'balance' }}>
              Valgte stoffer:{' '}
              {utvalgData?.map((u) => u.kortnavn)?.length > 1
                ? `${utvalgData[0]?.kortnavn} og ${utvalgData?.length - 1} andre`
                : utvalgData?.map((u) => u.kortnavn)?.join(', ')}
            </Typography>
          </Tooltip>
        </Stack>
        <Box sx={{ mt: '1rem' }}>
          <LopedagMaaltidsKomponenterTable
            maaltidskomponentData={maaltidskomponentData}
            setMaaltidskomponentData={setMaaltidskomponentData}
            lopedagData={lopedagData}
            currentMaaltidId={currentMaaltidId}
            // handleBytte={handleBytte}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            setRefetchLopedag={setRefetchLopedag}
            {...props}
          />
        </Box>
      </Stack>

      {/* dialogs */}

      {showMaaltidsberegning && (
        <LopedagMaaltidsberegningModal
          showing={showMaaltidsberegning}
          hide={() => setShowMaaltidsberegning(false)}
          stoffData={stoffnMengdeData}
          maaltidTypes={maaltidTypes}
          maaltidsData={maaltidsData}
          currentMaaltidId={currentMaaltidId}
        />
      )}

      {showStoffgrupper && (
        <LopedagStoffgrupperModal
          open={showStoffgrupper}
          utvalgId={utvalgId}
          hide={() => setShowStoffgrupper(false)}
          databaseId={currentDatabase?.value}
          utvalgData={utvalgData}
          setUtvalgData={setUtvalgData}
        />
      )}

      {showAlert && (
        <AlertComp
          alertMessage={alertMessage}
          alertColor={alertSeverity}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
    </>
  );
}
