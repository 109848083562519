import React, { useContext, useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { MonoSearchField, NButton } from '../components/Inputs';
import { useKeycloak } from '@react-keycloak/web';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { MatvareResult } from './types/MatvareTypes';
import { ProgressBar } from '../../utilities/ProgressBar';
import { useUserContext } from '../../../context/AuthContext';
import DialogGeneral from '../../utilities/DialogGeneral';
import MatvareSearchTable from './MatvareSearchTable';
import MatvareSearchTableVirtualized from './matvareSearchTableVirtualized';

interface MatvareSearchProps {
  setMatvare: (id: number | string, navn?: string) => void;
  matvarekode?: string;
  navn?: string;
  databaseId?: number;
  showing: boolean;
  showProgress: boolean;
  setShowProgress: (showProgress: boolean) => void;
  hide: () => void;
}

function MatvareSearch(props: MatvareSearchProps) {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler token';
  const { currentDatabase } = useContext(DatabaseContext);
  const loggedInuser = useUserContext();
  const [matvareData, setMatvareData] = useState<MatvareResult[]>([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [databaseId] = useState(
    props.databaseId === undefined ? currentDatabase?.value : props.databaseId
  );

  const changeMatvare = (kode: string | number, navn?: string) => {
    setSelectedRow(kode);
    props.hide();
    props.setMatvare(`${kode}`, `${navn}`);
  };

  const search = (uri: string, getRows?: (resp: any) => any[]) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    setLoading(true);
    fetch(encodeURI(fullUri), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setMatvareData(getRows ? getRows(resp) : resp || []);
        setLoading(false);
        props.setShowProgress(false);
      });
  };

  const searchWithNumber = (kode?: string) =>
    search(`getMatvareList/${databaseId}/1/999999`, (resp) =>
      kode ? resp?.filter((r: MatvareResult) => r.MATVAREKODEID >= parseInt(kode)) : resp
    );

  const searchWithName = (name: string) =>
    search(
      `getMatvareListByNavn/${databaseId}/${
        encodeURIComponent(name?.toLowerCase()?.trim()) || 'all'
      }/${-1}`
    );
  const searchWithMyNumbers = (kodeFrom: number, kodeTo: number) =>
    search(`getMatvareList/${databaseId}/${kodeFrom || '1'}/${kodeTo || '999999'}`);

  useEffect(() => {
    if (props.showing) {
      searchWithNumber();
    }
  }, [props.showing]);
  const setMittNummeromrade = () => {
    let rangeFrom = loggedInuser?.user?.numberRange[0];
    let rangeTo = loggedInuser?.user?.numberRange[1];
    searchWithMyNumbers(rangeFrom, rangeTo);
  };

  return (
    <DialogGeneral
      title="Matvareliste"
      open={props.showing}
      hide={props.hide}
      fullWidth
      maxWidth="lg"
    >
      {loading && (
        <ProgressBar
          text={'Loading...'}
          isShowing={props.showProgress}
          hide={() => {
            props.setShowProgress(!props.showProgress);
          }}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4} container alignItems="flex-end">
            <MonoSearchField
              header="Søk  etter matvarekode"
              //preValue={searchCode}
              searchFn={(n) => searchWithNumber(n)}
            />
          </Grid>
          <Grid item xs={4} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter navn eller søkeord"
              searchFn={(n) => searchWithName(n)}
              //preValue={searchName}
              //searchFn={(n) => searchWithName(searchName || n)}
            />
          </Grid>
          <Grid item xs={4} container alignItems="flex-end">
            <NButton
              onClick={setMittNummeromrade}
              children="Mitt nummerområde"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!loading || matvareData?.length < 1 ? (
            <MatvareSearchTable
              matvareData={matvareData}
              selectedRowId={selectedRow}
              changeMatvare={changeMatvare}
            />
          ) : (
            <Skeleton variant="rectangular" width="90vw" height="100em" />
          )}
        </Grid>
      </Grid>
    </DialogGeneral>
  );
}

export default MatvareSearch;
