import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableContainer,
  Paper,
  Radio,
  Stack,
  Typography
} from '@mui/material';
import { EnhetData } from '../../../matvare/types/MatvareTypes';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { NButton } from '../../../components/Inputs';
import { MaaltidskomponentI } from '../../types/PersonTypes';
import { HeadCell } from '../../../components/commons/CommonTypes';

const headCells: readonly HeadCell<EnhetData>[] = [
  { id: 'SORTERING', align: 'left', padding: 'normal', label: 'Sortering' },
  { id: 'MENGDEENHETID', align: 'left', padding: 'normal', label: 'Enhet' },
  { id: 'MENGDE', align: 'left', padding: 'normal', label: 'g/enhet' },
  { id: 'REF', align: 'left', padding: 'normal', label: 'Ref.' }
];

interface EnhetProps {
  showing: boolean;
  hide: () => void;
  enhetData: EnhetData[];
  selectedKode: number;
  maaltidskomponentData: MaaltidskomponentI[];
  fromNewRow: boolean;
  setNewRowData: Dispatch<SetStateAction<MaaltidskomponentI>>;
  setEditRowData: Dispatch<SetStateAction<MaaltidskomponentI>>;
}

export default function EnhetList(props: EnhetProps) {
  const {
    showing,
    hide,
    enhetData,
    selectedKode,
    maaltidskomponentData,
    fromNewRow,
    setNewRowData,
    setEditRowData
  } = props;
  const [selectedEnhet, setSelectedEnhet] = useState<string | null>(null);

  const handleOk = async () => {
    if (fromNewRow) {
      setNewRowData((prev) => ({
        ...prev,
        enhet: selectedEnhet,
        antall: null,
        mengde: null
      }));
    } else {
      setEditRowData((prev) => ({
        ...prev,
        enhet: selectedEnhet,
        antall: null,
        mengde: null
      }));
    }
    setTimeout(() => {
      document?.getElementById(`antall-input-${selectedKode}`)?.focus();
    }, 200);
    hide();
  };

  return (
    <DialogGeneral
      title={'Mengdeenheter'}
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h6" gutterBottom>
        Matvare: {maaltidskomponentData?.find((m) => m.kode === selectedKode)?.navn}
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Velg</TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  padding={headCell.padding}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {enhetData?.map((row: EnhetData, index: number) => (
                <TableRow
                  hover
                  key={row.SORTERING}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setSelectedEnhet(row.MENGDEENHETID)}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      checked={row.MENGDEENHETID === selectedEnhet}
                      inputProps={{
                        'aria-labelledby': 'table-checkbox-' + index
                      }}
                    />
                  </TableCell>
                  {headCells.map((cell, index) => (
                    <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
                      {cell.id === 'MENGDE'
                        ? row[cell.id].toString()?.replace(/,/g, '.')
                        : row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          }
        </Table>
      </TableContainer>

      <Stack direction="row" justifyContent={'space-between'} marginTop={2}>
        <NButton onClick={handleOk}>Ok</NButton>
        <NButton
          onClick={() => {
            setSelectedEnhet(null);
            hide();
          }}
          disabled={!selectedEnhet}
        >
          Avbryt
        </NButton>
      </Stack>
    </DialogGeneral>
  );
}
