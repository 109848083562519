import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState, MouseEvent, SetStateAction, Dispatch } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { HeadCell } from '../components/commons/CommonTypes';
import { TilleggsvariableI } from './types/TilleggsvariableTypes';
import { EnhancedTableHead, Order } from '../../utilities/EnhancedTableHead';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface BakgrunnsvariabelI {
  kode: number;
  beskrivelse: string;
}

const bakgrunnsvariabelHeadCells: HeadCell<BakgrunnsvariabelI>[] = [
  { id: 'kode', align: 'left', padding: 'normal', label: 'Kode' },
  { id: 'beskrivelse', align: 'left', padding: 'none', label: 'Beskrivelse' }
];

const dummyData: BakgrunnsvariabelI[] = [
  {
    kode: 1,
    beskrivelse:
      ' ullamco laboris nisi uprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." 1'
  },
  {
    kode: 2,
    beskrivelse:
      ' ullamco laboris nisi ut aliquip ex ea commodo consequat. Duisj laborum." 2'
  },
  {
    kode: 3,
    beskrivelse:
      ' ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure jillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." 3'
  },
  {
    kode: 4,
    beskrivelse:
      ' ulla dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." 4'
  },
  {
    kode: 5,
    beskrivelse: ' ullamco borum." 5'
  }
];

interface EnhancedTilleggsvariabelRowProps {
  row: TilleggsvariableI;
  index: number;
  headCells: HeadCell<TilleggsvariableI>[];
  variablerData: TilleggsvariableI[];
  orderBy: string;
  handleMove: (index: number, direction: string) => void;
  checkIfVariableHasInfo: (variabelId: number) => void;
  setDeleteId: Dispatch<SetStateAction<number | null>>;
  setShowDeleteVariabel: Dispatch<SetStateAction<boolean>>;
}

export default function EnhanceTilleggsvariabelRow(
  props: EnhancedTilleggsvariabelRowProps
) {
  const {
    row,
    index,
    headCells,
    variablerData,
    orderBy,
    handleMove,
    checkIfVariableHasInfo,
    setDeleteId,
    setShowDeleteVariabel
  } = props;
  const [openRow, setOpenRow] = useState<boolean>(false);

  return (
    <>
      <TableRow
        hover
        sx={{
          ':not(:hover)': {
            backgroundColor: '#F1F1F1',
            '& > :nth-of-type(n+6)': {
              backgroundColor: '#FFFFFF'
            }
          },
          '& > *': { borderBottom: 'unset !important' }
        }}
      >
        {headCells.map((cell) =>
          cell.id === 'variabelId' || cell.id === 'kortnavn' ? (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              <Link
                href={'/home/kostholdsdata/tilleggsvariabler/' + row?.variabelId}
                target="_blank"
              >
                {row[cell.id]}
              </Link>
            </TableCell>
          ) : cell.id === 'svartype' && row[cell.id] === 'Kategorier' ? (
            <TableCell
              key={cell.id}
              align={cell.align}
              padding={cell.padding}
              sx={{ pb: 0, pt: 0, cursor: 'pointer' }}
              onClick={() => {
                setOpenRow(!openRow);
              }}
            >
              <Stack direction={'row'} alignItems="center">
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
                {row[cell.id]}
              </Stack>
            </TableCell>
          ) : (
            <TableCell key={cell.id} align={cell.align} padding={cell.padding}>
              {row[cell.id]}
            </TableCell>
          )
        )}

        <TableCell padding="none" align="center">
          <Stack direction={'row'} alignItems="center" justifyContent="center">
            <IconButton
              disabled={orderBy !== 'sortering' || index === 0}
              onClick={() => handleMove(row.sortering, 'up')}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              disabled={orderBy !== 'sortering' || index === variablerData?.length - 1}
              onClick={() => handleMove(row.sortering, 'down')}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Stack>
        </TableCell>

        <TableCell padding="none" align="center">
          <IconButton
            onClick={() => {
              setShowDeleteVariabel(true);
              checkIfVariableHasInfo(row.variabelId);
              setDeleteId(row.variabelId);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse appear in={openRow} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Bakgrunnsvariabel: {row.kortnavn}
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <EnhancedTableHead<BakgrunnsvariabelI>
                    headCells={bakgrunnsvariabelHeadCells}
                  />
                  <TableBody>
                    {dummyData?.map((row) => (
                      <TableRow
                        key={row.kode}
                        sx={{
                          ':not(:hover)': {
                            backgroundColor: '#F1F1F1'
                          }
                        }}
                        hover
                      >
                        {bakgrunnsvariabelHeadCells.map((cell) => (
                          <TableCell
                            key={cell.id}
                            align={cell.align}
                            padding={cell.padding}
                          >
                            {row[cell.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
