import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid
} from '@mui/material';
import { ChatBubbleButton } from '../../components/ChatBubbleButton';
import { MatvareGruppeTablePopup } from './matvaregruppeTableComponents/MatvareGruppeTablePopup';
import { MatvaregruppeI, resultatGruppeI } from '../../matvare/types/matvaregruppeTypes';
import BeregningStoff from './BeregningStoff';
import RapportAndEnhet from './RapportAndEnhet';
import useStyles from '../../../../css/materialtheme';
import {
  BeregningObject,
  DefaultBeregningCounts,
  MatvareData,
  UtvalgTableData
} from '../types/BeregningTypes';
import { ResultatGruppe } from '../../matvare/matvaregruppe/ResultatGruppe';
import { SaveInputs } from './SaveInputs';
import useDidMountEffect from '../../../utilities/useDidMountEffect';
import { FjernMatvarerTablePopup } from './FjernmatvarerTableComponents/FjernMatvarerTablePopup';
import { CircularProgress } from '@mui/material';
//import { Stoff, StoffGruppe } from '../../matvare/types/StoffTypes';
import { useDatabaseContext } from '../../../../context/DatabaseContext';
import { StoffI, StoffgruppeI } from '../../stoff/types/StoffTypes';
interface MatvareAndStoffProps {
  beregningObject: BeregningObject;
  totalCounts: DefaultBeregningCounts;
  setTotalCounts: (value: DefaultBeregningCounts) => void;
  updateBeregningObject: (item: string, name: string, value: any) => void;
  showAlertMessage: (message: string, severity: any) => void;
  setBeregningObject: (obj: BeregningObject) => void;
  utvalgData: UtvalgTableData;
  setUtvalgData: (value: UtvalgTableData) => void;
  selectedResultatGroup: resultatGruppeI;
  setSelectedResultatGroup: (value: resultatGruppeI) => void;
  cleared: boolean;
  setShowProgress: (value: boolean) => void;
  setProgressText: (value: string) => void;
}
const MatvareAndStoff = (props: MatvareAndStoffProps) => {
  const {
    beregningObject,
    totalCounts,
    setTotalCounts,
    showAlertMessage,
    setBeregningObject,
    updateBeregningObject,
    utvalgData,
    setUtvalgData,
    selectedResultatGroup,
    setSelectedResultatGroup,
    cleared,
    setShowProgress,
    setProgressText
  } = props;
  const classes = useStyles();
  const db = useDatabaseContext();
  const [showMatvaregruppe, setShowMatvaregruppe] = useState<boolean>(false);
  const [showFjernMatvarer, setShowFjernMatvarer] = useState<boolean>(false);
  const [selectedMatvares, setSelectedMatvares] = useState<MatvareData[]>([]);
  const [fjerndeMatvareList, setFjerndeMatvareList] = useState<MatvareData[]>([]);
  const [selectedStoffs, setSelectedStoffs] = useState<StoffI[]>([]);
  const [selectedStoffgruppe, setSelectedStoffgruppe] = useState<StoffgruppeI>({
    stoffgruppeId: 0,
    beskrivelse: '',
    navn: ''
  });
  const [selectedMatvaregruppes, setSelectedMatvaregruppes] = useState<MatvaregruppeI[]>(
    []
  );
  const [saveBeregningInput, setSaveBeregningInput] = React.useState<boolean>(false);
  const [matvareIsActive, setMatvareActive] = React.useState<boolean>(false);
  const [stoffIsActive, setStoffActive] = React.useState<boolean>(false);
  const [showStoff, setShowStoff] = useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (selectedResultatGroup?.GRUPPETYPEID > 0) {
      updateResultatgruppe();
      //Todo : show alert that selected input is gonna change
    }
  }, [selectedResultatGroup.GRUPPETYPEID]);
  React.useEffect(() => {
    setBeregningObject({ ...beregningObject, databaseId: db.currentDatabase?.value });
    setSelectedResultatGroup({
      GRUPPETYPEID: 0,
      BESKRIVELSE: '',
      LANG_BESKRIVELSE: '',
      TILGANGSKODE: 0
    });
  }, [db.currentDatabase?.value]);

  useDidMountEffect(() => {
    if (!showMatvaregruppe) {
      if (!beregningObject?.inputsSaved) {
        setSaveBeregningInput(true);
      } else {
        setSaveBeregningInput(false);
      }
    } else {
      setSaveBeregningInput(false);
    }
  }, [showMatvaregruppe]);
  useDidMountEffect(() => {
    if (!showStoff) {
      if (!beregningObject?.inputsSaved) {
        setSaveBeregningInput(true);
      } else {
        setSaveBeregningInput(false);
      }
    } else {
      setSaveBeregningInput(false);
    }
  }, [showStoff]);
  useDidMountEffect(() => {
    if (!showFjernMatvarer) {
      if (!beregningObject?.inputsSaved) {
        setSaveBeregningInput(true);
      } else {
        setSaveBeregningInput(false);
      }
    } else {
      setSaveBeregningInput(false);
    }
  }, [showFjernMatvarer]);
  useDidMountEffect(() => {
    if (utvalgData) {
      setTotalCounts({
        TOTALMATVAREGRUPPE: utvalgData?.matvaregroupData?.length,
        TOTALMATVARE: utvalgData?.matvaredata?.length,
        TOTALFJERNDEMATVARE: utvalgData?.fjerndeMatvareData?.length,
        TOTALSTOFFCOUNT: utvalgData?.stoffData?.length
      });
      setUtvalgData(utvalgData);
      setSelectedMatvares(utvalgData?.matvaredata);
      setSelectedMatvaregruppes(utvalgData?.matvaregroupData);
      setSelectedStoffs(utvalgData?.stoffData);
      setSelectedStoffgruppe(
        utvalgData?.stoffGroupData?.length > 0
          ? utvalgData?.stoffGroupData[0]
          : selectedStoffgruppe
      );
      setFjerndeMatvareList(utvalgData?.fjerndeMatvareData);
      //setLoading(false);
    }
  }, [utvalgData]);
  useDidMountEffect(() => {
    if (cleared) {
      beregningObject.matvareData.bregningCheckboxes.engelskNavn = false;
      beregningObject.matvareData.bregningCheckboxes.utenKosttilskudd = false;
      setSelectedResultatGroup({
        GRUPPETYPEID: 0,
        BESKRIVELSE: '',
        LANG_BESKRIVELSE: '',
        TILGANGSKODE: 0
      });
    }
  }, [cleared]);

  const updateResultatgruppe = () => {
    updateBeregningObject('matvare', 'matvaregroups', []);
    updateBeregningObject('matvare', 'highLevelCategory', 0);
    updateBeregningObject('matvare', 'gruppetypeId', selectedResultatGroup?.GRUPPETYPEID);
    updateBeregningObject('matvare', 'resultatGroup', selectedResultatGroup?.BESKRIVELSE);
    updateBeregningObject('matvare', 'matvaresInGroup', false);
  };
  const handleChangeCheckBox = (name: any) => (event: any) => {
    updateBeregningObject('checkbox', name, event.target.checked);
    if (name === 'utenKosttilskudd') {
      //setLoading(true);
      setSaveBeregningInput(true);
    }
  };

  useEffect(() => {
    console.log('beregningObject', beregningObject);
  }, [beregningObject]);

  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12}>
          <RapportAndEnhet
            beregningObject={beregningObject}
            setMatvareActive={setMatvareActive}
            setStoffActive={setStoffActive}
            updateBeregningObject={updateBeregningObject}
            cleared={cleared}
          />
        </Grid>

        <Grid container spacing={1} alignItems={'flex-end'} style={{ paddingLeft: 10 }}>
          <Grid item xs={8}>
            <ResultatGruppe
              selectedResultatGroup={selectedResultatGroup}
              setSelectedResultatGroup={setSelectedResultatGroup}
              disabled={
                !matvareIsActive || beregningObject?.matvareData?.rapport?.value === 0
              }
              cleared={cleared}
            />
          </Grid>
          <Grid item xs={4}>
            <ChatBubbleButton
              name={'Matvaregrupper'}
              showPopup={showMatvaregruppe}
              setShowPopup={setShowMatvaregruppe}
              isDisabled={
                !matvareIsActive || beregningObject?.matvareData?.rapport?.value === 0
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0 0 1.5em 15px' }}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={beregningObject?.matvareData?.bregningCheckboxes?.norskNavn}
                    //onChange={handleChangeCheckBox('norskNavn')}
                    value="norskNavn"
                    disabled={
                      !matvareIsActive ||
                      beregningObject?.matvareData?.rapport?.value === 0
                    }
                  />
                }
                label="Norske navn"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      beregningObject?.matvareData?.bregningCheckboxes?.engelskNavn
                    }
                    onChange={handleChangeCheckBox('engelskNavn')}
                    value="engelskNavn"
                    disabled={
                      !matvareIsActive ||
                      beregningObject?.matvareData?.rapport?.value === 0
                    }
                  />
                }
                label="Engelske navn"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      beregningObject?.matvareData?.bregningCheckboxes?.utenKosttilskudd
                    }
                    onChange={handleChangeCheckBox('utenKosttilskudd')}
                    value="utenKosttilskudd"
                    disabled={beregningObject?.matvareData?.rapport?.value === 0}
                  />
                }
                label="Uten kosttilskudd"
              />
            </FormGroup>
          </FormControl>
          {utvalgData?.fjerndeMatvareData?.length ===
            utvalgData?.fjerndeMatvareData?.length && loading ? (
            <CircularProgress />
          ) : null}
        </Grid>
        <Grid container spacing={1} alignItems={'flex-end'} style={{ paddingLeft: 10 }}>
          <Grid item xs={8}>
            <InputLabel sx={classes.InputLabel}>Fjern matvarer</InputLabel>
            <TextField
              id="outlined-uncontrolled-fjerne-matvarer"
              type="text"
              value={
                utvalgData?.fjerndeMatvareData?.length > 0
                  ? utvalgData?.fjerndeMatvareData?.length + ' enkeltmatvarer'
                  : ''
              }
              maxRows="1"
              disabled={beregningObject?.matvareData?.rapport?.value === 0}
              inputProps={{
                min: 1,
                maxLength: 6
              }}
              style={{ width: '100%' }}
              InputProps={{ readOnly: true, sx: { input: classes.resize } }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <ChatBubbleButton
              name={'Fjerne matvarer'}
              showPopup={showFjernMatvarer}
              setShowPopup={setShowFjernMatvarer}
              isDisabled={beregningObject?.matvareData?.rapport?.value === 0}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 10, paddingBottom: 10 }}>
          <BeregningStoff
            beregningObject={beregningObject}
            showStoff={showStoff}
            setShowStoff={setShowStoff}
            stoffStatus={stoffIsActive}
            setTotalCounts={setTotalCounts}
            updateBeregningObject={updateBeregningObject}
            utvalgData={utvalgData}
            selectedStoffs={selectedStoffs}
            setSelectedStoffs={setSelectedStoffs}
            selectedStoffgruppe={selectedStoffgruppe}
            setSelectedStoffgruppe={setSelectedStoffgruppe}
          />
        </Grid>
      </Grid>
      {showMatvaregruppe && (
        <MatvareGruppeTablePopup
          showMatvaregruppe={showMatvaregruppe}
          setShowMatvaregruppe={setShowMatvaregruppe}
          beregningObject={beregningObject}
          updateBeregningObject={updateBeregningObject}
          setShowProgress={setShowProgress}
          selectedMatvares={selectedMatvares}
          setSelectedMatvares={setSelectedMatvares}
          selectedMatvaregruppes={selectedMatvaregruppes}
          setSelectedMatvaregruppes={setSelectedMatvaregruppes}
          totalCounts={totalCounts}
          setTotalCounts={setTotalCounts}
          utvalgData={utvalgData}
          selectedResultatGroup={selectedResultatGroup}
          setProgressText={setProgressText}
        />
      )}
      {showFjernMatvarer && (
        <FjernMatvarerTablePopup
          showFjernMatvarer={showFjernMatvarer}
          setShowFjernMatvarer={setShowFjernMatvarer}
          fjerndeMatvareList={fjerndeMatvareList}
          setFjerndeMatvareList={setFjerndeMatvareList}
          beregningObject={beregningObject}
          updateBeregningObject={updateBeregningObject}
          setShowProgress={setShowProgress}
          showAlertMessage={showAlertMessage}
          setProgressText={setProgressText}
        />
      )}
      {saveBeregningInput && (
        <SaveInputs
          beregningObject={beregningObject}
          showAlertMessage={showAlertMessage}
          setBeregningObject={setBeregningObject}
          setUtvalgData={setUtvalgData}
          setTotalCounts={setTotalCounts}
          setShowProgress={setShowProgress}
          setProgressText={setProgressText}
        />
      )}
    </>
  );
};
export default MatvareAndStoff;
