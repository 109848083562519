import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { DatabaseContext } from '../../../../../context/DatabaseContext';
import { useUserContext } from '../../../../../context/AuthContext';
import { MatvareResult } from '../../../matvare/types/MatvareTypes';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { ProgressBar } from '../../../../utilities/ProgressBar';
import { MonoSearchField, NButton } from '../../../components/Inputs';
import MatvareSearchTable from '../../../matvare/MatvareSearchTable';
import { MaaltidskomponentI, UtbyttetI } from '../../types/PersonTypes';

interface MatvareSearchProps {
  showing: boolean;
  hide: () => void;
  newRowData: MaaltidskomponentI | null;
  setNewRowData: Dispatch<SetStateAction<MaaltidskomponentI | null>>;
  showProgress: boolean;
  setShowProgress: Dispatch<SetStateAction<boolean>>;
  fromUtbyttet: boolean;
  utbyttetData: UtbyttetI[];
  setUtbyttetData: Dispatch<SetStateAction<UtbyttetI[]>>;
  currentIngredient: number;
}

function MatvareSearch(props: MatvareSearchProps) {
  const {
    showing,
    hide,
    newRowData,
    setNewRowData,
    showProgress,
    setShowProgress,
    fromUtbyttet,
    utbyttetData,
    setUtbyttetData,
    currentIngredient
  } = props;
  const [matvareData, setMatvareData] = useState<MatvareResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<number>();
  const { keycloak } = useKeycloak();
  const { currentDatabase } = useContext(DatabaseContext);
  const loggedInuser = useUserContext();

  const changeMatvare = (kode: number, navn?: string) => {
    fromUtbyttet
      ? setUtbyttetData((prev) =>
          prev.map((p) =>
            p.ingrediensId === currentIngredient
              ? { ...p, matvareinn: kode, matvareinnNavn: navn }
              : p
          )
        )
      : setNewRowData((prev) => ({ ...prev, kode: kode, navn: navn }));
    hide();
  };

  const search = (uri: string, getRows?: (resp: any) => any[]) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;
    setLoading(true);
    fetch(encodeURI(fullUri), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + keycloak?.token
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        setMatvareData(getRows ? getRows(resp) : resp || []);
        setLoading(false);
        setShowProgress(false);
      });
  };

  const searchWithNumber = (kode?: string) =>
    search(`getMatvareList/${currentDatabase.value}/1/999999`, (resp) =>
      kode ? resp?.filter((r: MatvareResult) => r.MATVAREKODEID >= parseInt(kode)) : resp
    );

  const searchWithName = (name: string) =>
    search(
      `getMatvareListByNavn/${currentDatabase.value}/${
        encodeURIComponent(name?.toLowerCase()?.trim()) || 'all'
      }/${-1}`
    );
  const searchWithMyNumbers = (kodeFrom: number, kodeTo: number) =>
    search(
      `getMatvareList/${currentDatabase.value}/${kodeFrom || '1'}/${kodeTo || '999999'}`
    );

  useEffect(() => {
    if (showing) {
      searchWithNumber();
    }
  }, [showing]);

  const setMittNummeromrade = () => {
    let rangeFrom = loggedInuser?.user?.numberRange[0];
    let rangeTo = loggedInuser?.user?.numberRange[1];
    searchWithMyNumbers(rangeFrom, rangeTo);
  };

  return (
    <DialogGeneral
      title="Matvareliste"
      open={showing}
      hide={hide}
      fullWidth
      maxWidth="lg"
    >
      {loading && (
        <ProgressBar
          text={'Loading...'}
          isShowing={showProgress}
          hide={() => {
            setShowProgress(!showProgress);
          }}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4} container alignItems="flex-end">
            <MonoSearchField
              header="Søk  etter matvarekode"
              searchFn={(n) => searchWithNumber(n)}
            />
          </Grid>
          <Grid item xs={4} container alignItems="flex-end">
            <MonoSearchField
              header="Søk etter navn eller søkeord"
              preValue={
                fromUtbyttet
                  ? utbyttetData?.find((b) => b.ingrediensId === currentIngredient)
                      ?.matvareinnNavn
                  : newRowData?.navn
              }
              searchFn={(n) => searchWithName(n)}
            />
          </Grid>
          <Grid item xs={4} container alignItems="flex-end">
            <NButton
              onClick={setMittNummeromrade}
              children="Mitt nummerområde"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!loading || matvareData?.length < 1 ? (
            <MatvareSearchTable
              matvareData={matvareData}
              selectedRowId={selectedRowId}
              changeMatvare={changeMatvare}
            />
          ) : (
            <Skeleton variant="rectangular" width="90vw" height="100em" />
          )}
        </Grid>
      </Grid>
    </DialogGeneral>
  );
}

export default MatvareSearch;
