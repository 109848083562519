import {
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import DialogGeneral from '../../../../utilities/DialogGeneral';
import { IntervjuerI, ProsjektI, SelectedIntervjuerI } from '../../types/ProjectTypes';
import { UserDetailI } from '../../../admin/AdminTypes';
import { ApiCall } from '../../../../utilities/ApiCall';
import { useKeycloak } from '@react-keycloak/web';
import { NButton } from '../../../components/Inputs';
import AddModeratorIcon from '@mui/icons-material/AddModeratorOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemoveOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { ItemOwner } from '../../types/UndersokelseTypes';
import InputValidator from '../InputValidator';
import { EnhancedTableHead } from '../../../../utilities/EnhancedTableHead';
import { HeadCell } from '../../../components/commons/CommonTypes';
import { AlertI } from '../../../../../types/alertTypes';

const headCells: HeadCell<UserDetailI>[] = [
  {
    id: 'id',
    align: 'left',
    padding: 'normal',
    label: 'ID'
  },
  {
    id: 'brukerkode',
    align: 'left',
    padding: 'normal',
    label: 'Brukernavn'
  },
  {
    id: 'navn',
    align: 'left',
    padding: 'normal',
    label: 'Navn'
  },
  {
    id: 'numberFrom',
    align: 'right',
    padding: 'normal',
    label: 'F.o.m-nr'
  },
  {
    id: 'numberTo',
    align: 'right',
    padding: 'normal',
    label: 'T.o.m-nr'
  }
];

interface IntervjuereModalProps {
  showing: boolean;
  hide: () => void;
  project: ProsjektI;
  prosjektId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  setAlertSeverity: Dispatch<SetStateAction<AlertI['alertColor']>>;
}

export default function IntervjuereModal(props: IntervjuereModalProps) {
  const {
    showing,
    hide,
    project,
    prosjektId,
    undersokelseId,
    undersokelseKortnavn,
    setShowAlert,
    setAlertMessage,
    setAlertSeverity
  } = props;
  const [detailData, setDetailData] = useState<UserDetailI[] | null>(null);
  const [detailDataCopy, setDetailDataCopy] = useState<UserDetailI[] | null>(null);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [users, setUsers] = useState<ItemOwner[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectedIntervjuerI[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { keycloak } = useKeycloak();

  const changes: boolean = detailData?.some((i) => !detailDataCopy?.includes(i));

  useEffect(() => {
    ApiCall('getUsers', 'GET', keycloak.token, setUsers).catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiCall(
        `getInterviewers/${prosjektId}/${undersokelseId}`,
        'GET',
        keycloak.token
      );
      const json = await response.json();
      setDetailData(json);
      setDetailDataCopy(json);
    };
    fetchData();
  }, [prosjektId, undersokelseId]);

  const saveAndUpdateInterviewers = (data: IntervjuerI[]) => {
    const fetchData = async () => {
      const response = await ApiCall(
        `saveAndUpdateInterviewers/${prosjektId}/${undersokelseId}`,
        'POST',
        keycloak.token,
        undefined,
        { data: data || [] }
      );
      if (response.ok) {
        setShowAlert(true);
        setAlertMessage('Intervjuere lagret');
        setAlertSeverity('success');
      } else {
        setShowAlert(true);
        setAlertMessage('Lagring av intervjuere feilet');
        setAlertSeverity('error');
      }
    };
    fetchData().catch(console.error);
  };

  const deleteInterviewers = (data: number[]) => {
    const fetchData = async () => {
      const response = await ApiCall(
        `deleteInterviewers/${prosjektId}/${undersokelseId}`,
        'DELETE',
        keycloak.token,
        undefined,
        { data: data || [] }
      );
      if (response.ok) {
        setShowAlert(true);
        setAlertMessage('Intervjuere slettet');
        setAlertSeverity('success');
      } else {
        setShowAlert(true);
        setAlertMessage('Sletting av intervjuere feilet');
        setAlertSeverity('error');
      }
    };
    fetchData().catch(console.error);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    let newSelected: readonly number[] = [];
    if (!selected.includes(id)) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = detailData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleLeggeTil = (isAdmin?: boolean) => {
    const newInterviewers: UserDetailI[] = [
      ...new Map(
        selectedUsers
          .filter((s) => s.isValid)
          .map((u) => [
            u.brukerId,
            {
              id: u.brukerId,
              brukerkode: u.brukerkode,
              isAdmin: isAdmin ? 'J' : 'N'
            }
          ])
      ).values()
    ];
    console.log('newInterviewers', newInterviewers);
    if (detailData?.find((i) => newInterviewers.map((j) => j.id).includes(i.id))) {
      setShowError(true);
      setErrorMessage('En eller flere av brukerkodene er allerede lagt til.');
      return;
    }
    setDetailData([...detailData, ...newInterviewers]);
    setSelectedUsers([]);
  };

  const handleAdminToggle = (userId: number) => {
    console.log('userId', userId);
    setDetailData((prev) =>
      prev.map((i) =>
        i.id === userId ? { ...i, isAdmin: i.isAdmin === 'J' ? 'N' : 'J' } : i
      )
    );
  };

  const handleRemoveAdminAccess = () => {
    setDetailData((prev) =>
      prev.map((i) => (selected.includes(i.id) ? { ...i, isAdmin: 'N' } : i))
    );
    setSelected([]);
  };

  const handleRemoveSurveyAccess = () => {
    const usersToRemove = detailData
      ?.filter((i) => selected.includes(i.id))
      .map((i) => i.id);
    deleteInterviewers(usersToRemove);
    setDetailData((prev) => prev.filter((i) => !selected.includes(i.id)));
    setSelected([]);
  };

  const handleLagre = () => {
    const apiData: IntervjuerI[] = detailData?.map((i) => ({
      brukerId: i.id,
      administrator: i.isAdmin
    }));
    saveAndUpdateInterviewers(apiData);
    hide();
  };

  return (
    <DialogGeneral title="Intervjuere" open={showing} hide={hide} fullWidth maxWidth="lg">
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <Typography variant="body1">
            <b>Prosjekt: </b> {prosjektId} - {project?.prosjektnavn}
          </Typography>
          <Typography variant="body1">
            <b>Undersøkelse: </b> {undersokelseKortnavn}
          </Typography>
        </Stack>
        <TableContainer component={Paper}>
          <Table size="small">
            <EnhancedTableHead<UserDetailI>
              headCells={headCells}
              rightColumns={[{ name: 'Ansvarlig', padding: 'none', align: 'center' }]}
              checkbox={{
                onSelectAllClick: (event) => handleSelectAllClick(event),
                numSelected: selected.length,
                rowCount: detailData?.length
              }}
            />
            <TableBody>
              {detailData?.map((row, index) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{
                    ':not(:hover)': {
                      backgroundColor: '#F1F1F1',
                      '& > :nth-of-type(1), & > :nth-of-type(7)': {
                        backgroundColor: '#FFFFFF'
                      }
                    }
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => {
                      handleClick(event, row.id);
                    }}
                  >
                    <Checkbox color="primary" checked={selected?.includes(row.id)} />
                  </TableCell>
                  {headCells.map((cell) => (
                    <TableCell
                      key={cell.id}
                      padding={cell.padding}
                      align={cell.align}
                      onClick={(event) => {
                        handleClick(event, row.id);
                      }}
                    >
                      {row[cell.id]}
                    </TableCell>
                  ))}
                  <TableCell
                    padding={'checkbox'}
                    align={'center'}
                    onClick={() => handleAdminToggle(row.id)}
                  >
                    <Switch size="small" checked={row['isAdmin'] === 'J'} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" spacing={1} alignItems="end">
          <InputValidator
            users={users}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            showError={showError}
            setShowError={setShowError}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <NButton
            children="Legge til (ansvarlig)"
            variant="outlined"
            leftIcon={<AddModeratorIcon />}
            onClick={() => handleLeggeTil(true)}
            sx={{ flexBasis: '18rem', px: 0.5 }}
            disabled={selectedUsers.length === 0 || selectedUsers.some((s) => !s.isValid)}
          />
          <NButton
            children="Legge til (vanlig)"
            variant="outlined"
            leftIcon={<PersonAddAltIcon />}
            onClick={() => handleLeggeTil()}
            sx={{ flexBasis: '18rem', px: 0.5 }}
            disabled={selectedUsers.length === 0 || selectedUsers.some((s) => !s.isValid)}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="end">
          <Tooltip title={!changes ? 'Ingen endringer gjort' : ''}>
            <span>
              <NButton children="Lagre" onClick={handleLagre} disabled={!changes} />
            </span>
          </Tooltip>
          <NButton
            children="Fjern intervjuer-rollen fra de valgte"
            variant="outlined"
            leftIcon={<PersonRemoveIcon />}
            onClick={handleRemoveAdminAccess}
            disabled={selected.length === 0}
          />
          <NButton
            children="Fjern tilgangen til undersøkelsen fra de valgte"
            variant="outlined"
            leftIcon={<BlockIcon />}
            onClick={handleRemoveSurveyAccess}
            disabled={selected.length === 0}
          />
        </Stack>
      </Stack>
    </DialogGeneral>
  );
}
