import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { LopedagI, MaaltidI } from './types/PersonTypes';
import { Typography } from '@mui/material';
import { FullHeightTextField, NButton } from '../components/Inputs';
import LopedagMaaltiderTable from './LopedagMaaltiderTable';
import AddIcon from '@mui/icons-material/Add';
import { CodeRegisterI } from '../components/commons/CommonTypes';

interface MaaltiderProps {
  lopedagData: LopedagI;
  setLopedagData: Dispatch<SetStateAction<LopedagI>>;
  maaltidsData: MaaltidI[];
  maaltidTypes: CodeRegisterI[];
  currentMaaltidId: number;
  setCurrentMaaltidId: Dispatch<SetStateAction<number>>;
  setShowMaaltid: Dispatch<SetStateAction<boolean>>;
  setFromExisting: Dispatch<SetStateAction<boolean>>;
  setShowKopi: (val: boolean) => void;
}

export default function LopedagMaaltider(props: MaaltiderProps) {
  const {
    lopedagData,
    setLopedagData,
    maaltidsData,
    maaltidTypes,
    currentMaaltidId,
    setCurrentMaaltidId,
    setShowMaaltid,
    setFromExisting,
    setShowKopi
  } = props;
  const [svar, setSvar] = useState<string>(
    maaltidsData?.find((i) => i.maaltidsId === currentMaaltidId)?.notat
  );

  useEffect(() => {
    setSvar(maaltidsData?.find((i) => i.maaltidsId === currentMaaltidId)?.notat);
  }, [maaltidsData, currentMaaltidId]);

  function handleChangeNotat(event: ChangeEvent<HTMLInputElement>, id: number) {
    const { value } = event.target;
    setLopedagData((prevState) => {
      return {
        ...prevState,
        maaltider: prevState.maaltider.map((m) =>
          m.maaltidsId === currentMaaltidId ? { ...m, notat: value } : m
        )
      };
    });
  }

  return (
    <Stack direction={'column'} spacing={2} mt={'2rem'}>
      <Typography variant="h6" gutterBottom>
        Måltider:
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <Box flexGrow={1}>
          <LopedagMaaltiderTable
            maaltidsData={maaltidsData}
            maaltidTypes={maaltidTypes}
            currentMaaltidId={currentMaaltidId}
            setCurrentMaaltidId={setCurrentMaaltidId}
            setShowMaaltid={setShowMaaltid}
            setFromExisting={setFromExisting}
            setShowKopi={setShowKopi}
          />
        </Box>
        <Stack spacing={1} sx={{ alignItems: 'end' }}>
          <NButton
            children="Nytt måltid"
            onClick={() => {
              setShowMaaltid(true);
              setFromExisting(false);
              setCurrentMaaltidId(
                lopedagData?.maaltider?.length
                  ? Math.max(...lopedagData?.maaltider?.map((m) => m.maaltidsId)) + 1
                  : 1
              );
            }}
            leftIcon={<AddIcon />}
            variant="outlined"
            disabled={!lopedagData?.lopedagId}
          />
          <FullHeightTextField
            name="maaltid"
            value={svar ? svar : '[Notat ikke funnet]'}
            onChange={(event) => handleChangeNotat(event, currentMaaltidId)}
            sx={{ minWidth: { lg: '20rem' } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
