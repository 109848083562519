import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageContainer from '../home/PageContainer';
import { Box, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { Dropdown, NButton, SlowText } from '../components/Inputs';
import { useKeycloak } from '@react-keycloak/web';
import { UndersokelseData } from './types/UndersokelseTypes';
import { ProgressBar } from '../../utilities/ProgressBar';
import { SaveBeforeRedirect } from '../../utilities/SaveBeforeRedirect';
import ExportDetailsToExcel from './ExportDetailsToExcel';
import UndersokelseSearch from './UndersokelseSearch';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CopyUndersokelse } from './components/CopyUndersokelse';
import DeleteDialog from './components/DeleteDialog';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AlertComp from '../../utilities/AlertComp';
import { AlertI } from '../../../types/alertTypes';
import IsOwnedByUser from '../../utilities/Validate';
import { databaseOptions } from '../../../assets/DatabaseOptions';
import Brukertilgang from '../components/commons/Brukertilgang';
import Endringslogg from '../components/commons/Endringslogg';
import ProjectNoLink from './components/ProjectNoLink';
import useFetchData from '../../utilities/useFetchData';
import { DefaultVerdier } from '../beregning/types/BeregningTypes';
import { DatabaseContext } from '../../../context/DatabaseContext';
import { useUserContext } from '../../../context/AuthContext';
import { useProjectContext } from '../../../context/ProjectContext';
import { GyldighetsOmrade, Maal, ProsjektI, UndersokelseI } from './types/ProjectTypes';
import { spacing } from '@mui/system';
import { FetchProjectandUndersokelse } from './components/FetchProjectandUndersokelse';
import { hasDataChanged } from '../../utilities/ArrayFunctions';
import { CodeRegisterI } from '../components/commons/CommonTypes';
import { useCodeContext } from '../../../context/CodeContext';
import IntervjuereModal from './components/modals/IntervjuereModal';
import { ApiCall } from '../../utilities/ApiCall';
interface printUndersokelseProps {
  u: UndersokelseI;
  projectId: number;
  user: string;
  ref: any;
}
const ComponentToPrint = React.forwardRef((p: printUndersokelseProps, ref: any) => (
  <div ref={ref} style={{ padding: '1em' }}>
    <table>
      <tr>
        <td>Utskrift av skjermbildet: </td>
        <td>Undersøkelse ({p.user})</td>
      </tr>
      <tr>
        <td>Utskriftsdato: </td>
        <td>{moment().format('YYYY-MM-DD hh:mm:ss')}</td>
      </tr>
    </table>

    <h5>Undersøkelse informasjon</h5>
    <table className="styledTable">
      <tr>
        <td>Prosjektnr</td>
        <td>{p.projectId}</td>
      </tr>
      <tr>
        <td>Undersøkelsenr</td>
        <td>{p.u?.undersokelseId}</td>
      </tr>
      <tr>
        <td>Kortnavn</td>
        <td>{p.u?.kortNavn}</td>
      </tr>
      <tr>
        <td>Navn</td>
        <td>{p.u?.navn}</td>
      </tr>
      <tr>
        <td>Tidsrom</td>
        <td>{p.u?.tidsrom}</td>
      </tr>
      <tr>
        <td>Leder</td>
        <td>{p.u?.ansvarlig}</td>
      </tr>
      <tr>
        <td>Metode</td>
        <td>{p.u?.metode}</td>
      </tr>
      <tr>
        <td>Beskrivelse</td>
        <td>{p.u?.beskrivelse}</td>
      </tr>
      <tr>
        <td>Matvaredatavase</td>
        <td>{p.u?.matvareDatabaseId}</td>
      </tr>
      <tr>
        <td>Importfil</td>
        <td>{p.u?.importfil}</td>
      </tr>
      <tr>
        <td>Min. Høyde</td>
        <td>{p.u?.gyldighetsOmraade?.minHoyde}</td>
      </tr>
      <tr>
        <td>Maks. Høyde</td>
        <td>{p.u?.gyldighetsOmraade?.maxHoyde}</td>
      </tr>
      <tr>
        <td>Min. Vekt</td>
        <td>{p.u?.gyldighetsOmraade?.minVekt}</td>
      </tr>
      <tr>
        <td>Maks. Vekt</td>
        <td>{p.u?.gyldighetsOmraade?.maxVekt}</td>
      </tr>
      <tr>
        <td>Min. Alder</td>
        <td>{p.u?.gyldighetsOmraade?.minAlder}</td>
      </tr>
      <tr>
        <td>Maks. Alder</td>
        <td>{p.u?.gyldighetsOmraade?.maxAlder}</td>
      </tr>
    </table>
  </div>
));

interface RouteParams {
  projectId: string;
  undersokelseId: string;
  undersokelseKortnavn: string;
}

interface UndersokelseProps extends RouteComponentProps<RouteParams> {}

function Undersokelse(p: UndersokelseProps) {
  const { projectId, undersokelseId, undersokelseKortnavn } = p.match.params;
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const token = keycloak?.token || 'Mangler token';
  const { currentDatabase } = useContext(DatabaseContext);
  const projectContext = useProjectContext();
  const loggedInuser = useUserContext();
  const allCodes = useCodeContext();
  const redirectToProject = () =>
    history.push(`/home/kostholdsdata/prosjekt/${projectId}`);
  const redirectTo = (undersokelse?: string | number) =>
    history.push(`/home/kostholdsdata/undersokelse/${projectId}/${undersokelse || ''}`);
  document.title = `Prosjekt ${projectId} - undersøkelse ${undersokelseId} - Nutrifoodcalc`;
  const initialUndersokelseForm: UndersokelseI = React.useMemo(
    () => ({
      prosjektId: projectId,
      undersokelseId: undersokelseId || '',
      kortNavn: '',
      navn: '',
      tidsrom: '',
      ansvarlig: '',
      metode: '',
      eier: '',
      beskrivelse: '',
      matvareDatabaseId: currentDatabase.value,
      importfil: '',
      gyldighetsOmraade: {
        maxHoyde: 0,
        minHoyde: 0,
        maxVekt: 0,
        minVekt: 0,
        maxAlder: 0,
        minAlder: 0
      }
    }),
    []
  );
  // const [project] = useState<ProsjektI>(
  //   projectContext.projects?.find((p) => p.prosjektId === +projectId)
  // );
  const project = projectContext.projects?.find((p) => p.prosjektId === +projectId);

  const [undersokelseData, setUndersokelseData] = React.useState<UndersokelseI>(
    initialUndersokelseForm
  );
  const [originalUndersokelseCopy, setOriginalUndersokelseCopy] = useState<UndersokelseI>(
    initialUndersokelseForm
  );
  const changedUndersokelseId = React.useMemo(
    () => undersokelseId?.toString() !== undersokelseData?.undersokelseId?.toString(),
    [undersokelseId, undersokelseData?.undersokelseId]
  );
  const [showUndersokelseSearch, setShowUndersokelseSearch] = React.useState(false);
  const printRef = React.useRef();
  const owned = React.useMemo(
    () => IsOwnedByUser({ id: +projectId, u: loggedInuser }),
    [projectId]
  );
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertI['alertColor']>('info');
  const [text, setText] = useState('Henter undersøkelser...');
  const [showProgress, setShowProgress] = React.useState<boolean>(false);
  const [showCopyUndersokelse, setShowCopyUndersokelse] = React.useState<boolean>(false);
  const [showBrukertilgang, setShowBrukertilgang] = React.useState<boolean>(false);
  const [showDetailsToExcel, setShowDetailsToExcel] = React.useState<boolean>(false);
  const [showChangeLog, setShowChangeLog] = React.useState<boolean>(false);
  const [showIntervjuere, setShowIntervjuere] = useState<boolean>(false);
  const [showTilleggsvariabler, setShowTilleggsvariabler] = useState<boolean>(false);
  const [refetchSurvey, setRefetchSurvey] = useState<boolean>(false);
  const [refetchProject, setRefetchProject] = useState<boolean>(false);
  const [tilleggsvariabelId, setTilleggsvariabelId] = useState<number>(0);

  const codesRegisters = useFetchData({
    loadOnMount: true,
    clearDataOnLoad: false,
    projectURL: 'getKodeRegister',
    subProjectURL: null
  });

  useEffect(() => {
    if (codesRegisters.data) {
      const data: CodeRegisterI[] = codesRegisters.data as CodeRegisterI[];
      allCodes.setCodes(data);
    }
  }, [codesRegisters.data]);
  const handleChange = (field: string, value: any) => {
    setUndersokelseData({ ...undersokelseData, [field]: value });
  };

  const handleValueChangeEvent = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof UndersokelseI | keyof GyldighetsOmrade | keyof Maal
  ) => {
    const { value } = e.target;

    // Update the undersokelseData state with the modified field
    setUndersokelseData((prevState) => {
      // Create a shallow copy of the state
      const newState: UndersokelseI = { ...prevState };

      // Check if the field is nested within gyldighetsOmraade
      if (field in newState.gyldighetsOmraade) {
        newState.gyldighetsOmraade = {
          ...newState.gyldighetsOmraade,
          [field]: value
        };
      } else {
        // Update the top-level field
        newState[field] = value;
      }

      return newState;
    });
  };
  const apiCall = (uri: string, method: string, body?: any) => {
    const fullUri = `${process.env.REACT_APP_BACKEND}/${uri}`;

    return fetch(encodeURI(fullUri), {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: body ? JSON.stringify(body) : undefined
    });
  };

  React.useEffect(() => {
    if (projectId && undersokelseId) {
      if (
        projectContext.projects?.find((p) => p.prosjektId === +projectId)?.undersokelser
          ?.length === 0
      ) {
        setRefetchSurvey(true);
      } else {
        const data = projectContext.projects
          ?.find((p) => p.prosjektId === +projectId)
          ?.undersokelser?.find(
            (u) => u.undersokelseId === +undersokelseData?.undersokelseId
          );
        setUndersokelseData(data);
        setOriginalUndersokelseCopy({ ...data });
      }
    }
  }, [undersokelseId, refetchSurvey]);

  React.useEffect(() => {
    if (projectId && +undersokelseData?.undersokelseId) {
      const data = projectContext.projects
        ?.find((p) => p.prosjektId === +projectId)
        ?.undersokelser?.find(
          (u) => u.undersokelseId === +undersokelseData?.undersokelseId
        );
      if (data !== undefined) {
        redirectTo(undersokelseData?.undersokelseId);
      } /*  else {
        showAlertMessage('Denne undersøkelsen finnes ikke', 'warning');
        //setUnmodified(initialUndersokelseForm);
      } */
    }
  }, [undersokelseData?.undersokelseId]);

  const handleDelete = () => {
    if (owned) {
      setText('Sletter undersøkelser...');
      setShowProgress(true);
      apiCall(
        `deleteUndersokelse/${projectId}/${undersokelseData?.undersokelseId}`,
        'DELETE'
      ).then((r) => {
        if (r.ok) {
          setShowProgress(false);
          showAlertMessage('Slettet undersøkelse', 'success');
          //surveys.loadData('hentPUndersokelser',  `${projectId}`);
          //setRefetchSurvey(true);
          setTimeout(() => redirectTo(), 1000);
        } else {
          setShowProgress(false);
          showAlertMessage('Klarte ikke å slette undersøkelse!', 'error');
        }
      });
    }
  };

  const handleUpdate = (data: UndersokelseI) => {
    apiCall(`updateUndersokelse/${projectId}`, 'PUT', { data: data }).then((r) => {
      if (r.ok) {
        showAlertMessage('Oppdatert undersøkelse', 'success');
        //fetch from db to include the newly updated data
        setRefetchSurvey(true);

        //surveys.loadData('hentPUndersokelser',  `${projectId}`);
        //setUnmodified(undersokelseData);
      } else {
        showAlertMessage('Klarte ikke å oppdatere undersøkelse', 'error');
      }
    });
  };

  const handleCreate = (data: UndersokelseI) => {
    apiCall(`createUndersokelse/${projectId}`, 'POST', { data: data }).then((r) => {
      if (r.ok) {
        showAlertMessage('Opprettet undersøkelse', 'success');
        //fetch from db to include the newly saved data
        setRefetchSurvey(true);

        setTimeout(() => redirectTo(undersokelseData?.undersokelseId), 1000);

        //surveys.loadData('hentPUndersokelser',  `${projectId}`);
        //setUnmodified(undersokelseData);
        //redirectTo(undersokelseData?.undersokelseId);
      } else {
        showAlertMessage('Klarte ikke å opprette undersøkelse', 'error');
      }
    });
  };

  const handleSave = () => {
    if (
      !undersokelseData?.undersokelseId ||
      !undersokelseData?.navn ||
      !undersokelseData?.kortNavn ||
      !undersokelseData?.ansvarlig
    ) {
      showAlertMessage('Obligatoriske felter kan ikke være tomme.', 'error');
    } else {
      if (owned && hasDataChanged(undersokelseData, originalUndersokelseCopy)) {
        console.log('Data has changed!');
        const undersokelseDataForm: UndersokelseI = {
          undersokelseId: +undersokelseData?.undersokelseId || +'',
          kortNavn: undersokelseData?.kortNavn || '',
          navn: undersokelseData?.navn || '',
          tidsrom: undersokelseData?.tidsrom || '',
          ansvarlig: undersokelseData?.ansvarlig || '',
          metode: undersokelseData?.metode || '',
          eier: undersokelseData?.eier || '',
          matvareDatabaseId: undersokelseData?.matvareDatabaseId || +'',
          importfil: undersokelseData?.importfil || '',
          beskrivelse: undersokelseData?.beskrivelse || '',
          gyldighetsOmraade: {
            maxHoyde: +undersokelseData?.gyldighetsOmraade.maxHoyde,
            maxVekt: +undersokelseData?.gyldighetsOmraade.maxVekt,
            maxAlder: +undersokelseData?.gyldighetsOmraade.maxAlder,
            minHoyde: +undersokelseData?.gyldighetsOmraade.minHoyde,
            minVekt: +undersokelseData?.gyldighetsOmraade.minVekt,
            minAlder: +undersokelseData?.gyldighetsOmraade.minAlder
          }
        };
        if (!changedUndersokelseId) {
          handleUpdate(undersokelseDataForm);
        } else {
          handleCreate(undersokelseDataForm);
        }
      } else {
        showAlertMessage(
          `Det er ikke blitt gjort noen endringer i skjermbildet. Lagring er ikke nødvendig.`,
          'info'
        );
      }
    }
  };

  const showAlertMessage = (message: string, severity: any) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };
  const selectedDatabase = React.useMemo(
    () =>
      databaseOptions?.find(
        (a) => a.value.toString() === undersokelseData?.matvareDatabaseId?.toString()
      ),
    [undersokelseData?.matvareDatabaseId]
  );
  const navigateToUnd = (pId: number, uId: number) => {
    // set a timer for the history.push because it will trigger a page refresh
    // and set showAlert to false, removing the Alert
    const timer = setTimeout(() => {
      history.push(`/home/kostholdsdata/undersokelse/${pId}/${uId}`);
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <PageContainer>
      <Paper style={{ padding: '1.5em 15px 1.5em 15px', boxShadow: 'none' }}>
        {/* <SaveBeforeRedirect canSave={isModified} saveFn={() => handleSave()} /> */}
        <FetchProjectandUndersokelse
          projectId={+projectId}
          setShowProgress={setShowProgress}
          setText={setText}
          refetchProject={refetchProject}
          setRefetchProject={setRefetchProject}
          refetchSurvey={refetchSurvey}
          setRefetchSurvey={setRefetchSurvey}
        />
        <Grid container spacing={2}>
          <Grid item xs={9} container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Undersøkelse</Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row-reverse" spacing={2}>
                <ReactToPrint
                  trigger={() => (
                    <NButton
                      children="Skriv ut"
                      rightIcon={<PrintOutlinedIcon />}
                      variant="text"
                      disabled={!undersokelseData?.undersokelseId}
                    />
                  )}
                  pageStyle=".styledTable{width:100%;border-collapse:collapse;text-align:left}.styledTable th,.styledTable td{border:2px solid black;padding:0.5em;}"
                  content={() => printRef.current}
                />
                <NButton
                  children="Kopier"
                  rightIcon={<ContentCopyOutlinedIcon />}
                  variant="text"
                  onClick={() => setShowCopyUndersokelse(true)}
                  disabled={!undersokelseData?.undersokelseId}
                />
                <DeleteDialog
                  title="Slett undersøkelse?"
                  message=""
                  deleteFn={handleDelete}
                  disabled={!(owned && undersokelseData?.undersokelseId)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">* Obligatorisk felt</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Prosjekt:{' '}
                <u
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() => redirectToProject()}
                >
                  {project?.prosjektnavn} - {projectId}
                </u>
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <SlowText
                  header="Undersøkelsesnummer *"
                  value={undersokelseData?.undersokelseId || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'undersokelseId')}
                  name="undersokelseId"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Kortnavn *"
                  value={undersokelseData?.kortNavn || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'kortNavn')}
                  name="kortNavn"
                  required
                  inputProps={{
                    maxLength: 14
                  }}
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <NButton
                  children="Søk etter undersøkelse"
                  leftIcon={<SearchOutlinedIcon />}
                  fullWidth
                  onClick={() => setShowUndersokelseSearch(true)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <SlowText
                  header="Undersøkelsesnavn *"
                  value={undersokelseData?.navn || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'navn')}
                  name="navn"
                  required
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Tidsrom"
                  value={undersokelseData?.tidsrom || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'tidsrom')}
                  name="tidsrom"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Ansvarlig *"
                  value={undersokelseData?.ansvarlig || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'ansvarlig')}
                  name="ansvarlig"
                  required
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <SlowText
                  header="Metode"
                  value={undersokelseData?.metode || ''}
                  onChange={(e) => handleValueChangeEvent(e, 'metode')}
                  name="metode"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Eier"
                  value={undersokelseData?.eier || ''}
                  //onChange={(e) => handleValueChangeEvent(e, 'eier')}
                  name="eier"
                  disabled
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  header="Matvaredatabase"
                  value={selectedDatabase || currentDatabase}
                  options={databaseOptions}
                  onChange={(v: any) => handleChange('matvareDatabaseId', v.value)}
                  optionLabel={(o) => o.label}
                  readOnly={!owned}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SlowText
                header="Import fil"
                value={undersokelseData?.importfil || ''}
                name="importfil"
                disabled
                multiline
                minRows={1}
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SlowText
                header="Beskrivelse"
                value={undersokelseData?.beskrivelse || ''}
                onChange={(e) => handleValueChangeEvent(e, 'beskrivelse')}
                name="beskrivelse"
                multiline
                minRows={5}
                InputProps={{
                  readOnly: !owned
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Gyldighetsområde ved manuell registrering
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <SlowText
                  header="Minimum høyde"
                  value={undersokelseData?.gyldighetsOmraade?.minHoyde}
                  onChange={(e) => handleValueChangeEvent(e, 'minHoyde')}
                  name="gyldighetsOmraade.minimum.hoyde"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Minimum vekt"
                  value={undersokelseData?.gyldighetsOmraade?.minVekt}
                  onChange={(e) => handleValueChangeEvent(e, 'minVekt')}
                  name="gyldighetsOmraade.minimum.vekt"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Minimum alder"
                  value={undersokelseData?.gyldighetsOmraade?.minAlder}
                  onChange={(e) => handleValueChangeEvent(e, 'minAlder')}
                  name="gyldighetsOmraade.minimum.alder"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={4}>
                <SlowText
                  header="Maksimum høyde"
                  value={undersokelseData?.gyldighetsOmraade?.maxHoyde}
                  onChange={(e) => handleValueChangeEvent(e, 'maxHoyde')}
                  name="gyldighetsOmraade.maximum.hoyde"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Maksimum vekt"
                  value={undersokelseData?.gyldighetsOmraade?.maxVekt}
                  onChange={(e) => handleValueChangeEvent(e, 'maxVekt')}
                  name="gyldighetsOmraade.maximum.vekt"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SlowText
                  header="Maksimum alder"
                  value={undersokelseData?.gyldighetsOmraade?.maxAlder}
                  onChange={(e) => handleValueChangeEvent(e, 'maxAlder')}
                  name="gyldighetsOmraade.maximum.alder"
                  InputProps={{
                    readOnly: !owned
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <NButton
                  children={changedUndersokelseId ? 'Opprett' : 'Lagre'}
                  onClick={() => handleSave()}
                  disabled={!owned || +undersokelseData?.undersokelseId === 0}
                />
                <NButton
                  children="Nullstill"
                  variant="outlined"
                  onClick={() => redirectTo()}
                  //disabled={!isModified}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={3} container spacing={2} position="relative">
            <Stack
              spacing={1}
              padding={1}
              position="sticky"
              top="10rem"
              sx={{
                background: 'rgba(200,200,200, 0.2)',
                width: '100%',
                height: 'fit-content'
              }}
            >
              {undersokelseId ? (
                <Link
                  href={`/home/kostholdsdata/prosjekt/matvarer/${projectId}/${undersokelseId}/${undersokelseData?.kortNavn}`}
                  target="_blank"
                  sx={{ width: '100%', color: 'inherit' }}
                >
                  <NButton
                    children="Matvarer"
                    rightIcon={<OpenInNewOutlinedIcon />}
                    variant="text"
                    color="inherit"
                    fullWidth
                    disabled={!undersokelseId}
                  />
                </Link>
              ) : (
                <NButton
                  children="Matvarer"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!undersokelseId}
                />
              )}
              {undersokelseId && process.env.REACT_APP_ENVIRONMENT === 'DEV' ? (
                <Link
                  href={`/home/kostholdsdata/prosjekt/personer/${projectId}/${undersokelseId}/${undersokelseData?.kortNavn}`}
                  target="_blank"
                  sx={{ width: '100%', color: 'inherit' }}
                >
                  <NButton
                    children="Personer"
                    rightIcon={<OpenInNewOutlinedIcon />}
                    variant="text"
                    color="inherit"
                    fullWidth
                    disabled={!undersokelseId}
                  />
                </Link>
              ) : (
                <NButton
                  children="Personer"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!undersokelseId}
                />
              )}
              {undersokelseId && process.env.REACT_APP_ENVIRONMENT === 'DEV' ? (
                <Link
                  href={`/home/kostholdsdata/prosjekt/person/${projectId}/${undersokelseId}/${undersokelseData?.kortNavn}`}
                  target="_blank"
                  sx={{ width: '100%', color: 'inherit' }}
                >
                  <NButton
                    children="Person"
                    rightIcon={<OpenInNewOutlinedIcon />}
                    variant="text"
                    color="inherit"
                    fullWidth
                    disabled={!undersokelseId}
                  />
                </Link>
              ) : (
                <NButton
                  children="Person"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  fullWidth
                  disabled={!undersokelseId}
                />
              )}
              <NButton
                children={'Intervjuere'}
                variant="text"
                color="inherit"
                onClick={() => setShowIntervjuere(true)}
                disabled={!owned || !undersokelseId}
              />

              {owned && undersokelseId && process.env.REACT_APP_ENVIRONMENT === 'DEV' ? (
                <Link
                  href={`/home/kostholdsdata/${projectId}/${undersokelseId}/tilleggsvariabler/${tilleggsvariabelId}`}
                  target="_blank"
                  sx={{ width: '100%', color: 'inherit' }}
                >
                  <NButton
                    children="Tilleggsvariabler"
                    rightIcon={<OpenInNewOutlinedIcon />}
                    variant="text"
                    color="inherit"
                    onClick={() => setShowTilleggsvariabler(true)}
                    fullWidth
                    disabled={!owned || !undersokelseId}
                  />
                </Link>
              ) : (
                <NButton
                  children="Tilleggsvariabler"
                  rightIcon={<OpenInNewOutlinedIcon />}
                  variant="text"
                  color="inherit"
                  onClick={() => setShowTilleggsvariabler(true)}
                  fullWidth
                  disabled={!owned || !undersokelseId}
                />
              )}

              <NButton
                children="Detaljer til Excel"
                variant="text"
                color="inherit"
                onClick={() => setShowDetailsToExcel(true)}
                disabled={!undersokelseId}
              />
              <NButton
                children={`Brukertilgang${!owned ? ' (Utilgjengelig)' : ''}`}
                variant="text"
                color="inherit"
                onClick={() => setShowBrukertilgang(true)}
                disabled={!owned || !undersokelseId}
              />
              <NButton
                children="Endringslogg"
                variant="text"
                color="inherit"
                onClick={() => setShowChangeLog(true)}
                disabled={!undersokelseId}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Dialogs */}

        <Box sx={{ display: 'none' }}>
          <ComponentToPrint
            user={loggedInuser?.user?.username}
            u={undersokelseData}
            projectId={+projectId}
            ref={printRef}
          />
        </Box>
        {showCopyUndersokelse && (
          <CopyUndersokelse
            navigateToUnd={navigateToUnd}
            projectId={projectId}
            undersokelseId={undersokelseId}
            projectName={project?.prosjektnavn}
            undersokelseName={undersokelseData?.navn}
            setRefetchSurvey={setRefetchSurvey}
            setProgressBar={setShowProgress}
            showProgressAlert={showAlertMessage}
            isShowing={showCopyUndersokelse}
            hide={() => setShowCopyUndersokelse(false)}
          />
        )}
        {showDetailsToExcel ? (
          <ExportDetailsToExcel
            header={
              <ProjectNoLink
                projectId={projectId}
                undersokelseId={undersokelseId}
                kortnavn={undersokelseData?.kortNavn}
              />
            }
            projectId={projectId}
            undersokelseId={undersokelseId}
            showAlertComponent={showAlertMessage}
            owned={owned}
            setProgressBar={setShowProgress}
            showing={showDetailsToExcel}
            hide={() => setShowDetailsToExcel(false)}
          />
        ) : null}

        {showIntervjuere && (
          <IntervjuereModal
            showing={showIntervjuere}
            hide={() => setShowIntervjuere(false)}
            project={project}
            prosjektId={projectId}
            undersokelseId={undersokelseId}
            undersokelseKortnavn={undersokelseData?.kortNavn}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
          />
        )}

        {showBrukertilgang && (
          <Brukertilgang
            header={
              <ProjectNoLink
                projectId={projectId}
                undersokelseId={undersokelseId}
                kortnavn={undersokelseData?.kortNavn}
              />
            }
            showAlertComponent={showAlertMessage}
            owned={owned}
            setProgressBar={setShowProgress}
            showing={showBrukertilgang}
            hide={() => setShowBrukertilgang(false)}
            getUrl={`getUBrukerTilgang/${projectId}/${undersokelseId}`}
            deleteUrl={`deleteAccessToUnd/${projectId}/${undersokelseId}`}
            postUrl={`createUBrukertilgang/${projectId}/${undersokelseId}`}
          />
        )}

        {showChangeLog ? (
          <Endringslogg
            header={
              <ProjectNoLink
                projectId={projectId}
                undersokelseId={undersokelseId}
                kortnavn={undersokelseData?.kortNavn}
              />
            }
            showAlertComponent={showAlertMessage}
            owned={owned}
            setProgressBar={setShowProgress}
            showing={showChangeLog}
            hide={() => setShowChangeLog(false)}
            getUrl={`getUEndringslogg/${projectId}/${undersokelseData?.undersokelseId}`}
            postUrl={`createUEndringslogg/${projectId}/${undersokelseData?.undersokelseId}`}
          />
        ) : null}
        {showUndersokelseSearch ? (
          <UndersokelseSearch
            setUndersokelse={(u) => handleChange('undersokelseId', u)}
            projectId={projectId}
            showing={showUndersokelseSearch}
            hide={() => setShowUndersokelseSearch(false)}
          />
        ) : null}
        {showAlert && (
          <AlertComp
            alertMessage={alertMessage}
            alertColor={alertSeverity}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        )}
        {showProgress && (
          <ProgressBar
            text={text}
            isShowing={showProgress}
            hide={() => {
              setShowProgress(!showProgress);
            }}
          />
        )}
      </Paper>
    </PageContainer>
  );
}

export default Undersokelse;
